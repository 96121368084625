import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { Link, useHistory } from "react-router-dom";
import { Badge } from "react-materialize";

import MegaMenu from "./MegaMenu";
import "../../asset/css/menustyles.css";
import Location from "../geolocation/Location";
import { getItem } from "../../config/config";
import { get } from "../../config/ajaxRequest";
import Images from "../../config/Images";
import { logOut } from "../../utils/auth";
import { useFilter } from "../../customHook/useFilter";
import SearchFilter from "./SearchFilter";

export default function Main() {
	const history = useHistory();
	//This get the lastest filter, and update storage
	useFilter();
	const [loggedin, setLoggedIn] = useState(false);
	const [user, setUser] = useState({});
	const [category, setCategory] = useState({});
	const [cartStorage, setCartStorage] = useState(0);
	const [wishListStorage, setWishListStorage] = useState(0);
	const [activeTab, setActiveTab] = useState("");
	const [canvasMenuOpen, setCanvasMenuOpen] = useState(true);
	const [showHeader, setShowHeader] = useState(true);

	// start if search logic

	const [searchResults, setSearchResults] = useState([]);
 
	const handleSearch = async(query) => {
		let searchData = []
		if(query.length > 1){
			const res = await get("search?search="+query) 
			if(Array.isArray(res)){
				searchData = res	
				if(res.length === 0)searchData = [{id:0, name:"No Result Was Found"}]
			}
		}

		const filteredProducts = searchData.filter((product) =>
			product.name.toLowerCase().includes(query.toLowerCase()) || product.id===0
		);

		setSearchResults(filteredProducts);
	};
	// end of search logic

	useEffect(() => {
		(async()=>{
			const res = await get("category")
			if(typeof res === "object")setCategory(res) 
		})()
	}, []);


	useEffect(() => {
		document.addEventListener("myEvent", function (e) {
			getCartAndWishList();
		});
		document.addEventListener("menuEvent", function (e) {
			closeMobileMenu();
		});
	}, []);

	useEffect(() => {
		(async () => {
			const res = (await getItem("token")) ?? "";
			const u = (await getItem("user")) ?? {};
			if (res.length > 5) {
				setUser(u);
				setLoggedIn(true);
			}
		})();
	}, []);

	useEffect(() => {
		getCartAndWishList();
		let active = "";
		const urlPath = window.location.pathname;
		if (urlPath.indexOf("/women") > -1) active = "women";
		if (urlPath.indexOf("/men") > -1) active = "men";
		if (urlPath.indexOf("/kids") > -1) active = "kids";
		if (urlPath.indexOf("/fitness") > -1) active = "fitness";
		if (urlPath.indexOf("/jewellery") > -1) active = "jewellery";
		if (urlPath.indexOf("/root") > -1) active = "root";
		if (urlPath.indexOf("/yeshua") > -1) active = "yeshua";
		if (active.length > 1) setActiveTab(active);
		if (urlPath.indexOf("/maintain") > -1) setShowHeader(false);
	}, []);

	useEffect(() => {
		if (window.location.pathname.indexOf("my-account/logout") > -1) logOut();
	}, []);

	const getCartAndWishList = () => {
		const c = getItem("cart") ?? [];
		const s = getItem("savedItems") ?? [];

		const cartLength = Array.isArray(c) ? c.length : 0;
		const wishListLength = Array.isArray(s) ? s.length : 0;

		setCartStorage(cartLength);
		setWishListStorage(wishListLength);
		return;
	};

	// close canva
	const closeCanvasMenu = () => {
		setCanvasMenuOpen(false);
	};

	const fireEvent = (data) => {
		setActiveTab(data);
		const event = new CustomEvent("category", {
			bubbles: true,
			detail: { data: data },
		});
		document.dispatchEvent(event);
	};

	const closeMobileMenu = () => {
		const closeMobileMenu = document.getElementById("closeMobileMenu");
		if (closeMobileMenu) closeMobileMenu.click();
		// alert("Hi");
	};

	
const goToMenu = (data, url) => {  
	const event = new CustomEvent("menuEvent", {
		bubbles: true,
		detail: { data: data },
	});
	// console.log(data, "checking...")
	document.dispatchEvent(event);
	history.push(url)
};


	return (
		<div className="overall">
			{showHeader && (
				<div className="container topmenu1">
					<a href="https://www.iiignacy.com">
						<img
							src={Images.img39}
							width="7%"
							style={{ float: "right" }}
							alt="iiignacy site"
						/>
					</a>
					<Link to="/help" className="innermenu">
						Help & FAQs
					</Link>

					<Location />
					<img
						src={Images.img44}
						width="3%"
						style={{ padding: "0 8px" }}
						alt="hianga logo"
					/>
					{/* end of modal locate*/}
				</div>
			)}
			{showHeader && (
				<div className="topmenu2">
					<div className="left">
						<Link
							to="/"
							onClick={() => fireEvent("site")}
							className="logo"
							style={
								{
									// backgroundColor: "white",
									// borderRadius: "100px",
								}
							}
						>
							<img
								src={Images.img44}
								style={{
									width: "100%",
									// backgroundColor: "white",
									borderRadius: "100px",
								}}
								alt="hianga logo"
							></img>
						</Link>
						<Link
							to="/women"
							onClick={() => fireEvent("women")}
							className={activeTab === "women" ? "category active" : "category"}
						>
							{category.women ? category.women : "WOMEN"}
						</Link>
						<Link
							to="/men"
							onClick={() => fireEvent("men")}
							className={activeTab === "men" ? "category active" : "category"}
						>
						    {category.men ? category.men : "MEN"}
						</Link>
						<Link
							onClick={() => fireEvent("kids")}
							className={activeTab === "kids" ? "category active" : "category"}
							to="/kids"
						>
							{category.kids? category.kids: "KIDDIES"}
						</Link>
						<Link
							onClick={() => fireEvent("fitness")}
							className={
								activeTab === "fitness" ? "category active" : "category"
							}
							to="/fitness"
						>
							{category.fitness? category.fitness: "FITNESS"}
						</Link>
						<Link
							onClick={() => fireEvent("jewellery")}
							className={
								activeTab === "jewellery" ? "category active" : "category"
							}
							to="/jewellery"
						>
							{category.jewellery? category.jewellery: "JEWELLERY"}
						</Link>
						<Link
							onClick={() => fireEvent("root")}
							className={activeTab === "root" ? "category active" : "category"}
							to="/root"
						>
							{category.root? category.root: "ROOT"}
						</Link>
					</div>
					<div className="center">
						<SearchFilter handleSearch={handleSearch} />

						<ul>
							{searchResults.map((result, index) => {
								const id = result.id
								const url = "/products/"+id
								return(
									id === 0 ?
									<li key={index}>{result.name}</li>
									:
									<li key={index}><div style={{"cursor":"pointer"}} to={url} onClick={()=>goToMenu(id, url)}>{result.name}</div></li>
								)
							})}
						</ul>
					</div>
					<div className="right">
						<Link
							onClick={() => fireEvent("yeshua")}
							className={
								activeTab === "yeshua" ? "category active" : "category"
							}
							to="/yeshua"
							style={{ textDecoration: "none", fontSize:"24px" }}
						>
							{category.yeshua? category.yeshua: "ישוע"}
						</Link>
						{/* start modal */}
						<div
							type="button"
							data-bs-toggle="offcanvas"
							data-bs-target="#demo"
						>
							{/* <Icon  icon="mdi:user-convert" /> */}
							<Icon
								className="usericon "
								icon="streamline:interface-user-single-close-geometric-human-person-single-up-user"
							/>
						</div>

						<div className="offcanvas offcanvas-top" id="demo">
							<div className="mycanvas">
								<div className="offcanvas-header">
									<div className="myAuth">
										{loggedin ? (
											<>
												<a href="#" className="auth">
													Hi {user.fname}
												</a>
												<a href="#" onClick={(e) => logOut()} className="auth">
													Sign Out
												</a>
											</>
										) : (
											<>
												<Link
													onClick={() => fireEvent("signIn")}
													to="/signIn"
													className="auth"
												>
													Sign In
												</Link>
												<Link
													onClick={() => fireEvent("signUp")}
													to="/signUp"
													className="auth"
												>
													Join Now
												</Link>
											</>
										)}
									</div>
									<button
										type="button"
										className="btn-close"
										data-bs-dismiss="offcanvas"
									></button>
								</div>
								<div className="offcanvas-body">
									<div className="myAcount">
										<Link
											onClick={() => fireEvent("my-account/orders")}
											to="/my-account/orders"
											className="account"
										>
											<Icon icon="icon-park-solid:order" className="icon" />
											<h4>My Order</h4>
										</Link>
										<Link
											onClick={() => fireEvent("my-account")}
											to="/my-account"
											className="account"
										>
											<Icon
												icon="material-symbols:account-circle-off-rounded"
												className="icon"
											/>
											<h4>My Account</h4>
										</Link>
									</div>

									<div className="myAcount">
										<Link
											onClick={() => fireEvent("signIn")}
											to="/terms"
											className="account"
										>
											<Icon icon="icon-park-solid:order" className="icon" />
											<h4>Retunds & FAQs</h4>
										</Link>
										<Link
											onClick={() => fireEvent("signUp")}
											to="/touch"
											className="account"
										>
											<Icon
												icon="material-symbols:account-circle-off-rounded"
												className="icon"
											/>
											<h4>Contact preferences</h4>
										</Link>
									</div>
								</div>
							</div>
						</div>
						{/* end of modal */}

						<Link
							onClick={() => {
								fireEvent("wishlist");
							}}
							to="/wishlist"
							className=""
						>
							{/* <Icon  icon="mdi:cards-heart"></Icon> */}
							<Icon className="usericon" icon="mdi:heart-outline" />
							<Badge
								newIcon={true}
								style={{
									color: "white",
									margin: "1px 0 0 -25px",
									fontSize: "10px",
									backgroundColor: "#100840",
								}}
							>
								{wishListStorage}
							</Badge>
						</Link>
						<Link
							onClick={() => {
								fireEvent("cart");
								// closeMobileMenu();
							}}
							to="/cart"
							className=""
						>
							<Icon className="usericon" icon="bytesize:bag" />
							<Badge
								newIcon={true}
								style={{
									color: "white",
									margin: "1px 0 0 -25px",
									fontSize: "10px",
									backgroundColor: "#100840",
								}}
							>
								{cartStorage}
							</Badge>
						</Link>
					</div>
				</div>
			)}

			{/* mobile view */}
			{showHeader && (
				<nav className="navbar navbar-expand-lg navbar-light bg-light topmost">
					<div className="container-fluid">
						<button
							className="navbar-toggler order-lg-0"
							type="button"
							data-bs-toggle="offcanvas"
							data-bs-target="#sidebar"
							aria-controls="sidebar"
							onClick={() => setCanvasMenuOpen(!canvasMenuOpen)}
						>
							<span className="navbar-toggler-icon"></span>
						</button>
						<Link onClick={() => fireEvent("site")} to="/" className="mainlogo">
							HIANGA
						</Link>

						{/* icons before mobile view */}

						<div className="right1">
							{/* start modal */}

							<div
								type="button"
								data-bs-toggle="offcanvas"
								id="closeMobileMenu"
							>
								<Link to="/signin">
									<Icon
										className="usericon mobileusericon"
										icon="streamline:interface-user-single-close-geometric-human-person-single-up-user"
										color="grey"
									/>
								</Link>
							</div>

							{/* end of modal */}
							<Link
								to="/wishlist"
								className="wishlist1"
								onClick={() => closeMobileMenu()}
							>
								<Icon
									className="usericon mobileusericon"
									icon="mdi:heart-outline"
									color="grey"
								/>
								<Badge
									newIcon={true}
									style={{
										color: "white",
										margin: "1px 0 0 -20px",
										fontSize: "6px",
										backgroundColor: "#100840",
									}}
								>
									{wishListStorage}
								</Badge>
							</Link>
							<Link
								to="/cart"
								className="cart1"
								onClick={() => closeMobileMenu()}
							>
								<Icon
									className="usericon mobileusericon"
									icon="bytesize:bag"
									color="grey"
								/>
								<Badge
									newIcon={true}
									style={{
										color: "white",
										margin: "1px 0 0 -25px",
										fontSize: "6px",
										backgroundColor: "#100840",
									}}
								>
									{cartStorage}
								</Badge>
							</Link>
						</div>
						{/* end of icons before mobile view */}

						<div
							className="offcanvas offcanvas-end"
							tabIndex="-1"
							id="sidebar"
							aria-labelledby="sidebarLabel"
						>
							<div className="offcanvas-header">
								<Link
									onClick={() => fireEvent("site")}
									to="/"
									className="offcanvas-title minilogo"
									id="sidebarLabel"
								>
									HIANGA Menu
								</Link>
								<div className="right">
									{/* start modal */}

									<div
										type="button"
										data-bs-toggle="offcanvas"
										id="closeMobileMenu"
									>
										<Icon
											className="usericon mobileusericon"
											icon="streamline:interface-user-single-close-geometric-human-person-single-up-user"
											color="grey"
										/>
									</div>

									{/* end of modal */}
									<Link
										to="/wishlist"
										className="wishlist1"
										onClick={() => closeMobileMenu()}
									>
										<Icon
											className="usericon mobileusericon"
											icon="mdi:heart-outline"
											color="grey"
										/>
										<Badge
											newIcon={true}
											style={{
												color: "white",
												margin: "1px 0 0 -20px",
												fontSize: "6px",
												backgroundColor: "#100840",
											}}
										>
											{wishListStorage}
										</Badge>
									</Link>
									<Link
										to="/cart"
										className="cart1"
										onClick={() => closeMobileMenu()}
									>
										<Icon
											className="usericon mobileusericon"
											icon="bytesize:bag"
											color="grey"
										/>
										<Badge
											newIcon={true}
											style={{
												color: "white",
												margin: "1px 0 0 -25px",
												fontSize: "6px",
												backgroundColor: "#100840",
											}}
										>
											{cartStorage}
										</Badge>
									</Link>
								</div>
								<button
									type="button"
									className="btn-close text-reset"
									data-bs-dismiss="offcanvas"
									aria-label="Close"
								></button>
							</div>
							<div className="offcanvas-body bg-dark">
								<ul className="navbar-nav megainner1">
									<div className="topmenu3">
										<div className="left">
											<Link
												to="/"
												onClick={() => {
													fireEvent("site");
													closeCanvasMenu();
												}}
												className="logo"
											>
												HIANGA
											</Link>
											<Link
												to="/women"
												onClick={() => {
													fireEvent("women");
													closeCanvasMenu();
												}}
												className={
													activeTab === "women" ? "category active" : "category"
												}
											>
												{category.women ? category.women : "WOMEN"}
											</Link>
											<Link
												to="/men"
												onClick={() => {
													fireEvent("men");
													closeCanvasMenu();
												}}
												className={
													activeTab === "men" ? "category active" : "category"
												}
											>
											    {category.men ? category.men : "MEN"}
											</Link>
											<Link
												onClick={() => fireEvent("kids")}
												className={
													activeTab === "kids" ? "category active" : "category"
												}
												to="/kids"
											>
												{category.kids? category.kids: "KIDDIES"}
											</Link>
											<Link
												onClick={() => fireEvent("fitness")}
												className={
													activeTab === "fitness"
														? "category active"
														: "category"
												}
												to="/fitness"
											>
												{category.fitness? category.fitness: "FITNESS"}
											</Link>
											<Link
												onClick={() => fireEvent("jewellery")}
												className={
													activeTab === "jewellery"
														? "category active"
														: "category"
												}
												to="/jewellery"
											>
												{category.jewellery? category.jewellery: "JEWELLERY"}
											</Link>
											<Link
												onClick={() => fireEvent("root")}
												className={
													activeTab === "root" ? "category active" : "category"
												}
												to="/root"
											>
												{category.root? category.root: "ROOT"}
											</Link>
											<Link
												onClick={() => fireEvent("yeshua")}
												className={
													activeTab === "yeshua"
														? "category active"
														: "category"
												}
												to="/yeshua"
												style={{fontSize:"15px"}}
											>
												{category.yeshua? category.yeshua: "ישוע"}
											</Link>
										</div>
									</div>

									<MegaMenu />
								</ul>
							</div>
						</div>
					</div>
				</nav>
			)}
		</div>
	);
}
