import { createContext, useState, useEffect } from "react";
import { getItem } from "../config/config";
import Images from "../config/Images";

const ProductContext = createContext();
const { Provider } = ProductContext;

const ProductProvider = ({ children }) => {
  const [products, setProducts] = useState([ ]);

  useEffect(() => {
    (async()=>{
      const p = await getItem("products")
      setProducts(Array.isArray(p) ? p:[])
    })()
  }, []);

  return <Provider value={{ products, setProducts }}>{children}</Provider>;
};

export { ProductContext, ProductProvider };
