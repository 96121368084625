import { useHistory } from "react-router-dom"
import { get } from "../config/ajaxRequest";
import { getItem } from "../config/config";

const clearUserInfo = () =>{
  try{ 
    const token = getItem("token")
    localStorage.removeItem("user") 
    localStorage.removeItem("token")
    if(!token || token.length < 5)get("logout")   
  }catch(err){}

}
export const logOut = async() => {
  clearUserInfo()
  window.location.href = "/signIn"

};


export const checkUserLogin = async() =>{ 
  try{ 
      const token = await getItem("token") 
      if(!token || token.length < 5){clearUserInfo();return "/signIn"}
       
      const res = await get("user")  
      if(res!="user" && res!="admin"){
        clearUserInfo() 
        return "/signIn"
      }
      return null
  }catch(err){clearUserInfo();return "/signIn"}
}


export const CheckAdminLogin = async() =>{ 
  try{
      const token = await getItem("token") 
      if(!token || token.length < 5){clearUserInfo();return "/signIn"}

      const res = await get("user")  
      // return
      if(res!="admin"){
        clearUserInfo()
          return "/signIn"
      }
      return null
  }catch(err){clearUserInfo();return "/signIn"}
}

export const signInwithFacebook = async() =>{
    /*try {
        await new Promise((resolve, reject) => {
          window.fbAsyncInit = function() {
            FB.init({
              appId      : 'YOUR_APP_ID',
              cookie     : true,
              xfbml      : true,
              version    : 'v12.0'
            });
            resolve();
          };
          (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
          }(document, 'script', 'facebook-jssdk'));
        });
        const response = await new Promise((resolve, reject) => {
          FB.login((response) => {
            if (response.authResponse) {
              FB.api('/me', {fields: 'id,name,email,picture'}, (response) => {
                resolve(response);
              });
            } else {
              reject('User cancelled login or did not fully authorize.');
            }
          });
        });
        const userInfo = {
          id: response.id,
          name: response.name,
          email: response.email,
          image: response.picture.data.url,
        };
        //console.log(userInfo);
        return userInfo
      } catch (error) {
        //console.log('Error: ' + error);
        return {}
      }
      */
}


export const signInwithGoogle = async()=>{
  /*
  try {
      await gapi.client.init({
          apiKey: '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',
          clientId: '99181804016-ve30jhpdus5jjdeelp5p521bc86si20d.apps.googleusercontent.com',
          scope: 'profile email'
        });
        const user = await gapi.auth2.getAuthInstance().signIn();
        const userProfile = user.getBasicProfile();
        const userInfo = {
          id: userProfile.getId(),
          name: userProfile.getName(),
          email: userProfile.getEmail(),
          image: userProfile.getImageUrl(),
        };
        //console.log(userInfo);
        return userInfo
  } catch (error) {
      //console.log('Error: ' + error.result.error.message);
      return {}
  } 
  */ 
}
