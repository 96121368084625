import { getItem } from "../config/config";


export function RawHtml(htmlContent) {
    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
}
export const saveItem = async (productToSave, setSavedItems, aler, setCart=null) => {  
    productToSave = formatObj(productToSave);
    const itemsInListFromLocalStorage = (await getItem("savedItems")) ?? [];
    const found = itemsInListFromLocalStorage.find(
        (item) => (item.index === productToSave.index && (item.id && item.id === productToSave.id) || (item.product_id && item.product_id === productToSave.product_id))

    );
    //console.log("testingo",found, productToSave.id, itemsInListFromLocalStorage)
    if (found) {
        aler("Product is already in the wishlist", "success");
        return;
    }

    setSavedItems([...itemsInListFromLocalStorage, productToSave]);
    aler("Product Saved To Wishlist", "success");
    
    if(setCart)removeItemFromCart(productToSave, setCart)
    
};

const removeItemFromCart = (item, setCart)=>{
    const carts = ( getItem("cart")) ?? [];
    const c = carts.filter((cart) => { 
        //console.log(cart.id, item.id, cart, item, "yes")
        return !(cart.id === item.id && cart.index===item.index);
    });

    setCart(c);
}

export const formatObj = (p) => {
    let p2 = {...p}
    if(p2.productprice){
      p2.price = p2.productprice.price
      p2.discount = p2.productprice.discount
      delete p2.productprice
    }
    
    if(p2.product_id){
      p2.id = p2.product_id; 
      delete p2.product_id;
    }
    
    p2.quantity = 1
    if(p2.menuthree_id)delete p2.menuthree_id
    if(p2.productphotos)delete p2.productphotos 
    if(p2.aboutme)delete p2.aboutme 
    if(p2.details)delete p2.details 
    if(p2.lookafterme)delete p2.lookafterme 

    return p2;
};


export function getRandomNumber(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }