import React, { useContext, useEffect, useState } from "react";
import { PayPalScriptProvider, PaymentGates } from "@paypal/react-paypal-js";
import "../../asset/css/paybtns.css";
import { getProductPrices } from "../../utils/priceCaculator";
import { useAddress } from "../../customHook/useAddress";
import { get, post } from "../../config/ajaxRequest";
import { CartContext } from "../../contexts/CartContext";
import aler from "../../utils/aler";
import Preloader from "../../config/Preloader";
import PayPalButton from "./PayPalButton";
import MyPaystackButton from "./MyPaystackButton";
 
const PaymentGate = ({paystackKey, paypalKey, exchangeRate, paymentMethod, mercentprice}) => { 
	const [checking, setChecking] = useState(true) 
	const { cart, setCart } = useContext(CartContext);
	const [userDetails, setUserDetails] = useState({});
	const [total, setTotal] = useState(0) 
	const { address } = useAddress(setChecking);
  
	useEffect(() => {
    (async ()=>{ 
        try{ 
          if(cart.length < 1)return
            setChecking(true)
            const serverTotal = await getProductPrices(cart, setCart, true) 
            setTotal(parseFloat(parseFloat(serverTotal).toFixed(2)))
        }catch(err){ 
            setTotal(10000.00)
        }
        setChecking(false)
    })()
  }, [cart]);
 
      useEffect(() => {
        (()=>{ 
            address.forEach((res) => { 
                if (res.type == "billing") setUserDetails({ ...res });
            }); 
        })()
      }, []);
         
	return (
		<>
			<input type="text" readOnly style={{ border:"none", position:"relative"}} autoFocus />
			{checking && <Preloader/>}
			{total > 0 && paymentMethod === "paypal" && <PayPalButton  exchangeRate={exchangeRate} total={total} userDetails={userDetails} setChecking={setChecking} mercentprice={mercentprice} paypalKey={paypalKey}/>}
			{total > 0 &&  paymentMethod === "paystack" && <MyPaystackButton exchangeRate={exchangeRate} total={total} userDetails={userDetails} setChecking={setChecking} mercentprice={mercentprice} paystackKey={paystackKey}/>}
		</>
	);
};

export default PaymentGate;
