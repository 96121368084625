import React, { useState } from "react";
import Modal from "react-modal";
import "../../asset/css/star.css"; // Import your CSS file for styling
import { motion } from "framer-motion";

const modalVariants = {
	initial: { scale: 0 },
	animate: { scale: 1 },
	exit: { scale: 0 },
};

Modal.setAppElement("#root");
const StarRating = ({productRating}) => {
	const [rating, setRating] = useState(productRating ? parseInt(productRating): 0);
	const [modalIsOpen, setModalIsOpen] = useState(false);

	const handleRatingClick = (newRating) => {
		return
		setRating(newRating);
		setModalIsOpen(true);

		setTimeout(() => {
			setModalIsOpen(false);
		}, 2000);
	};

	return (
		<div className="star-rating-container">
			<p className="rating-label">Rating: {productRating} stars</p>
			<div className="star-rating">
				{[1, 2, 3, 4, 5].map((star) => (
					<Star
						key={star}
						selected={star <= rating}
						onClick={() => handleRatingClick(star)}
					/>
				))}
			</div>
			<motion.div
				initial="initial"
				animate={modalIsOpen ? "animate" : "initial"}
				exit="exit"
				variants={modalVariants}
				className="modal"
			>
				<div className="modal-content">
					<p className="modal-text">
						<span className="check-mark">✓</span> Rating saved
					</p>
				</div>
			</motion.div>
		</div>
	);
};

const Star = ({ selected, onClick }) => (
	<span className={selected ? "star selected" : "star"} onClick={onClick}>
		★
	</span>
);

export default StarRating;
