import React, { useState, useEffect } from "react";
import Banner from "../women/Banner";
import "../../asset/css/help.css";
import { BiChevronDown, BiChevronUp, BiQuestionMark } from "react-icons/bi";
import { Link } from "react-router-dom";
import {
	BsBorder,
	BsPaypal,
	BsReceipt,
	BsRobot,
	BsTruck,
} from "react-icons/bs";
import * as MaterialDesign from "react-icons/bs";
import * as MaterialDesign2 from "react-icons/tb";
import * as MaterialDesign3 from "react-icons/bs";
import Socials from "../women/Socials";
import Footer from "../Footer";
import { get } from "../../config/ajaxRequest";

export default function MainHelp() {
	const [showList, setShowList] = useState(false);
	const [activeIndex, setActiveIndex] = useState(null);
	const [faqs, setFaqs] = useState([]);

	useEffect(() => {
		(async () => {
			const response = await get("user/faq");
			//console.log(response)
			setFaqs(response);
		})();
	}, []);

	const test = "BsTruck";

	const toggleList = () => {
		setShowList(!showList);
	};
	// faq array

	function toggleIndex(id) {
		if (activeIndex === id) {
			setActiveIndex(null);
		} else {
			setActiveIndex(id);
		}
	}

	return (
		<div className="container">
			<Banner />
			<div className="topbg"></div>
			<div className="topcolor">
				<span onClick={toggleList}>
					{showList ? (
						<BiChevronUp color="white" size={40} />
					) : (
						<BiChevronDown color="white" size={40} />
					)}
				</span>
				{showList && (
					<h5>
						Due to the ongoing situation, it’s not possible for us to fulfil any
						orders to Ukraine currently - <Link to={"/touch"}>click here</Link>{" "}
						for more info.
					</h5>
				)}
			</div>
			{/* <div className="topic"></div>
      <h3 className="topich3">FAQ TOPICS</h3> */}
			<div className="container-fluid">
				{/* delivery */}
				<div className="row">
					{faqs.map((faq, index) => {
						return (
							<div
								className="col-xs-12 col-sm-12 col-md-6 col-lg-6"
								key={index}
							>
								<div className="iconwithtitlel">
									{MaterialDesign[faq.icon] &&
										React.createElement(MaterialDesign[faq.icon], { size: 30 })}
									{MaterialDesign2[faq.icon] &&
										React.createElement(MaterialDesign2[faq.icon], {
											size: 30,
										})}
									{MaterialDesign3[faq.icon] &&
										React.createElement(MaterialDesign3[faq.icon], {
											size: 30,
										})}
									<h4>{faq.name}</h4>
									<hr />
								</div>
								<div>
									{faq.faqs.map((item, index) => {
										return (
											<div key={item.id}>
												<div
													className="dynamic"
													onClick={() => toggleIndex(item.id)}
												>
													<span className="question"> {item.question}</span>
													<BiQuestionMark />
												</div>
												{activeIndex === item.id && (
													<p className="answer">{item.answer}</p>
												)}
											</div>
										);
									})}
								</div>
							</div>
						);
					})}
				</div>
			</div>
			{/* contact */}
			<div className="contact">
				<h2 className="intouch">Need To Get Intouch?</h2>
				<div className="con">
					<button>
						<Link className="btnlink" to={"./touch"}>
							Contact Us
						</Link>
					</button>
				</div>
			</div>
			<Socials />
			<Footer />
		</div>
	);
}
