import { createContext, useState, useEffect } from "react";
import { getItem, setItem } from "../config/config";

const CartContext = createContext();
const { Provider } = CartContext;

const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    ( async()=>{
      const c = await getItem("cart") 
      setCount(count+1) 
      if(c && c.length>0 && count==0)return setCart(c)


      if(JSON.stringify(cart) == JSON.stringify(c))return
      setItem("cart", cart)
      
      // Creating a custom event
      var myEvent = new Event('myEvent', { bubbles: true });
      document.dispatchEvent(myEvent);
    })()
  }, [cart]);

  
  return <Provider value={{ cart, setCart }}>{children}</Provider>;
};

export { CartContext, CartProvider };
