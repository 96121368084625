import { useState } from "react";
import { useHistory } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import SocialMedia from "../../components/auth/SocialMedia";
import AuthLocation from "../../components/auth/AuthLocation";

import "../../styles/auth.css";
import { get, post } from "../../config/ajaxRequest";
import { setItem } from "../../config/config";
import Preloader from "../../config/Preloader";
import aler from "../../utils/aler";
import { validateEmail, validatePassword } from "../../utils/validation";

function SignUp() {
  let history = useHistory();

  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false);

  const [Data, setData] = useState({
    fname: "",
    lname: "",
    email: "",
    password: "",
    dob: "",
    preferences:"",
    interest:""
  });

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };
  
  const onChange = (event) => { 
    setData({...Data, [event.target.name]: event.target.value});
 
  };

  async function handleSubmit (event) {
    event.preventDefault();  
    if(!validateEmail(Data.email, aler))return
    if(!validatePassword(Data.password, aler))return 
    setLoading(true)
    //remember to validate inputs before submitting
    try{
      const res = await post("register", Data)
      // //console.log(res)
      const {email, fname, lname} = Data

      if(!res.token || res.token.length< 3){
        setLoading(false);
        return aler(res)
      }
      
      setItem("user", {email:email, fname:fname, lname:lname, usertype:"user"})
      setItem("token", res.token)
      get("execute/job")
      history.push('/my-account')
    }catch(err){aler("An error occured, check your network")}
    setLoading(false)
  }


  const setPreferences = (event) =>{
    const preferences = Data.preferences
    const found = preferences.indexOf(event.target.value) > -1
    if(event.target.checked){
      if(found)return 
      setData({...Data, preferences: preferences+" "+event.target.value})
    }else{
      if(found){
        let newPreferences = preferences.replace(event.target.value, "").replace("  ", " ")
        setData({...Data, preferences:newPreferences})
      }
    }
  }

  return (
    <main>
      <h1>Hianga</h1>
      <section className="auth-container">
        <AuthLocation tab={1} />

        <h1 className="social__header">SIGN UP WITH...</h1>

        <SocialMedia
          setLoading={setLoading}
          subTitle={`Signing up with social is super quick. No extra passwords to remember - no brain fail. Don't worry, we'd never share any of your data or post anything on your behalf #notevil`}
        />

        <h1 className="social__header">OR SIGN UP WITH EMAIL</h1>

        {loading ?
        <Preloader/>
        :
          <form className="container" onSubmit={handleSubmit}>
            <section className="form-group">
              <label htmlFor="id" className="form-group__label">
                Email Address:
              </label>
              <br />
              <input type="text" id="email" name="email" onChange={onChange} value={Data.email} required/>
            </section>

            <section className="form-group">
              <label htmlFor="id" className="form-group__label">
                First Name:
              </label>
              <br />
              <input type="text" id="fname" name="fname" onChange={onChange} value={Data.fname} required />
            </section>

            <section className="form-group">
              <label htmlFor="id" className="form-group__label">
                Last Name:
              </label>
              <br />
              <input type="text" id="lname" name="lname" onChange={onChange} value={Data.lname} required/>
            </section>

            <section className="form-group">
              <label htmlFor="id" className="form-group__label">
                Password:
              </label>
              <br />
              <div className="password-input-wrapper">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  onChange={onChange}
                />
                <button
                  type="button"
                  className="password-toggle-button"                  
                >
                  {showPassword ? <FaEyeSlash size={16} onClick={togglePasswordVisibility} /> : <FaEye size={20} onClick={togglePasswordVisibility} />}
                </button>
              </div>
            </section>

            {/* Date Of Birth */}
            {/* <section className="form-group">
              <label htmlFor="dob">DATE OF BIRTH : </label>
              <input type="date" name="dob" id="dob" onChange={onChange} value={Data.dob}/>
            </section> */}

              <button type="submit" className="joinButton active">JOIN NOW</button>
            {/* </section> */}
          </form>
        }
      </section>
    </main>
  );
}

export default SignUp;


/*
            {/* Radio Input * /}
            <section className="form-group">
              <label htmlFor="dob">MOST INTERESTED IN : </label>
              <section className="radio-group">
                <section className="label-group">
                  <input type="radio" name="interest" id="interest" value="womenswear" onChange={onChange}/>
                  <label htmlFor="choice">Womenswear</label>
                </section>
                <section className="label-group">
                  <input type="radio" name="choice" id="choice" value="menswear" onChange={onChange} />
                  <label htmlFor="choice">Menswear</label>
                </section>
              </section>
            </section> 

              {/* Contact Preference CheckBoxes * /}
              <section className="checkbox">
                <section className="checkbox__item">
                  <label htmlFor="">Discount and sales</label>
                  <input type="checkbox" name="preferences" id="preferences" value="Discount And Sales" onChange={e=>setPreferences(e)} />
                </section>
                <section className="checkbox__item">
                  <label htmlFor="">New Stuff</label>
                  <input type="checkbox" name="preferences" id="preferences" value="New Stuff" onChange={e=>setPreferences(e)} />
                </section>
                <section className="checkbox__item">
                  <label htmlFor="">Your Exculsives</label>
                  <input type="checkbox" name="preferences" id="preferences" value="Your Exculsives"  onChange={e=>setPreferences(e)} />
                </section>
              </section>

            {/* Contact Preference * /}

            <section className="contact__preference">
              {/* Contact Preference Header * /}
              <section className="contact__header">
                <section>
                  <h3>Contact preferences</h3>
                  <p>Tell us which emails you'd like:</p>
                </section>
                <button type="button">SELECT ALL</button>
              </section>




*/