import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../asset/css/menustyles.css";
import Images from "../../config/Images";
import { useMainMenu } from "../../customHook/useMainMenu";
import { DropDownMenu } from "./DropDownMenu";
import SearchFilter from "./SearchFilter";

const HoverMenu = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [menuId, setMenuId] = useState(null);
	const mainMenu = useMainMenu(window.location.pathname);

	// start if search logic

	const [searchResults, setSearchResults] = useState([]);

	const handleSearch = (query) => {
		// For demonstration purposes, we're just filtering an array of dummy products.
		const dummyProducts = [
			{ id: 1, name: "bag 1" },
			{ id: 2, name: "shoe 2" },
			{ id: 3, name: "shirt with lots of colors" },
			{ id: 4, name: "clothes 3" },
			{ id: 5, name: "glasses 3" },
			// Add more products here...
		];

		const filteredProducts = dummyProducts.filter((product) =>
			product.name.toLowerCase().includes(query.toLowerCase())
		);

		setSearchResults(filteredProducts);
	};
	// end of search logic

	useEffect(() => {
		handleMouseLeave();
	}, [mainMenu]);

	const handleMouseEnter = (id) => {
		if (id > -1) {
			setMenuId(id);
			setIsOpen(true);
		}
	};

	const handleMouseLeave = (e) => {
		setIsOpen(false);
		setMenuId(-1);
		return;
	};

	return (
		<div className="hover-menu">
			<div className="container menu2">
				{/* <div className="center hideMobileSearch">
					<SearchFilter handleSearch={handleSearch} />

					<ul>
						{searchResults.map((result) => (
							<li key={result.id}>{result.name}</li>
						))}
					</ul>
				</div> */}
				{menuId !== -1 && (
					<Link
						to={"#"}
						className="offcanvas-title mediaBackMenu"
						id="sidebarLabel"
						style={{}}
						onClick={() => setMenuId(-1)}
					>
						<i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp;&nbsp;
						Main Menu
					</Link>
				)}
				{mainMenu &&
					mainMenu.map((response, index) => {
						const to = "/?cat=" + response.id;
						let className = "link";
						let displaymenuInMobile = "hideMobleMenu";
						if (menuId == response.id || menuId === -1)
							displaymenuInMobile = "";
						if (index === 0 || mainMenu.length - 2 === index)
							className = "link speciallink";
						return (
							<div
								key={index}
								className={className + " " + displaymenuInMobile}
								onMouseEnter={() => handleMouseEnter(response.id)}
							>
								<span>{response.name}</span>
							</div>
						);
					})}
				{/* <div className="link" onMouseEnter={() => handleMouseEnter(setIsOpen)}>
					<Link to="https://www.iiignacy.com">MarketPlace</Link>
				</div> */}
			</div>

			{isOpen && (
				<div
					onMouseEnter={() => handleMouseEnter(-1)}
					onMouseLeave={handleMouseLeave}
				>
					<DropDownMenu
						menuOneId={menuId}
						handleMouseLeave={handleMouseLeave}
					/>
				</div>
			)}
		</div>
	);
};

export default function MegaMenu() {
	return <HoverMenu />;
}
