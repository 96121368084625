import React from "react";
import { PulseLoader } from "react-spinners"; // Import the PulseLoader from react-spinners

import "../asset/css/Preloader.css"; // You can create a separate CSS file for styling

const Preloader = () => {
	return (
		<div className="preloader-container">
			<PulseLoader color="blue" size={15} />{" "}
			{/* Use the PulseLoader from react-spinners */}
		</div>
	);
};

export default Preloader;
