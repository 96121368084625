import { URL } from "./config";
async function setHeader() {
	let token = localStorage.getItem("token"); //?? "bbBzgZTFzNCpuiOdSkNlvjx4PXnI24FEoliTqIqX"
	let obj = {
		"Content-Type": "application/json",
	};

	if (token)
		obj["Authorization"] = "Bearer " + token.replace('"', "").replace('"', "");

	return obj;
}

export async function get(url) {
	let res = null;
	let finalUrl = URL + "/" + url;
	let data = null;
	// console.log(finalUrl);
	const headers = await setHeader();

	//console.log(finalUrl, headers, "token in get()");
	try {
		res = await fetch(finalUrl, {
			method: "GET",
			headers: headers,
		});

		data = await res.json();

		if (data) {
			/*
        let err = JSON.stringify(data) ?? "";
        let errMsg = throwErrorMessage(err)

        if(errMsg.length > 1){
            throw errMsg
        }*/

			return data?.message ? data.message : data;
		}
	} catch (err) {
		//console.log(err);
		throw err;
	}

	return data;
}

export async function post(url, formData, stringify = true) {
	let res = null;
	const endpoint = URL + "/" + url;
	const headers = await setHeader();
	if (!stringify) delete headers["Content-Type"];
	//console.log(
	// 	endpoint,
	// 	formData,
	// 	headers,
	// 	"formData in ajaxRequest for in config"
	// );
	try {
		res = await fetch(endpoint, {
			method: "POST",
			body: stringify ? JSON.stringify(formData) : formData,
			headers: headers,
		});
		let res2 = await res.json();
		//console.log(res2, "post()...");
		return res2?.message ? res2.message : res2;
	} catch (err) {
		throw err;
	}
}

function throwErrorMessage(msg) {
	if (msg.indexOf("Unable to log in") > -1)
		return "Invalid Username Or Password";
	if (msg.indexOf("credentials were not provided.") > -1) {
		return "Please Login";
	}
	if (msg.indexOf("Invalid token") > -1) {
		return "Please Login";
	}
}

async function customPost(
	data,
	url = "https://image.uberlive.website/notify.php",
	method = "POST"
) {
	let res = null;

	try {
		res = await fetch(url, {
			method: method,
			body: data,
			mode: "cors",
			cache: "default",
			credentials: "include",
		});
		return await res.text();
	} catch (err) {
		throw err;
	}
}

/***/
async function filePost(
	url = "https://image.uberlive.website",
	data,
	aler = null
) {
	try {
		let res = await fetch(url, {
			method: "POST",
			body: data,
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});

		return await res.json();
	} catch (err) {
		throw err;
	}
}
