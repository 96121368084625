import {useState, useEffect} from 'react'
import { setItem, getItem } from '../config/config'
import { get } from '../config/ajaxRequest'

export const useAddress = (setLoading=null)=>{ 
    const [address, setAddress] = useState([])

    useEffect(() => { 
            (async()=>{
                if(setLoading)setLoading(true)
                try{
                    const addressFromLocalStorage = await getItem("address")
                    if(Array.isArray(addressFromLocalStorage) && addressFromLocalStorage.length == 2){
                        if(setLoading)setLoading(false)
                        // console.log(addressFromLocalStorage, "from localstorage")
                        return setAddress(addressFromLocalStorage)
                    }

                    //get from backend
                    const res = await get("getaddress") 
                    // console.log(res, "from from server")
                    if(Array.isArray(res) && res.length>0){
                        setAddress(res) 
                        setItem("address", res)
                    } 
                }catch(err){}
                if(setLoading)setLoading(false)

            })()
        
    }, []);    

    
    return {address, setAddress}
}

