import React, { useState } from "react";
import "../../asset/css/DeliveryMethodComponent.css";

const DeliveryMethodComponent = ({ onDeliveryMethodChange }) => {
	const [selectedMethod, setSelectedMethod] = useState("");

	const handleMethodChange = (e) => {
		setSelectedMethod(e.target.value);
	};

	return (
		<div></div>
	);
};

export default DeliveryMethodComponent;
