import React from "react";
import { Link, useHistory } from "react-router-dom";

function AuthLocation({ tab }) {
  const history = useHistory()

  React.useEffect(() => {
       
  }, [tab]);

  const condition = tab === 1
  const hoverClass = condition ? 'auth-menu__left':'auth-menu__right'
  const onClickFun = url =>history.push(url)
  const cursorHand ={cursor:"pointer"}

  return (
    <section className={`container auth-menu + ${hoverClass}`}>
        <ul>
            <li style={cursorHand} onClick={()=>onClickFun('/signUp')} className={tab === 1 ? "joinButton active": "joinButton"}><Link to='/signUp' className="joinButton">JOIN</Link></li>
            <li style={cursorHand} onClick={()=>onClickFun('/signIn')} className={tab === 2 ? "joinButton active": "joinButton"}><Link to='/signIn' className="joinButton">SIGN IN</Link></li>
        </ul>
    </section>
  );
}

export default AuthLocation;
