import React, { useState, useEffect } from "react";
import Main from "./Menu/Main";
import { getItem } from "../config/config";
import PopupMessage from "../alerts/PopupMessage";
import { BiArrowFromBottom, BiArrowFromTop } from "react-icons/bi";

export default function ToggleNav() {
	const [showNavbar, setShowNavbar] = useState(true);
	const [hideMessage, setHideMessage] = useState(true);
	const [toggleDirection, setToggleDirection] = useState("down");

	const toggleNavbar = () => {
		setShowNavbar(!showNavbar);
		setToggleDirection(showNavbar ? "up" : "down");
	};

	useEffect(() => {
		document.addEventListener("popUpMessage", function (e) {
			if (hideMessage) setHideMessage(false);
		});
 
	}, []);

	return (
		<div>
			{showNavbar ? <Main /> : null}

			{!hideMessage && (
				<PopupMessage hideMessage={hideMessage} close={setHideMessage} />
			)}

			<div className="toggleNavbar">
				<button onClick={toggleNavbar}>
					<span>
						{toggleDirection === "down" ? (
							<BiArrowFromBottom />
						) : (
							<BiArrowFromTop />
						)}
					</span>
				</button>
			</div>
		</div>
	);
}
