import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import "../../styles/auth.css";

import SocialMedia from "../../components/auth/SocialMedia";
import AuthLocation from "../../components/auth/AuthLocation";
import { post } from "../../config/ajaxRequest";
import { getItem, setItem } from "../../config/config";
import Preloader from "../../config/Preloader";
import aler from "../../utils/aler";
import { validateEmail, validatePassword } from "../../utils/validation";

const redirect = (history) =>{
	const hasCheckoutParam = window.location.href.includes("?proceed=checkout");
	const redirectUrl = !hasCheckoutParam ? "/my-account" : "/checkout"; 
	return history.push(redirectUrl);
}

function SignIn() {
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [Data, setData] = useState({
		email: "",
		password: "",
	});

	useEffect(() => { 
		(async () => { 
			const token = await getItem("token"); 
			if (token && token.length > 3) { 
				return redirect(history)
			}
		})();
	}, []);

	const togglePasswordVisibility = () => {
		setShowPassword((prevState) => !prevState);
	};

	const onChange = (event) => {
		setData((prevState) => ({
			...prevState,
			[event.target.name]: event.target.value,
		}));
	};

	async function handleSubmit(event) {
		event.preventDefault();
		if (!validateEmail(Data.email, aler)) return;
		if (!validatePassword(Data.password, aler)) return;
		setLoading(true);
		try {
			const res = await post("login", Data);

			if (res?.user) {
				setItem("user", res.user);
				setItem("token", res.token);
				//after setting user and token in localstorage, the page will automatically redirect to dashboard when reloaded
				return redirect(history)//window.location.reload();
			}
			if (res.indexOf("Bad Credentials") > -1) {
				setLoading(false);
				return aler("Invalid Email Or Password");
			}
			aler(res);
		} catch (err) {
			aler("An error Occured....");
		}
		setLoading(false);
	}

	return (
		<main>
			<h1>Hianga</h1>

			<section className="auth-container">
				<AuthLocation tab={2} />

				{/* Sign In Form */}
				{loading ? (
					<Preloader />
				) : (
					<form className="container" onSubmit={handleSubmit}>
						{/* Email Address */}
						<section className="form-group">
							<label htmlFor="id" className="form-group__label">
								Email Address:{" "}
							</label>
							<br />
							<input
								type="email"
								id="email"
								name="email"
								value={Data.email}
								onChange={onChange}
							/>
						</section>

						{/* Password */}
						<section className="form-group">
							<label htmlFor="id" className="form-group__label">
								Password:
							</label>
							<br />
							<div className="password-input-wrapper">
								<input
									type={showPassword ? "text" : "password"}
									id="password"
									name="password"
									value={Data.password}
									onChange={onChange}
								/>
								<button type="button" className="password-toggle-button">
									{showPassword ? (
										<FaEyeSlash size={16} onClick={togglePasswordVisibility} />
									) : (
										<FaEye size={20} onClick={togglePasswordVisibility} />
									)}
								</button>
							</div>
						</section>

						<button type="submit" className="joinButton active">
							SIGN IN
						</button>

						<Link to="/forgetpassword" className="joinButton">
							<h5>Forgot Password?</h5>
						</Link>
					</form>
				)}
				<h1 className="social__header">OR SIGN IN WITH...</h1>
				<SocialMedia setLoading={setLoading} />
			</section>
		</main>
	);
}

export default SignIn;
