import React, { useState } from "react";
import aler from "../utils/aler";
import { get, post } from "../config/ajaxRequest";

const Assistant = () => {
	const [data, setData] = useState({name:"", email:"", message:""})

	const updateData = (e)=>{ 
		setData({...data, [e.target.name]:e.target.value})
	}

	const handleSubmit = async(e) => {
		e.preventDefault();
		 
		// console.log(data, "data")
		if(data.name.length < 1 || data.name.length<1 || data.message.length<1)return aler("All fields are required")
		 
		const res = await post("contact/form", data)
		
		let type = ""
		if(res.indexOf("Thank you reaching out") > -1){
			type="success"
			get("execute/job")
		}
		
		aler(res, type)

		setData({name:"", email:"", message:""})
	};

	return (
		<section id="contact">
			<h1 className="section-header">Let me Help You Today</h1>
			<div className="contact-wrapper">
				{/* Left contact page */}
				<form
					id="contact-form"
					className="form-horizontal"
					role="form"
					onSubmit={handleSubmit}
				>
					<div className="form-group">
						<div className="col-sm-12">
							<input
								type="text"
								className="form-control"
								id="name"
								placeholder="NAME"
								name="name"
								value={data.name}
								required
								onChange={updateData}
							/>
						</div>
					</div>

					<div className="form-group">
						<div className="col-sm-12">
							<input
								type="email"
								className="form-control"
								id="email"
								placeholder="EMAIL"
								name="email"
								value={data.email}
								required
								onChange={updateData}
							/>
						</div>
					</div>

					<textarea
						className="form-control"
						rows="10"
						placeholder="MESSAGE"
						name="message"
						required
						onChange={updateData}
						>{data.message}</textarea>

					<button
						className="btn btn-primary send-button"
						id="submit"
						type="submit"
						value="SEND"
					>
						<div className="alt-send-button">
							<i className="fa fa-paper-plane"></i>
							<span className="send-text">SEND</span>
						</div>
					</button>
				</form>
			</div>
		</section>
	);
};

export default Assistant;
