import React, { useContext, useState, useRef, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { BsHeart, BsBag, BsTruck, BsCalendar, BsFolder } from "react-icons/bs";
import { TfiAngleLeft, TfiAngleRight } from "react-icons/tfi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import "../../styles/product.css";
 
import { CartContext } from "../../contexts/CartContext";
import { WishContext } from "../../contexts/WishContext"; 
import RelatedProducts from "../../components/Product/RelatedProducts";
import { get } from "../../config/ajaxRequest";
import { getItem } from "../../config/config";
import Preloader from "../../config/Preloader";
import aler from "../../utils/aler";
import { motion } from "framer-motion"; 
import ButtonserverChange from "../../components/Menu/ThirdMenu/Ads/ButtonserverChange";
import StarRating from "./StarRating";
import ReviewPage from "./ReviewPage";
import Socials from "../../components/women/Socials";
import Footer from "../../components/Footer";
import Breadcrumbs from "../../components/Product/Breadcrumbs";
import { RawHtml, getRandomNumber, saveItem } from "../../utils/saveToCardAndWishList";
import { formatObj } from "../../utils/saveToCardAndWishList";

export default function Product() {
	const history = useHistory();
	const [reload, setLoad] = useState(0); 
	const { cart, setCart } = useContext(CartContext);
	const { savedItems, setSavedItems } = useContext(WishContext);
	const [product, setProduct] = useState({});
	const [productFilterFromServer, setProductFilterFromServer] = useState([]);
	const [selectedProperties, setSelectedProperties] = useState({});
	const [selectedQuantity, setSelectedQuantity] = useState(1);
	const [productFilterQuantity, setProductFilterQuantity] = useState({});
	const [index, setIndex] = useState(0); 
	const [preloader, setPreloader] = useState(false);
	const [scale, setScale] = useState(false); 
	const [move, setMove] = useState(false);
	const [isAccordionOpen, setIsAccordionOpen] = useState({
		item1: false,
		item2: false,
		item3: false,
		item4: false,
	});
	const [relatedProducts, setRelatedProducts] = useState([]);
	const [currentText, setCurrentText] = useState("");

	const image_preview = useRef(null);
 
	useEffect(() => {
		(async () => {
			let locArr = window.location.href.split("/");
			let lastIndex = locArr.length - 1;
			let id = locArr[lastIndex] ?? 1;

			setPreloader(true);
			try {
				const p = await get("user/singleproduct/" + id); 
				setProduct(p.product); 
				if (p?.product?.color) setCurrentText(p.product.color);
				const ind = product.productphotos.findIndex(
					(img) => img.pic === product.mainpic
				);
				if (ind && ind > -1) setIndex(ind); 
			} catch (err) {}

			setPreloader(false);
		})();
	}, [reload]);

	useEffect(() => {
		(()=>{
			if(!product || !product.id)return
			const quantities = JSON.parse(product.quantity)
			let obj = {}
			quantities.forEach(res=>{
				obj[res.filteroptionids] = res.quantity
			})
			setProductFilterQuantity(obj) 
		})()
	}, [product]);


	useEffect(() => {
		(async () => {
			let locArr = window.location.href.split("/");
			let lastIndex = locArr.length - 1;
			let id = locArr[lastIndex] ?? 1;

			setPreloader(true);
			try {
				const relaProducts = await get("relatedproducts/" + id);
				setRelatedProducts(relaProducts);
			} catch (err) {}

			setPreloader(false);
		})();
	}, [reload]);

	useEffect(() => {
		(async () => {
			let locArr = window.location.href.split("/");
			let lastIndex = locArr.length - 1;
			let id = locArr[lastIndex] ?? 1;

			setPreloader(true);
			try {
				const response = await get("product/filter/quantity?productIds=" + id);
				response.map((res) => {
					if (res.productId == id) setProductFilterFromServer(res.filter);
					let newProperties = {};
					res.filter.forEach((element) => {
						newProperties[element.id] = null;
					}); 
					setSelectedProperties(newProperties);
					 
				});
			} catch (err) {}

			setPreloader(false);
		})();
	}, [reload]);

	const toggleAccordion = (item) => {
		setIsAccordionOpen((prevState) => ({
			...prevState,
			[item]: !prevState[item],
		}));
	};
	

	const getMenuId = () => {
		let locArr = window.location.href.split("/");
		let lastIndex = locArr.length - 1;
		let id = locArr[lastIndex] ?? 1;
		return id;
	};

	const addToCart = async () => { 

		const itemsInListFromLocalStorage = (await getItem("cart")) ?? [];
 
		const foundProductInCartArr = itemsInListFromLocalStorage.filter(
			(item) => item.id === product.id
		);


		let matchingProperties = 0
		foundProductInCartArr.forEach((cartItem)=>{
			let found = 0
			Object.keys(cartItem.selectedQuantities).forEach((key) => {
				if (cartItem.selectedQuantities[key] == selectedProperties[key] && selectedProperties[key]!=null) {
					found += 1 
				}				 
			});
			if(found > matchingProperties)matchingProperties =  found
			
		}) 
		if (matchingProperties === productFilterFromServer.length) return aler("Product is already in cart", "success"); 
		let inValidQuantities = ""; 
		
		Object.keys(selectedProperties).map((key) => {
			if (selectedProperties[key] == null) {
				productFilterFromServer.forEach((filter) => {
					if (filter.id == key) inValidQuantities += filter.name + ", ";
				});
			}
		});

		if (Object.keys(selectedProperties).length > 0 && inValidQuantities.length > 0)
			return aler("Please select " + inValidQuantities);

		let newProduct = formatObj(product);
		newProduct = { ...newProduct, selectedQuantities: selectedProperties, quantity:selectedQuantity };
		const newCart = [...itemsInListFromLocalStorage, newProduct].map((res, index)=>{
			if(res.index)return res
			return {...res, index:getRandomNumber(1, 10000)}
		})
		setCart(newCart);

		aler("Product added to cart", "success");
	};

	const handleQuantities = (event, filterId) => {
		const value = event.target.value.length > 0 ? event.target.value : null;
		
		if(productFilterQuantity[","+value+","] === 0 || productFilterQuantity[","+value+","] === "0")return aler("Out of stock")
		let newProperty = { ...selectedProperties};
		newProperty[String(filterId)] = value;
		setSelectedProperties(newProperty);
	};

	const prevImage = () => {
		if (index === 0) {
			setIndex(product.productphotos.length - 1);
		} else {
			setIndex(index - 1);
		}
	};

	const nextImage = () => {
		if (index === product.productphotos.length - 1) {
			setIndex(0);
		} else {
			setIndex(index + 1);
		}
	};

	const changePreviewImg = (link) => {
		const index = product.productphotos.findIndex((img) => img.pic === link);
		setIndex(index);
	};

	const zoomIn = (event) => {
		const posX = event.clientX;
		const posY = event.clientY;
		const image = image_preview.current;
		image.style.transform = "scale(1.2)";
		image.style.transformOrigin = `${posX}%${posY}`;
		image.style.transition = `700ms all`;
	};

	const zoomOut = () => {
		const image = image_preview.current;
		image.style.transform = "scale(1)";
	};

	return (
		<div>
			<ButtonserverChange />
			<Breadcrumbs id={getMenuId()} type={"product"} menuName={product.title}/>
			<div className="mainProduct">
				{preloader && <Preloader />}

				{!preloader && product && Object.keys(product).length > 0 && (
					<section className="preproduct container">
						<section className="preproduct__images preproduct__images-sticky">
							<section className="preproduct__images--side">
								{product?.productphotos &&
									product.productphotos.length > 0 &&
									product.productphotos.map(({ pic, id }) => {
										return (
											<button
												key={id}
												onClick={() => {
													changePreviewImg(pic);
												}}
											>
												<img
													src={pic}
													style={{ objectFit: "cover" }}
													height="100px"
													alt="hianga products"
												/>
											</button>
										);
									})}
							</section>
							<motion.section
								className="preproduct__images--main"
								onMouseMove={zoomIn}
								onMouseOut={zoomOut}
							>
								<section className="preproduct__images--main-controls">
									<button>
										<TfiAngleLeft size={30} onClick={prevImage} />
									</button>
									<button>
										<TfiAngleRight size={30} onClick={nextImage} />
									</button>
								</section>

								{product.productphotos && product.productphotos.length > 0 ? (
									<motion.section animate={{ x: 0 }} initial={{ x: -400 }}>
										<img
											ref={image_preview}
											src={product.productphotos[index]?.pic ?? product.mainpic}
											alt={product.title + " Image"}
											style={{ objectFit: "scale-down" }}
											width="30%"
										/>
									</motion.section>
								) : (
									<motion.section style={{ objectFit: "scale-down" }}>
										<img
											ref={image_preview}
											src={product.mainpic ?? ""}
											alt={product.title + "Image"}
										/>
									</motion.section>
								)}
							</motion.section>
						</section>

						<motion.section
							animate={{ scale: 1, x: 0, y: 0 }}
							initial={{ y: 200, x: 100, scale: 4 }}
							className="preproduct__details sticky"
						>
							<section className="preproduct__details--name">
								<h4 style={{ fontVariant: "small-caps", fontWeight: "800" }}>
									{RawHtml(product.title)}
								</h4>
								{product.short && product.short.length > 0 && (
									<h6 style={{ fontSize: "13px", fontWeight: "200" }}>
										{RawHtml(product.short)}
									</h6>
								)}
								{/* <motion.button onClick={saveItem}>
									<BsHeart size={24} className="heartProduct" />
								</motion.button> */}
							</section>

							<section className="preproduct__details--price">
								{!product?.productprice?.discount ||
								product?.productprice?.discount === 0 ? (
									<h2 style={{ fontWeight: "800" }}>
										${parseFloat(product?.productprice?.price).toFixed(2)}
									</h2>
								) : (
									<>
										<h4 style={{ fontWeight: "800" }}>
											$
											{parseFloat(product?.productprice?.price -
												product?.productprice?.price *
													product?.productprice?.discount / 100).toFixed(2)}
										</h4>
										<h6
											className="preproduct__details--price-inactive"
											style={{
												color: "maroon",
												textDecoration: "line-through",
												fontWeight: "700",
											}}
										>
											${parseFloat(product?.productprice?.price).toFixed(2)}
										</h6>
									</>
								)}
							</section>
							{product?.productprice?.discount > 0 &&
								<section> 
									<div key={index} className="banner-item">
										<h5 className="big-text" style={{ color: "black" }}>
											{product?.productprice?.discount}% Discount
										</h5>
									</div> 
								</section>
							}
							<section className="">
								<StarRating productRating={product.rating} />
								<section className="product__info--attr">
									<motion.span
										animate={{ scale: 1 }}
										initial={{ x: 0, scale: 3 }}
										style={{display:"flex", marginLeft:"30%", justifyContent:"center", width:"40%"}}
									>
										<span
											style={{
												color: "black",
												fontWeight: "600",
												fontSize: "15px",
											}}
										>
											COLOR:
										</span>
										<span
											className="color-text"
											style={{
												color: "black",
												fontWeight: "600",
												// width: "100%",
												textAlign:"left",
											}}
										>
											{RawHtml(currentText)}
										</span>
									</motion.span>
									{/* <span>{product?.color}</span> */}
								</section>

								{/* <h5>Colors:</h5> */}
								{relatedProducts.length > 0 &&
									relatedProducts.map(({ color, mainpic, id }) => {
										if (id == product.id) return <></>;
										return (
											<motion.button
												key={id}
												onClick={() => {
													changePreviewImg(mainpic);
													setMove(!move);

													const urlArr = window.location.href.split("/");
													const oldId = urlArr.pop();
													const url = window.location.pathname.replace(
														"/" + oldId,
														`/${id}`
													);
													history.push(url);
													return setLoad(id);
												}}
												onMouseEnter={() => setCurrentText(color)}
												animate={{ x: move ? 0 : 10 }}
											>
												<motion.section
													animate={{ x: move ? -50 : 0 }}
													onClick={() => {
														setMove(!move);
													}}
												>
													<img
														src={mainpic}
														style={{ objectFit: "cover", padding: "15px" }}
														height="100px"
														alt="hianga products"
													/>
												</motion.section>
											</motion.button>
										);
									})}
							</section>
							<hr />

							<section className="preproduct__details--buttons">
								{productFilterFromServer.length > 0 &&
									productFilterFromServer.map((filter, index) => {
										return (
											<div key={index}>
												<label
													className="text-dark"
													style={{
														textAlign: "left",
														marginTop: "15px",
														fontWeight: "bold",
													}}
												>
													{filter.name}:
												</label>
												<select
													key={index}
													onClick={(event) => {
														handleQuantities(event, filter.id);
													}}
													className="SelectSize"
													name="size"
												>
													<option value="">Select {filter.name}</option>
													{filter.filteroptions.map((ele, index2) => {
														const quantity = productFilterQuantity[","+ele.id+","]
														const selected =
															filter.id in selectedProperties &&
															selectedProperties[filter.id] == ele.id
																? true
																: false;
														return (
															<option
																selected={selected}
																key={index2}
																value={ele.id}
															>
																{ele.name} {quantity === "0" || quantity === 0 ? "- Out Of Stock":""}
															</option>
														);
													})}
												</select>
												
											</div>
										);
									})}
									{/* QUANTITY */}
									<label
										className="text-dark"
										style={{
											textAlign: "left",
											marginTop: "15px",
											fontWeight: "bold",
										}}
									>
										Quantity:
									</label>
									<select onChange={(event)=>setSelectedQuantity(parseInt(event.target.value))} className="SelectSize">
										{
											[1,2,3,4,5,6,7,8,9,10].map((ele, index)=><option key={index}>{ele}</option>)														
										}
									</select>

								<div className="bagheart">
									<motion.button
										style={{ color: "#fff" }}
										onClick={addToCart}
										animate={{ scale: scale ? 1.03 : 1 }}
										onMouseOver={() => {
											setScale(!scale);
										}}
										className="bagProduct"
									>
										<BsBag size={25} />
										<motion.span>Add to bag</motion.span>
									</motion.button>

									<BsHeart
										onClick={() => saveItem(product, setSavedItems, aler)}
										size={24}
										className="heartProduct"
									/>
								</div>
							</section>
							<hr />

							<section className="preproduct__details--info">
								<h6 className="header__text">
									<BsTruck size={24} color="#100840" /> Free Delivery.
								</h6>

								<Link
									to="/terms"
									className="about__text"
									style={{ color: "grey", fontSize: "12px" }}
								>
									View our Delivery & Returns Policy &nbsp; <BsFolder />
								</Link>
							</section>
							<hr />

							<div className="container-fluid" key={index}>
								<div className="accordion" id="accordionExample">
									{product.size && product.size.length > 0 && (
										<div className="accordion-item">
											<h2 className="accordion-header" id="headingOne">
												<button
													className="accordion-button"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target="#collapseOne"
													aria-expanded={
														isAccordionOpen.item1 ? "true" : "false"
													}
													aria-controls="collapseOne"
													onClick={() => toggleAccordion("item1")}
													style={{
														borderRadius: "0px!important",
														backgroundColor: "transparent",
														color: "black",
													}}
												>
													{isAccordionOpen.item1 ? (
														<FontAwesomeIcon icon={faMinus} color="black" />
													) : (
														<FontAwesomeIcon icon={faPlus} color="black" />
													)}
													Product Details
												</button>
											</h2>
											<div
												id="collapseOne"
												className={`accordion-collapse collapse ${
													isAccordionOpen.item1 ? "show" : ""
												}`}
												aria-labelledby="headingOne"
												data-bs-parent="#accordionExample"
											>
												<div className="accordion-body">
													{RawHtml(product.details)}
												</div>
											</div>
										</div>
									)}
									{product.size && product.size.length > 0 && (
										<div className="accordion-item">
											<h2 className="accordion-header" id="headingTwo">
												<button
													className="accordion-button"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target="#collapseTwo"
													aria-expanded={
														isAccordionOpen.item2 ? "true" : "false"
													}
													aria-controls="collapseTwo"
													onClick={() => toggleAccordion("item2")}
													style={{
														borderRadius: "0px!important",
														backgroundColor: "transparent",
														color: "black",
													}}
												>
													{isAccordionOpen.item2 ? (
														<FontAwesomeIcon icon={faMinus} color="black" />
													) : (
														<FontAwesomeIcon icon={faPlus} color="black" />
													)}
													Size & Fit
												</button>
											</h2>
											<div
												id="collapseTwo"
												className={`accordion-collapse collapse ${
													isAccordionOpen.item2 ? "show" : ""
												}`}
												aria-labelledby="headingTwo"
												data-bs-parent="#accordionExample"
											>
												<div className="accordion-body">
													{RawHtml(product.size)}
												</div>
											</div>
										</div>
									)}
									{product.lookafterme && product.lookafterme.length > 0 && (
										<div className="accordion-item">
											<h2 className="accordion-header" id="headingThree">
												<button
													className="accordion-button"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target="#collapseThree"
													aria-expanded={
														isAccordionOpen.item3 ? "true" : "false"
													}
													aria-controls="collapseThree"
													onClick={() => toggleAccordion("item3")}
													style={{
														borderRadius: "0px!important",
														backgroundColor: "transparent",
														color: "black",
													}}
												>
													{isAccordionOpen.item3 ? (
														<FontAwesomeIcon icon={faMinus} color="black" />
													) : (
														<FontAwesomeIcon icon={faPlus} color="black" />
													)}
													Care instructions
												</button>
											</h2>
											<div
												id="collapseThree"
												className={`accordion-collapse collapse ${
													isAccordionOpen.item3 ? "show" : ""
												}`}
												aria-labelledby="headingThree"
												data-bs-parent="#accordionExample"
											>
												<div className="accordion-body">
													{RawHtml(product.lookafterme)}
												</div>
											</div>
										</div>
									)}

									{product.aboutme && product.aboutme.length > 0 && (
										<div className="accordion-item">
											<h2 className="accordion-header" id="headingFour">
												<button
													className="accordion-button"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target="#collapseFour"
													aria-expanded={
														isAccordionOpen.item4 ? "true" : "false"
													}
													aria-controls="collapseFour"
													onClick={() => toggleAccordion("item4")}
													style={{
														borderRadius: "0px!important",
														backgroundColor: "transparent",
														color: "black",
													}}
												>
													{isAccordionOpen.item4 ? (
														<FontAwesomeIcon icon={faMinus} color="black" />
													) : (
														<FontAwesomeIcon icon={faPlus} color="black" />
													)}
													About Me
												</button>
											</h2>
											<div
												id="collapseFour"
												className={`accordion-collapse collapse ${
													isAccordionOpen.item4 ? "show" : ""
												}`}
												aria-labelledby="headingFour"
												data-bs-parent="#accordionExample"
											>
												<div className="accordion-body">
													{RawHtml(product.aboutme)}
												</div>
											</div>
										</div>
									)}
								</div>
							</div>

							{/* end of accordion */}
						</motion.section>
					</section>
				)}
				{/* Related Products */}
				<hr />
				<section className="reproducts container">
					<h5 style={{ color: "black", fontWeight: "900" }}>
						YOU MIGHT ALSO LIKE
					</h5>
					<RelatedProducts productId={product?.id} setLoad={setLoad} />
				</section>
			</div>
			{/* <ReviewPage /> */}
			<Socials />
			<Footer />
		</div>
	);
}
