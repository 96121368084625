import { useState, useEffect } from "react";
import { useLocation, useHistory } from 'react-router-dom'; 


import "../../styles/auth.css";

import SocialMedia from "../../components/auth/SocialMedia";
import AuthLocation from "../../components/auth/AuthLocation";
import { post } from "../../config/ajaxRequest";
import aler from "../../utils/aler";
import Preloader from "../../config/Preloader";
import { validateEmail, validatePassword } from "../../utils/validation";

function ResetPassword() {
	const [loading, setLoading] = useState(false)
	const [showEmail, setShowEmail] = useState(true)
	const [showToken, setShowToken] = useState(true)
	const [Data, setData] = useState({
		email:"",
		token:"",
		password: "",
		confirmPassword: "",
	});

	const location = useLocation();
	const history = useHistory();

	useEffect(() => {	
		(()=>{	
			if(Data.email.length > 0 || Data.token.length > 0) return
			const searchParams = new URLSearchParams(location.search);
			const emailParameter = searchParams.get('email');
			const tokenParameter = searchParams.get('token');
			
			setData({...Data, email:emailParameter ?? "", token:tokenParameter ?? ""})
			if(emailParameter)setShowEmail(false) 
			if(tokenParameter)setShowToken(false) 
		})()
	}, [location]); 
 


	const onChange = (event) => {
		setData({...Data, [event.target.name]: event.target.value});
	};
 
	async function handleSubmit(event) {
		event.preventDefault(); 
		
		if(!validateEmail(Data.email, aler))return setShowEmail(true)  
		
		if(!Data.token || Data.token.length < 1){
			aler("Please Enter Your Token")
			return setShowEmail(true)
		}
		if(!validatePassword(Data.password, aler))return

		if(Data.password !== Data.confirmPassword)return aler("Password And Confirm Password Must Match")
		
		setLoading(true)
		try{
			const res = await post("setpassword", Data)
			if(res.indexOf("Success") > -1){
				aler("Your Password Reset Is Successful", "success") 
			}else{
				if(res.indexOf("Invalid Email") > -1){
					aler("Enter Your Email Address")
					return history.push("/forgetpassword");
				}
				aler(res) 
				setShowToken(true)
			}
		}catch(err){
			aler("An Error Occured. Please check your network")
		}
		setLoading(false)
	}

	return (
		<main>
			<h1>Reset Password</h1>

			<section className="auth-container">
				<AuthLocation tab={2} />

				{/* Sign In Form */}

				{loading ?
					<Preloader/>
					: 
					<form className="container" onSubmit={handleSubmit}>
						{/* Email */}
						{showEmail && <section className="form-group">
							<label htmlFor="id" className="form-group__label">
								Email:
							</label>
							<br />
							<input
								type="email"
								id="email"
								name="email"
								value={Data.email}
								onChange={onChange}
							/>
						</section>}
						
						{showToken && <section className="form-group">
							<label htmlFor="id" className="form-group__label">
								Token:
							</label>
							<br />
							<input
								type="text"
								id="token"
								name="token"
								value={Data.token}
								onChange={onChange}
							/>
						</section>}
						
						<section className="form-group">
							<label htmlFor="id" className="form-group__label">
								Password:
							</label>
							<br />
							<input
								type="password"
								id="password"
								name="password"
								value={Data.password}
								onChange={onChange}
							/>
						</section>

						{/* Confirm Password */}
						<section className="form-group">
							<label htmlFor="id" className="form-group__label">
								Confirm Password:
							</label>
							<br />
							<input
								type="password"
								id="confirmPassword"
								name="confirmPassword"
								value={Data.confiemailrmPassword}
								onChange={onChange}
							/>
						</section> 
						<button className="joinButton active" type="submit">SIGN IN</button> 
					</form>
				}
				<h1 className="social__header">OR SIGN IN WITH...</h1>
				<SocialMedia />
			</section>
		</main>
	);
}

export default ResetPassword;
