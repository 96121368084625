import React, { useState } from "react";
import "../../asset/css/help.css";
import { BiMessage, BiSmile } from "react-icons/bi";
import Socials from "../women/Socials";
import Footer from "../Footer";
import Banner from "../women/Banner";
import Assistant from "../Assistant";
export default function InTouch() {
	const [activeIndex, setActiveIndex] = useState(null);

	// faq array

	function toggleIndex(id) {
		if (activeIndex === id) {
			setActiveIndex(null);
		} else {
			setActiveIndex(id);
		}
	}

	const DeliveryReturnFAQs = [
		{
			id: 1,
			question: "What is your standard delivery time?",
			answer:
				"Our standard delivery time is typically 14 business days. Please note that delivery times may vary depending on your location and other factors. You can track your order using the provided tracking number in your confirmation email.",
		},

		{
			id: 2,
			question: "Can I expedite my delivery?",
			answer:
				"Yes, we offer expedited shipping options for an additional fee. During the checkout process, you will have the opportunity to choose your preferred shipping method, including expedited options if available in your region.",
		},

		{
			id: 3,
			question: "What is your return policy?",
			answer:
				"Don’t worry, as long as an item is still in its original condition. You will have 28 days from the date your order is delivered to you or made available for collection, to return your item(s). As long as an item is still in its original condition, we accept returns free of charge up to 14 days after you receive them but after this period, you will be charged $4. Any returns that are sent back between 15-28 days will be subject to a charge.",
		},

		{
			id: 4,
			question: "How do I return an item?",
			answer:
				"To return an item, please contact our customer service team with your order number and details about the items you would like to return. They will provide you with instructions on how to proceed. Please note that return shipping costs may apply.",
		},

		{
			id: 5,
			question: "Do you offer international shipping?",
			answer:
				"Yes, we do offer international shipping. International shipping rates and delivery times vary depending on the destination. During the checkout process, you can select your country to view the available shipping options and costs.",
		},

		{
			id: 6,
			question: "What should I do if my order is damaged during delivery?",
			answer:
				"If your order arrives damaged, please contact our customer service team immediately. We may require photos of the damaged items and packaging to assist with the claims process. We will work to replace or refund your order as quickly as possible.",
		},

		{
			id: 7,
			question: "Can I change my delivery address after placing an order?",
			answer:
				"Once an order is placed, we process it quickly to ensure timely delivery. Therefore, we cannot guarantee changes to the delivery address after the order has been confirmed. Please double-check your address before completing the purchase.",
		},

		{
			id: 8,
			question: "What happens if I'm not home during the delivery?",
			answer:
				"If you're not home during the delivery, the courier may leave a delivery notification with instructions on how to reschedule or pick up your package. Alternatively, they may attempt redelivery on the next business day. Please check the carrier's tracking information for details.",
		},

		{
			id: 9,
			question: "Are there any items that cannot be returned?",
			answer:
				"Certain items, such as personalized or customized products, may be non-returnable. Additionally, for hygiene reasons, some items like opened cosmetics or intimate apparel may not be eligible for return. Please review our detailed return policy for more information.",
		},

		{
			id: 10,
			question: "What do I do if I receive the wrong item?",
			answer:
				"If you receive the wrong item, please contact our customer service team immediately. We will arrange for the correct item to be shipped to you, and we may provide a prepaid return label for the incorrect item. We apologize for any inconvenience.",
		},
	];

	return (
		<div>
			<Banner />
			{/* contact */}
			<div className="container touch">
				<div className="leftside">
					<div className="con">
						<span className="btnlink2">NEED TO GET IN TOUCH?</span>
						<h5>
							<strong>ORDER AND RETURN UPDATES</strong>
							Question about an order or return? Check out My Account for all
							the details.
						</h5>
					</div>
					<div className="virtualAsist">
						<div className="container mt-4">
							<div className="card">
								<div className="card-header">
									<h3>
										{" "}
										Hianga Assistant
										<BiSmile color="#100840" size={26} />
									</h3>
								</div>
								<div
									className="card-body"
									// style={{ height: "200px", overflowY: "scroll" }}
								>
									<div className="row">
										<Assistant />
									</div>
								</div>
								{/* <div
									className="card-footer"
									style={{ height: "90px", overflowY: "scroll" }}
								>
									<form>
										<div className="input-group">
											<input
												type="text"
												className="form-control"
												placeholder="Type your message here"
											/>
											<button className="btn btn-primary">Send</button>
										</div>
									</form>
								</div> */}
							</div>
						</div>
					</div>
				</div>
				<div className="rightsidebar">
					{/* delivery */}
					<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
						<div className=" d-flex justify-content-center align-items-center">
							<BiMessage size={26} /> &nbsp;&nbsp;&nbsp;&nbsp;
							<h4 style={{ fontWeight: "900", marginTop: "5px" }}>
								Return & Delivery
							</h4>
						</div>
						<hr />
						<div>
							{DeliveryReturnFAQs.map((item, index) => (
								<div className="container" key={item.id}>
									<div onClick={() => toggleIndex(item.id)}>
										<span style={{ cursor: "pointer", fontSize: "15px" }}>
											{" "}
											{item.question}
										</span>
									</div>
									{activeIndex === item.id && (
										<p className="answer" style={{ fontSize: "12px" }}>
											{item.answer}
										</p>
									)}
									<hr />
									<br />
									<br />
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
			<Socials />
			<Footer />
		</div>
	);
}
