import { useContext, useEffect, useState } from "react";
import { CartContext } from "../contexts/CartContext";
import "../styles/cart.css";
import { itemPriceDiscountCalculator } from "../utils/priceCaculator"; // Corrected typo
import { motion } from "framer-motion";
import { BsHeart, BsXCircle } from "react-icons/bs";

import { useParams, Link } from "react-router-dom";
import "../styles/product.css";
import aler from "../utils/aler";
import { WishContext } from "../contexts/WishContext";
import { getItem } from "../config/config";
import { RawHtml, saveItem } from "../utils/saveToCardAndWishList";
import { get } from "../config/ajaxRequest";
import { ProductContext } from "../contexts/ProductsContext";

export default function CartItem({ item, cal, setCal }) {
	const { cart, setCart } = useContext(CartContext); 
	const [quantity, setQuantity] = useState(item.quantity ?? 1); // State for quantity 
	const [quantities, setQuantities] = useState([]);
	const [properties, setProperties] = useState({});
	const [cartQuantities, setCartQuantities] = useState([]);
	const [productFilterQuantity, setProductFilterQuantity] = useState({}); 
	const { savedItems, setSavedItems } = useContext(WishContext);
	// Color text changer
 
	
	useEffect(() => {
		(async () => {  
			try {
				const p = await get("user/singleproduct/" + item.id); 
				if(p.product){
					const quantities = JSON.parse(p.product.quantity)
					let obj = {}
					quantities.forEach(res=>{
						obj[res.filteroptionids] = res.quantity
					})
					setProductFilterQuantity(obj)
					// console.log(obj, "filter obj")
				} 
			} catch (err) {}
 
		})();
	}, []);
 
	useEffect(() => {
		(async () => {
			cart.forEach((res) => {
				if (!(res.id == item.id && res.index == item.index)) return;
				if (res.selectedQuantities) setProperties(res.selectedQuantities);
			});
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if (cart.length < 1) return;
			let ids = "";
			cart.forEach((res) => {
				if (res.id) ids += res.id + ",";
				if (res.product_id) ids += res.product_id + ",";
			});

			if (ids.length > 0) ids.slice(0, -1);
			try {
				const response = await get("product/filter/quantity?productIds=" + ids);
				setCartQuantities(response);
			} catch (err) {}
		})();
	}, [cart]);

	useEffect(() => {
		(async () => {
			cartQuantities.map((res) => {
				if (res.productId != item.id) return;
				setQuantities(res.filter);
				let newProperties = { ...properties };
				res.filter.forEach((element) => {
					const condition = element.id in newProperties;
					if (!condition) newProperties[element.id] = null;
				});
				// setProperties(newProperties)
			});
		})();
	}, [cartQuantities]);

	const handleQuantities = (event, filterId) => {
		const value = event.target.value.length > 0 ? event.target.value : null;
		if(productFilterQuantity[","+value+","] === 0 || productFilterQuantity[","+value+","] === "0")return aler("Out of stock")
		let newProperty = { ...properties };
		newProperty[filterId] = value;
		setProperties(newProperty);

		const ITEM_ID = item.id;
		const ITEM_INDEX = item.index;
		const newItems = cart.map((item) => {
			if (item.id === ITEM_ID && item.index === ITEM_INDEX){
				return { ...item, selectedQuantities: newProperty };
			}
			return item;
		});

		setCart(newItems);
	};

	const removeItem = () => {
		const ITEM_ID = item.id;
		const ITEM_INDEX = item.index;

		setCart(
			cart.filter((cartItem) => { 
				return !(cartItem.id === ITEM_ID && cartItem.index === ITEM_INDEX);
			})
		);
	};

	// Function to handle quantity change
	const handleQuantityChange = (event) => {
		let value = 1;
		try {
			value = parseInt(event.target.value);
		} catch (err) {
			value = parseInt(event.target.value);
		}
		setQuantity(value);
		const ITEM_ID = item.id;
		const ITEM_INDEX = item.index;
		const newItems = cart.map((item) => { 
			if (item.id === ITEM_ID && item.index === ITEM_INDEX) {
				return { ...item, quantity: value };
			}

			return item;
		});

		setCart(newItems);
		setCal(cal + 1);
	};

	const newPrice = itemPriceDiscountCalculator(item) * quantity; // Calculate the new price

	return (
		<section className=" cart__item">
			<section className="container-fluid cart__item--details">
				<section className="row cartitem_in_item">
					<section className="cart__item--image">
						<img src={item.mainpic} alt={item.name} />
					</section>
					<div
						className="col-lg-6 col-md-12 col-sm-12 col-xs-12 cartDetails"
						style={{ fontVariant: "small-caps", color: "#100840" }}
					>
						<h5 style={{ wordSpacing: "4px" }}>
							Product: <small style={{ fontSize: "15px" }}>{item.title}</small>
						</h5>
						<h5
							style={{
								display: "flex",
								alignItems: "end",
								justifyContent: "center",
								width: "33%",
							}}
						>
							Color:
							<span
								className="color-text"
								style={{
									color: "black",
									fontWeight: "600",
									width: "100%",
								}}
							>
								{RawHtml(item.color)}
							</span>
						</h5>
						<span
							style={{
								fontVariant: "small-caps",
								color: "#100840",
								fontWeight: "900",
								padding: ".5rem 0",
								display: "flex",
								alignItems: "end",
								justifyContent: "space-between",
								width: "25%",
							}}
						>
							Price: <span>${parseFloat(newPrice).toFixed(2)}</span> {/* Display the new price */}
						</span>
						{item?.discount > 0 &&
								<section> 
									<div className="banner-item">
										<h5 className="big-text" style={{ color: "black" }}>
											{item?.discount}% Discount
										</h5>
									</div> 
								</section>
							}

						{/* Rest of your code */}
						<div>
							{quantities.map((filter, index) => {
								return (
									<div key={index}>
										<select
											onClick={(event) => {
												handleQuantities(event, filter.id);
											}}
											style={{ marginTop: "10px", width: "100%" }}
											className="SelectSize"
											name="size"
										>
											{filter.filteroptions.length < 1 && (
												<option value="">Select {filter.name}</option>
											)}
											{filter.filteroptions.map((ele, index2) => { 
												const quantity = productFilterQuantity[","+ele.id+","] 
												const selected =
													filter.id in properties &&
													properties[filter.id] == ele.id
														? true
														: false;
												return (
													<option
														selected={selected}
														key={index2}
														value={ele.id}
													>
														{ele.name} {quantity === "0" || quantity === 0 ? "- Out Of Stock":""}
													</option>
												);
											})}
										</select>
									</div>
								);
							})}
							{/* Add quantity input size_qty */}
							<div
								className="SelectSize1 qty size_qty"
								style={{ width: "100%" }}
							>
								<label htmlFor="quantity" style={{ display: "inline" }}>
									Quantity:
								</label>
								<input
									type="number"
									id="quantity"
									name="quantity"
									value={quantity}
									onChange={handleQuantityChange}
									min="1"
									style={{ backgroundColor: "white", width: "100%" }}
								/>
							</div>
						</div>
						<div
							className="bagheart"
							style={{
								margin: "1rem 0",
								backgroundColor: "#eee",
								padding: "5px 7px",
							}}
						>
							<BsHeart
								onClick={() => saveItem(item, setSavedItems, aler, setCart)}
								size={20}
								color="black"
								style={{ cursor: "pointer" }}
							/>
							<span
								onClick={() => saveItem(item, setSavedItems, aler, setCart)}
								style={{
									fontVariant: "small-caps",
									padding: "0 5px",
									cursor: "pointer",
								}}
							>
								Save For Later
							</span>
						</div>
					</div>
				</section>
				<button onClick={removeItem}>
					<BsXCircle size={24} style={{ margin: "2rem" }} color="grey" />
				</button>
			</section>
		</section>
	);
}
