import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { get } from "../../config/ajaxRequest";
import {useHistory} from "react-router-dom";
import aler from "../../utils/aler";

export default function Order({ order, orders, setOrders }) { 
	const [orderDate, setOrderDate] = useState(`${new Date(order.orderDate).getDate()} - ${new Date(order.orderDate).getMonth()} - ${new Date(order.orderDate).getFullYear()}`);
	const [show, setShow] = useState({remove:false, confirm:true})
	const history = useHistory()

	useEffect(()=>{
		setShow({...show, remove: order.status === "delivered" || order.status === "confirmed"  || order.status === "canceled"})
		setShow({...show, confirm: order.status !== "delivered" && order.status !== "confirmed" && order.status !== "canceled"})
	}, [])
	const removeOrder = async()=>{ 
		const newOrder = orders.filter(res=>res.cartId!==order.cartId)
		
		// console.log(orders, newOrder)
		setOrders(newOrder)

		const res = await get("hide/order?id="+order.cartId)
		// console.log(res)
		if(res.indexOf("Successfully") < 0)setOrders([...orders, order])
	}

	const confirmOrder = async()=>{  
		setShow({...show, confirm:false})
		const res = await get("confirm/order?id="+order.cartId)  
		if(res.indexOf("Successfully") < 0)setShow({...show, confirm:true})
	}

	return (
		<section className="order__item">
			{Object.keys(order).length > 0 && (
				
				<div>
					<section className="order__item--header">
						<h1>Order Date: {orderDate}</h1>
						<h1>Product Sample In Cart</h1>
						<div>
							<button onClick={()=>history.push(`/my-account/orders/${order.cartId}`)} style={{cursor:"pointer", padding:"10px", border:"1px solid grey", borderRadius:10}}>View Details</button>
							{ show.remove &&
								<button onClick={removeOrder} style={{cursor:"pointer", margin:"0 10px", padding:"10px", border:"1px solid grey", borderRadius:10, backgroundColor:"maroon", color:"white" }}>Remove</button>
							}
							{ show.confirm &&
								<button onClick={confirmOrder} style={{cursor:"pointer", margin:"0 10px", padding:"10px", border:"1px solid grey", borderRadius:10, backgroundColor:"green", color:"white" }}>Confirm Delivery</button>
							} 
						</div>
					</section>
					<img
						src={order.mainpic}
						style={{ minWidth: "20%", borderRadius: "100px" }}
						alt={`${order.title} Image`}
					/>
					<div>
						<span> {order.title} </span>
					</div>
				</div>
			)}
			<hr />
		</section>
	);
}
