import React, { useRef, useEffect } from "react";
import "../../asset/css/backgoundvideo.css";
import Ads1 from "../Menu/ThirdMenu/Ads/Ads1";
function BackgroundVideo({ src, autoPlay, muted, loop, children }) {
	const videoRef = useRef(null);

	useEffect(() => {
		const video = videoRef.current;

		if (video) {
			video.src = src;
			video.autoplay = autoPlay;
			video.muted = muted;
			video.loop = loop;
		}
	}, [src, autoPlay, muted, loop]);

	return (
		<div className="background-video">
			<video controls ref={videoRef} className="video-element"></video>
			{children}
			<Ads1 />
		</div>
	);
}

export default BackgroundVideo;
