import React, { useContext, useEffect, useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import "../../asset/css/paybtns.css";
import { calculateFee, getProductPrices } from "../../utils/priceCaculator";
import { useAddress } from "../../customHook/useAddress";
import { get, post } from "../../config/ajaxRequest";
import { CartContext } from "../../contexts/CartContext";
import aler from "../../utils/aler";
import Preloader from "../../config/Preloader";
import {useHistory} from "react-router-dom";
import { submitToServer } from "../../utils/checkoutFunctions";

const PayPalButton = ({exchangeRate, mercentprice, total, setChecking, userDetails, paypalKey}) => { 
	const { cart, setCart } = useContext(CartContext);
	const [paymentFee, setPaymentFee] = useState(0);
	 const history = useHistory();
	  
	
	 useEffect(() => { 
		console.log(paypalKey, "key....")
		let price = 0
		const totalPrice = total +  mercentprice 
		const func = calculateFee("paypal")
		if(func)price = parseFloat(func(totalPrice, exchangeRate))
		if(price)setPaymentFee(price); 

	}, [exchangeRate, total, mercentprice]);
	
	const initialOptions = {
		clientId: paypalKey,
		currency: "USD",
		intent: "capture",
	};

	const createOrder = async(data, actions) => { 
		const total = await getProductPrices(cart, setCart, true) 
		const payment = parseFloat(parseFloat(total) + parseFloat(mercentprice) + parseFloat(paymentFee)).toFixed(2) 
		return actions.order.create({
			purchase_units: [
				{
					amount: {
						value: payment,
					},
				},
			],
		});
	};

	const onApprove = async(data, actions) => { 
		return actions.order.capture().then(async(details) => {
			// Log success message with necessary fields
			// console.log("Transaction completed successfully!");
			// console.log("Order ID: ", details.id);
			// console.log("Payer ID: ", details.payer.payer_id);
			// console.log("Payer Name: ", details.payer.name.given_name);
			try{
				setChecking(true) 
				const shouldContinue = await submitToServer("paypal", details.id, cart, setCart, userDetails, total, mercentprice, paymentFee)
				if(shouldContinue){
					get("execute/job")
					setTimeout(()=>{history.push("/cart")}, 1000)
				}
			}catch(err){console.log(err)}
			setChecking(false)
		});
	}; 

	const onError = (err) => {
		// Log error message
		console.error("Error during transaction:", err);
	};

	return (
		<>
			<input type="text" readOnly style={{ border:"none", position:"relative"}} autoFocus /> 
			<div className="myPaypal" style={{paddingBottom:"50vh"}}>
				<PayPalScriptProvider options={initialOptions}>
					<PayPalButtons
						createOrder={createOrder}
						onApprove={onApprove}
						onError={onError}
					/>
				</PayPalScriptProvider>
			</div>
		</>
	);
};

export default PayPalButton;
