import React from "react";
import "../asset/css/maintainance.css";
import Images from "../config/Images";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { motion } from "framer-motion";
import { Icon } from "@iconify/react";
function Maintainance() {
	return (
		<div className="mainbody">
			<article>
				<h1>HIANGA</h1>
				<br />
				<h2>We will be back soon!</h2>
				<br />

				<div>
					<p>
						Sorry for the inconvenience.
						<br /> We are currently undergoing scheduled maintenance.
						<br /> We appreciate your understanding.
						<br />
						If you have questions, don't hesitate to get in touch with us at
						<br />
						<a href="mailto:infos@hianga.com">infos@hianga.com</a>
						<br /> Or you can always follow us on Social Media Platforms for
						updates.
						<br /> We’ll be back shortly!
					</p>
					<div className="icons">
						<a
							href="https://www.facebook.com/ixxiindustry?mibextid=ZbWKwL"
							target="_blank"
							className="fa fa-facebook fas"
						></a>

						<a
							href="https://instagram.com/lahianga?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D"
							target="_blank"
							className="fa fa-instagram fas"
						></a>
						<a
							href="https://www.tiktok.com/@hiangaa?_t=8giliWojfXi&_r=1"
							target="_blank"
							className="fa ticktok fas"
						>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
								<path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" />
							</svg>
						</a>
						<a
							href="https://x.com/ixxiindustry?t=0n6JMqftNqu9fqqeVe0Cng&s=09"
							target="_blank"
						>
							<Icon icon="ri:twitter-x-line" className="check-x" />
						</a>
					</div>
					<p>The hianga Team</p>
				</div>
			</article>
		</div>
	);
}
export default Maintainance;
