import React, { useEffect, useState } from "react";
import { getItem } from "../config/config";
import "../asset/css/alert.css";
import { motion } from "framer-motion";

const PopupMessage = ({ hideMessage, close }) => {
	const [message, setMessage] = useState("");
	const [color, setColor] = useState("red");
	const [title, setTitle] = useState("Error");

	useEffect(() => {
		(async () => {
			//console.log(hideMessage, "hideMessage");
			if (hideMessage !== false) return;
			try {
				const msg = await getItem("message");
				setMessage(msg.message);
				//console.log(msg.type, "msg.type");
				if (msg.type === "success") {
					setColor("green");
					setTitle("Success");
				}
				if (msg.type === "success")
					setTimeout(() => {
						close(true);
					}, 3500);
			} catch (err) {
				//console.log(err, "err");
			}
			localStorage.removeItem("message");
		})();
	}, [hideMessage]);

	return (
		<>
			{message.length < 1 ? (
				<motion.div></motion.div>
			) : (
				<motion.div
					initial={{ scale: 0 }}
					animate={{ scale: 1 }}
					className="prompt-dialog-overlay"
				>
					<div className="prompt-dialog">
						<div>
							<h3
								onClick={() => close(true)}
								style={{
									color: color,
									borderBottom: "1px solid " + color,
									borderRadius: "50%",
									display: "inline-block",
									padding: 10,
									marginBottom: 20,
								}}
							>
								{title}
							</h3>
						</div>
						<b className="alertSmall" style={{ color: color }}>
							{message}
						</b>
						<div className="button-container">
							<button
								style={{ backgroundColor: color, color: "white" }}
								onClick={() => close(true)}
							>
								Ok
							</button>
						</div>
					</div>
				</motion.div>
			)}
		</>
	);
};
export default PopupMessage;
