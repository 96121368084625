import React, { useContext, useEffect, useState } from "react";
import {PaystackButton} from 'react-paystack';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import "../../asset/css/paybtns.css";
import { calculateFee, getProductPrices } from "../../utils/priceCaculator";
import { useAddress } from "../../customHook/useAddress";
import { get, post } from "../../config/ajaxRequest";
import { CartContext } from "../../contexts/CartContext";
import aler from "../../utils/aler";
import Preloader from "../../config/Preloader";
import {useHistory} from "react-router-dom";
import { submitToServer } from "../../utils/checkoutFunctions";

function MyPaystackButton({exchangeRate, mercentprice, total, setChecking, userDetails={}, paystackKey}) {
	const { cart, setCart } = useContext(CartContext);
	const history = useHistory(); 
	const [payment, setPayment] = useState(0);
	const [paymentFee, setPaymentFee] = useState(0);
  const [user, setUser] = useState({});
	const { address } = useAddress(); 
  
  useEffect(() => {
		(() => {  
			if (address.length > 1 && Object.keys(user).length < 2) {  
				let billingObj = {};
				address.forEach((res) => { 
					if (res.type == "billing") billingObj = { ...res };
				});
        
				if (Object.keys(billingObj).length > 0) setUser(billingObj);
			} 
		})();
	}, [address]);

  useEffect(() => {
    let pay = parseInt((parseFloat(parseFloat(total) + parseFloat(mercentprice) + parseFloat(paymentFee)))* exchangeRate * 100) 
    setPayment(pay )
  }, [paymentFee, total, mercentprice]);

  useEffect(() => { 
		let price = 0
		const totalPrice = total +  mercentprice 
		const func = calculateFee("paystack")
		if(func)price = parseFloat(func(totalPrice, exchangeRate))
		if(price)setPaymentFee(price); 
    
	}, [exchangeRate, total, mercentprice]);
	

  const onSuccessSubmit = async(res) => {
    try{ 
      setChecking(true)
      const shouldContinue = await submitToServer("paystack", res?.reference, cart, setCart, userDetails, total, mercentprice, paymentFee)
      if(shouldContinue){
        get("execute/job")
        aler("Thanks for doing business with us! Come back soon!!", "success")
        setTimeout(()=>{history.push("/cart")}, 1000)
      }
    }catch(err){aler("An error Occured While Verifying Payment. Please Contact the admin")}
    setChecking(false)
  };


  const componentProps = {
    email:user.email, 
    amount:payment,
    metadata: { },
    publicKey:paystackKey,//"pk_live_ea19e557b67979de47377a50b0ed4d0efcda27e4",
    text: "Pay Now Using Paystack",
    onSuccess: (e) =>{onSuccessSubmit(e)  },
    onClose: () => aler("Paystack Interface Closed"),
  }

  return (
    <PaystackButton className="paystackBtn" {...componentProps} />
  );
}

export default MyPaystackButton
