import {useState, useEffect} from 'react'
import { setItem, getItem } from '../config/config'
import { get } from '../config/ajaxRequest'

export const useCountries = ()=>{ 
    const [countries, setCountries] = useState([])

    useEffect(() => { 
            (async()=>{ 
                try{
                    const res = await get("get/countries") 
                    // console.log(res, "from from server")
                    if(Array.isArray(res) && res.length>0){
                        setCountries(res) 
                        setItem("countries", res)
                    }
                }catch(err){console.log(err)} 

            })()
        
    }, []);    

    
    return {countries, setCountries}
}

