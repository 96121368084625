import React, { useState, useEffect } from "react";
import { BiCheck } from "react-icons/bi";
import { Link, useHistory } from "react-router-dom";
import "../../asset/css/product.css";
import { getItem } from "../../config/config";

import Banner from "../men/Banner.js";

export default function ProductFilters({
	selected,
	setSelected,
	filterOptionCount,
	activeFilterOption,
}) {
	const [filter, setFilter] = useState([]);
	const [parameters, setParameters] = useState("?");
	const [numberSelected, setNumberSelected] = useState({});
	const history = useHistory();

	useEffect(() => {
		(async () => {
			const fil = (await getItem("filter")) ?? [];
			if (fil.length > 0) {
				setFilter(fil);
				let obj = {};
				fil.forEach((res) => {
					obj[res.id] = 0;
				});
				setNumberSelected(obj);
			}
		})();
	}, []);

	useEffect(() => {
		(() => {
			const href = window.location.href;
			if (href.indexOf("?") < 0) return;
			const parameters = href.split("?")[1];
			setParameters(parameters);
			let newSelected = ",";
			const stringArr = parameters.split("oid=");
			//console.log(stringArr);
			stringArr.forEach((res) => {
				const string = res.replace("&", "");
				if (string.length < 1) return;
				newSelected += string + ",";
			});
			setSelected(newSelected);
		})();
	}, []);

	const updateNumberSelected = (id, type) => {
		let newNumberberSelected = 0;
		if (type == "add") newNumberberSelected = numberSelected[id] + 1;
		if (type == "remove") newNumberberSelected = numberSelected[id] - 1;
		if (newNumberberSelected != numberSelected[id])
			setNumberSelected({
				...numberSelected,
				[id]: newNumberberSelected > -1 ? newNumberberSelected : 0,
			});
		//console.log(id, numberSelected, "numberSelected...", newNumberberSelected);
	};

	const isSelectedAlready = (id) => {
		return selected.indexOf("," + id + ",") > -1;
	};

	const filterOptionClickAction = (id, filterId) => {
		let string = "";
		let newParameters = parameters;
		const path = window.location.pathname;

		if (isSelectedAlready(id)) {
			string = selected;
			string = string.replace("," + id + ",", ",");
			if (newParameters.length == "?oid=" + id + "&") newParameters = "";
			newParameters = newParameters.replace("oid=" + id + "&", "");
			updateNumberSelected(filterId, "remove");
		} else {
			if (selected.length < 1) string = ",";
			string += selected + id + ",";
			newParameters = newParameters + "oid=" + id + "&";
			updateNumberSelected(filterId, "add");
		}
		setSelected(string);
		setParameters(newParameters);
		// //console.log(string, path, newParameters, "new Symbol o in ProductFilters")
		history.push(path + newParameters);
	};

	return (
		<>
			{/* <Banner /> */}

			<nav className="myProdNav">
				{filter.length > 0 &&
					filter.map((res, index) => {
						if (
							numberSelected[res.id] < 1 &&
							(activeFilterOption[res.id] == null ||
								activeFilterOption[res.id] != true)
						)
							return <span key={index}></span>;
						return (
							<div class="filter-dropdown" key={index}>
								<button class="filter-dropdown__button">{res.name}</button>
								<ul class="filter-dropdown__list">
									<div>
										<small className="select" id={res.id}>
											{numberSelected[res.id]} selected
										</small>

										<small className="all">
											{/* <BiCheck size={20}/> All */}
										</small>
									</div>
									{res.filteroptions.map((res2, index) => {
										if (!(res2.id in filterOptionCount))
											return <span key={index}></span>;
										const clName = isSelectedAlready(res2.id) ? " active" : "";
										return (
											<li key={index}>
												<span
													className={"Link" + clName}
													onClick={() =>
														filterOptionClickAction(res2.id, res.id)
													}
												>
													{res2.name} ({filterOptionCount[res2.id] ?? 0})
												</span>
											</li>
										);
									})}
								</ul>
							</div>
						);
					})}
			</nav>
		</>
	);
}

// /*
//         <div class="filter-dropdown">
//           <button class="filter-dropdown__button">SORT</button>
//           <ul class="filter-dropdown__list">
//             <li>
//               <Link className="Link" to="/">
//                 Recommmended
//               </Link>
//             </li>
//             <li>
//               <Link className="Link" to="/">
//                 Whats New
//               </Link>
//             </li>
//             <li>
//               <Link className="Link" to="/">
//                 Price High To Low
//               </Link>
//             </li>
//             <li>
//               <Link className="Link" to="/">
//                 Price Low To High
//               </Link>
//             </li>
//             <li>
//               <Link className="Link" to="/">
//                 Link 1
//               </Link>
//             </li>
//           </ul>
//         </div>

//         <div class="filter-dropdown">
//           <button class="filter-dropdown__button">SORT</button>
//           <ul class="filter-dropdown__list">
//             <li>
//               <Link className="Link" to="/">
//                 Recommmended
//               </Link>
//             </li>
//             <li>
//               <Link className="Link" to="/">
//                 Whats New
//               </Link>
//             </li>
//             <li>
//               <Link className="Link" to="/">
//                 Price High To Low
//               </Link>
//             </li>
//             <li>
//               <Link className="Link" to="/">
//                 Price Low To High
//               </Link>
//             </li>
//             <li>
//               <Link className="Link" to="/">
//                 Link 1
//               </Link>
//             </li>
//           </ul>
//         </div>

// */
