import React, { useState } from "react";
import "../../asset/css/readmore.css";
const ReadMore = ({ text, maxLength, title }) => {
	const [isCollapsed, setIsCollapsed] = useState(true);

	const toggleReadMore = () => {
		setIsCollapsed(!isCollapsed);
	};

	const displayText = isCollapsed ? text.slice(0, maxLength) + "..." : text;

	return (
		<div className="readmoreProduct">
			<div>
				<h1>{title}</h1>
				<p>{displayText}</p>
				{text.length > maxLength && (
					<span className="read-more-toggle" onClick={toggleReadMore}>
						{isCollapsed ? (
							<iconify-icon
								icon="iconamoon:arrow-down-2-bold"
								width="30"
							></iconify-icon>
						) : (
							<iconify-icon
								icon="iconamoon:arrow-up-2-bold"
								width="30"
							></iconify-icon>
						)}
					</span>
				)}
			</div>
		</div>
	);
};

export default ReadMore;
