import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { TfiAngleLeft, TfiAngleRight } from "react-icons/tfi";
import aler from "../../utils/aler";

import "../../styles/checkout.css";
import { get, post } from "../../config/ajaxRequest";
import { useAddress } from "../../customHook/useAddress";
import Preloader from "../../config/Preloader";
import { setItem } from "../../config/config";
import InformationSummary from "./InformationSummary";
import CheckOutPayment from "../../pages/checkout/CheckOutPayment";
import MerchantSummary from "./MerchantSummary";
import Address from "./Address";
import CheckOutButton from "./CheckoutButtons"; 
import PaymentGate from "../paymentGateways/PaymentGate";
import { calculateFee } from "../../utils/priceCaculator";

export default function Information() {
	const [step, setStep] = useState(1);
	const [loading, setLoading] = useState(false);
	const [mercentprices, setMerchantPrices] = useState({})
	const [mercentprice, setMerchantPrice] = useState(10)
	const [exchangeRate, setExchangeRate] = useState(1200)
	const [paystackKey, setPaystackKey] = useState(null)
	const [paypalKey, setPaypalKey] = useState(null)
    
	useEffect(() => {
    (async ()=>{ 
        try{   
          const psKey = await get("paystack/public/key")
          setPaystackKey(psKey)

          const paKey = await get("paypal/clientid/key")
          console.log(paKey, "paKey...")
          setPaypalKey(paKey)
        }catch(err){  
        } 
    })()
  }, []);
 
	useEffect(() => {
		(async()=>{			
      try{
        const res = await get("naira/usd") 
        res ? setExchangeRate(res): (()=>{})()
      }catch(err){}
			
		})()
	}, []);

	
	const [user, setUser] = useState({
		fname: "",
		lname: "",
		company: "",
		country: "",
		street: "",
		city: "",
		state: "",
		phone: "",
		email: "",
	});
	const [shipAddress, setShipAddress] = useState({
		fname: "",
		lname: "",
		company: "",
		country: "",
		street: "",
		city: "",
		state: "",
		phone: "",
		email: "",
	});

	//CHECKOUTPAYMENT
	const [selectedValues, setSelectedValues] = useState({
		merchant: null,
		deliveryMethod: null,
		method: "",
		payMethod: "",
	});

	const history = useHistory();

	useEffect(() => {
		(() => {
			const path = window.location.pathname;
			if ("/checkout/payment" === path && step !== 2) {
				return setStep(2);
			}
			if ("/checkout/summary" === path && step !== 3) {
				return setStep(3);
			}
			if (step !== 1) return setStep(1);
		})();
	}, [history]);

	useEffect(() => {
		setUser({
			fname: "",
			lname: "",
			company: "",
			country: "",
			street: "",
			city: "",
			state: "",
			phone: "",
			email: "",
		});

		setShipAddress({
			fname: "",
			lname: "",
			company: "",
			country: "",
			street: "",
			city: "",
			state: "",
			phone: "",
			email: "",
		});
	}, []);

	useEffect(() => {
		(async()=>{
			if(step < 2)return
			try{
				const res = await post("get/rates", shipAddress)  
				
				if(typeof res === 'string'){
					aler(" Could find your address. Please choose another location")
					return setStep(1)
				}
				if(res && Object.keys(res).length > 0){ 
					setMerchantPrices(res)
					if(selectedValues?.merchant && Object.keys(selectedValues.merchant).length>0){						
						if(typeof res === 'string' || !selectedValues.merchant.name.toLowerCase() in res){
							aler(selectedValues.merchant.name.toUpperCase()+" could find your address. Please choose another location")
							return setStep(1)
						}
						const data = {price:res[selectedValues.merchant.name.toLowerCase()]} 
						setMerchantPrice(data.price)
						const event = new CustomEvent("delivery", {
							bubbles: true,
							detail: { data: data },
						});	
						document.dispatchEvent(event); 
					} 
									
				}
			}catch(err){ 
				// console.log(err)
			}
			
		})()
	}, [step, selectedValues.merchant, shipAddress]);
 

	useEffect(() => {
		(async()=>{
			if(!selectedValues.payMethod || selectedValues.payMethod.length < 1)return
			try{
				// console.log(selectedValues.payMethod, "...") 
				const event = new CustomEvent("paymentfee", {
					bubbles: true,
					detail: { data: selectedValues.payMethod },
				});	
				document.dispatchEvent(event); 
			
			}catch(err){ 
				// console.log(err)
			}
			
		})()
	}, [mercentprice, selectedValues.payMethod]);
 
	const comingSoon = () => {
		aler("Coming Soon", "success");
		setStep(3);
	};

	const checkUsers = () => {
		if (shipAddress.fname.length > 0 && user.fname.length > 0) return 1;
		//aler("Fill in Address Information");
		setStep(1);
	};

	const checkMerchantAndPayment = () => {
		let msg = "";
		if (
			selectedValues?.merchant?.name?.length > 0 &&
			selectedValues.payMethod.length > 0
		)
			return 1;
		if (
			!selectedValues?.merchant?.name ||
			selectedValues?.merchant?.name?.length < 2
		)
			msg += "Select Delivery Method ";

		if (selectedValues.payMethod.length < 1) {
			if (msg.length > 1) msg += "and ";
			msg += "Select Payment Method";
		}
		aler(msg);
		setStep(2);
	};

	return (
		<>
			<div className="checkout__address--content">
				{step == 1 && (
					<>
						<Address
							setUser={setUser}
							setStep={setStep}
							user={user}
							shipAddress={shipAddress}
							setShipAddress={setShipAddress}
						/>
						{/* <CheckOutButton prevText={"CART"} prevLink={"/cart"} nextText={"PAYMENT"} setStep={setStep} newStep={2}/> */}
					</>
				)}

				{step == 2 && checkUsers() && (
					<>
						<CheckOutPayment
							setLoading={setLoading}
							selectedValues={selectedValues}
							setSelectedValues={setSelectedValues}
							mercentprices={mercentprices}
							setMerchantPrice={setMerchantPrice}
						/>
						<CheckOutButton
							prevText={"Payment Methods"}
							oldStep={1}
							nextText={"Summary"}
							setStep={setStep}
							newStep={3}
						/>
					</>
				)}

				{step == 3 && checkMerchantAndPayment() && (
					<>
						<div className="orderSummary">							
							<h1><input type="text" readOnly style={{paddingLeft:"30%", border:"none", fontSize:"2rem"}} value={"ORDER SUMMARY"} autoFocus/></h1>
							<div>
								<p className="selected-method">
									Selected Delivery Method:
									<h2 className="selectedMethod">
										{selectedValues?.merchant?.name ?? ""}
									</h2>
								</p>
							</div>
							<div>
								<p className="selected-method">
									Selected Payment Method:
									<h2 className="selectedMethod">{selectedValues.payMethod}</h2>
								</p>
							</div>
							<hr />
							<InformationSummary
								user={user}
								shipAddress={shipAddress}
								setLoading={setLoading}
							/>
						</div>
						<CheckOutButton
							prevText={"Shipment"}
							oldStep={2}
							nextText={"Payment"}
							setStep={setStep}
							newStep={4}
						/>
					</>
				)}
				{/* {step == 4 && comingSoon()} */}

				{step == 4 &&
					<PaymentGate paystackKey={paystackKey} paypalKey={paypalKey} exchangeRate={exchangeRate} paymentMethod={selectedValues.payMethod}  mercentprice={mercentprice}/>
				}
				{step == 4 && !["paypal", "paystack"].includes(selectedValues.payMethod) && comingSoon()}
			</div>
		</>
	);
}
