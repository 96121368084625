import React, { useState, useContext } from "react";
import { CartContext } from "../contexts/CartContext";
import { Link } from "react-router-dom";
import { BsCart } from "react-icons/bs";

import "../styles/cart.css";

import PageBanner from "../components/common/PageBanner";
import CartItem from "../components/CartItem";
import Footer from "../components/Footer";
import {
	itemPriceDiscountCalculator,
	priceCalculator,
	coupleCalculator
} from "../utils/priceCaculator"; // Corrected typo
import { BiErrorCircle } from "react-icons/bi";
import Images from "../config/Images";
import Banner from "../components/women/Banner";
import Socials from "../components/women/Socials";

export default function Cart() {
	const { cart } = useContext(CartContext);
	const [cal, setCal] = useState(0)
	const cart_length = cart.length;

	const totalOrderPrice = () => {
		const totalPrice = cart.reduce((total, item) => {
		  return total + parseFloat(priceCalculator(item), 2);
		}, 0.0);
	  
		return totalPrice.toFixed(2); // Apply .toFixed(2) to the final result
	}
	 
	const plusDelieveryPrice = (price) => {
		const totalPrice = parseFloat(price, 2) * parseFloat(totalOrderPrice(), 2)
	  
		return totalPrice.toFixed(2); // Apply .toFixed(2) to the final result
	}
	  
	return (
		<div className="cartMain">
			<PageBanner pageName={"Cart"}>
				<a>Cart</a>
				<Link to="/wishlist">Wishlist</Link>
			</PageBanner>
			{cart_length < 1 ? (
				<NoCartItems />
			) : (
				<>
					<section className="container cart">
						<section className="cart__items">
							<section className="cart__items--header">
								<h6 style={{ fontVariant: "small-caps", color: "black" }}>
									My Bag[{cart_length}]
								</h6>
								<span>These are your cart items</span>
							</section>
							{cart.map((item,index) => {
								return <CartItem setCal={setCal} cal={cal}  key={index} item={item} />;
							})}
						</section>
						<section className="cart__checkout">
							<h2>Total Summary</h2>

							<section className="cart__checkout--details">
								<section className="cart__checkout--details-subtotal">
									<h3>$ {totalOrderPrice()}</h3>
									<span style={{ fontSize: "20px" }}>Subtotal</span>
								</section>

								<section className="cart__checkout--details-subtotal" style={{display:"none"}}>
									<Link to="/policy">
										<BiErrorCircle color="grey" size={24} />
									</Link>
									<span style={{ fontSize: "20px" }}>Delivery</span>
								</section>

								<p className="cart__checkout--details-info" style={{display:"none"}}>
									Standard Delivery ${plusDelieveryPrice(0.8 / 100)}
								</p>
							</section>

							<Link to="/checkout" className="checkoutbtn">
								<button className="checkoutbtn">
									Checkout ($ &nbsp;
									{ 
										parseFloat(totalOrderPrice()) //+ parseFloat(plusDelieveryPrice(0.8 / 100))
									}
									)
								</button>
							</Link>
							<div className="checkoutpayadds">
								<h3>WE ACCEPT:</h3>
								<div className="right checkoutpayaddsinner">
									<Link className="img" to="#">
										<img src={Images.visa} alt="trendsb1" />
									</Link>
									<Link className="img" to="#">
										<img src={Images.express} alt="trendsb2" />
									</Link>
									<Link className="img" to="#">
										<img src={Images.mastercard} alt="trendsb3" />
									</Link>
									<Link className="img" to="#">
										<img src={Images.paypal} alt="trendsb1" />
									</Link>
									<Link className="img" to="#">
										<img src={Images.electron} alt="trendsb2" />
									</Link>
								</div>
								<h6 style={{ fontSize: "15px", margin: "0.5rem 0" }}>
									Got a discount code? Add it in the next step.
								</h6>
							</div>
						</section>
					</section>
					<Banner />
					<Socials />
					<Footer />
				</>
			)}
		</div>
	);

	function NoCartItems() {
		return (
			<>
				<section className="no__items">
					<BsCart size={36} color={"red"} />
					<p>Your cart is empty!</p>
					<p>Browse our categories and discover our best deals</p>
					<Link to="/">
						<button>START SHOPPING</button>
					</Link>
				</section>
				<Footer />
			</>
		);
	}
}
