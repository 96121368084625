import { useParams } from "react-router-dom";
import Socials from "../../components/women/Socials";
import Footer from "../../components/Footer";
import { useEffect, useState } from "react";
import { get } from "../../config/ajaxRequest";
import { priceCalculator, itemPriceDiscountCalculator } from "../../utils/priceCaculator";

// const order = {
//   items: [
//     {
//       name: "",
//       price: "",
//       quantity: "",
//     },
//   ],
//   status: "Shipped",
// };

export default function OrderItem() {
  const { id } = useParams();
  const [orders, setOrders] = useState([])
  useEffect(() => {
    (async()=>{ 
        const ordersFromServe = await get("get/orders?cartid="+id)
        setOrders(Array.isArray(ordersFromServe) ? ordersFromServe:[]) 
    })() 
  }, []);

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ]

  return (
    <section className="account__page order">
      <h1>Order Details</h1>
      <hr />

      {orders.length < 1 && 
        <section className="list">
          <section className="list__item list--header list--horizontal">
            <h3 style={{textAlign:"center"}}>No Order Was Found</h3>
            <button className="list__item--button"></button>
          </section>
        </section>
      }

      { orders.length > 0 && 
        <>
          <section className="list">
            <section className="list__item list--header list--horizontal">
              <h3>Order Information</h3>
              <button className="list__item--button">Track Order</button>
            </section>


            <section className="list__item list--horizontal">
              <h3>Order Number</h3>
              <h3>{id}</h3>
            </section>


            {orders[0].shipmenttrackingNumber &&<section className="list__item list--horizontal">
              <h3>Tracking Number</h3>
              <h3>{orders[0].shipmenttrackingNumber ?? ""}</h3>
            </section>}

            <section className="list__item list--horizontal">
              <h3>Order Date</h3>
              <h3>{`${new Date(orders[0].orderDate ?? null).getDate()} - ${monthNames[new Date(orders[0].orderDate ?? null).getMonth()]} - ${new Date(orders[0].orderDate ?? null).getFullYear()}`}</h3>
            </section>

            <section className="list__item list--horizontal">
              <h3>Delivery Fee</h3>
              <h3>${parseFloat(orders[0].delivery_fee).toFixed(2)}</h3>
            </section>

            <section className="list__item list--horizontal">
              <h3>Payment Fee</h3>
              <h3>${parseFloat(orders[0].payment_fee).toFixed(2)}</h3>
            </section>

            <section className="list__item list--horizontal">
              <h3>Total Amount</h3>
              <h3>${parseFloat(orders[0].totalprice).toFixed(2)}</h3>
            </section>
          </section>

          <section className="list">
            <section className="list__item list--header list--horizontal">
              <h3>Items Order</h3>
              <button className="list__item--button list__item--button-colored">
                {orders.length >0 && orders[0].status===0 ? "Shipped":""}
                {orders.length >0 && orders[0].status===1 ? "Delivered":""}
              </button>
            </section>

            {orders.length>0 && orders.map((order, index)=>{
              
            return(
              <section key={index} className="order__details">
                <section className="order__detail">
                  <img
                    src={order.mainpic}
                    alt={order.title+" Image"}
                  />
                  <section className="order__detail--details">
                    <h4>{order.title}</h4>
                    <h5>
                      <span>${itemPriceDiscountCalculator(order)}</span>
                    </h5>
                    <h6>Quantity: {order.quantity}</h6>
                    ${priceCalculator(order)}
                    {/* {!isNaN(order.quantity * order.price) &&<h6>Total: ${parseFloat(order.quantity * order.price).toFixed(2) ?? ""}</h6>} */}
                  </section>
                  {/* <button>Buy Again</button> */}
                </section>
              </section>)
            }
            )}
          </section>
        </>
      }
      <Socials/>
      <Footer/>
    </section>
  );
}
