import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { TfiAngleLeft } from "react-icons/tfi";
import aler from "../../utils/aler";

import "../../styles/checkout.css";
import { get, post } from "../../config/ajaxRequest";
import { useAddress } from "../../customHook/useAddress";
import Preloader from "../../config/Preloader";
import { setItem } from "../../config/config";
import InformationSummary from "./InformationSummary";
import CheckOutPayment from "../../pages/checkout/CheckOutPayment";
import MerchantSummary from "./MerchantSummary";
import CheckOutButton from "./CheckoutButtons";
import { useCountries } from "../../customHook/useCountries";

export default function Address({
	user,
	setUser,
	setStep,
	shipAddress,
	setShipAddress,
}) {
	const [submitData, setSubmitData] = useState(false);
	const [showAdd, setShowAdd] = useState(false);
	const [loading, setLoading] = useState(false);
	const { address } = useAddress(setLoading);
	const { countries } = useCountries();
	const history = useHistory();

	// Add a state variable for form validation
	const [formValid, setFormValid] = useState(false);
 

	useEffect(() => {
		(() => {
			// console.log(address, "Address...")
			if (address.length > 1 && user.fname.length < 2) {
				// console.log(address, "Address...")
				let shippingObj = {};
				let billingObj = {};
				address.forEach((res) => {
					if (res.type == "shipping") shippingObj = { ...res };
					if (res.type == "billing") billingObj = { ...res };
				});
				// //console.log(shippingObj, billingObj, "testing address");
				setShipAddress(
					Object.keys(shippingObj).length > 1 ? shippingObj : billingObj
				);
				if (Object.keys(billingObj).length > 0) setUser(billingObj);
			}
			checkFormValidation();
		})();
	}, [address]);

	const checkFormValidation = () => {
		const requiredFields = [
			"fname",
			"lname", 
			"country",
			"street",
			"city",
			"state",
			"postalcode",
			"phone",
			"email",
		];

		const isUserFormValid = requiredFields.every(
			(field) => user[field].trim() !== ""
		);
		let isShipAddressFormValid = true;

		if (showAdd) {
			isShipAddressFormValid = requiredFields.every(
				(field) => shipAddress[field].trim() !== ""
			);
		}

		// Update the form validation status
		setFormValid(isUserFormValid && isShipAddressFormValid);
	};

	const resetShipAddress = () => {
		setShowAdd(!showAdd);
	};

	const fieldName = (key) => {
		let newKey = key;
		if (newKey == "fname") newKey = "First Name";
		if (newKey == "lname") newKey = "Last Name";
		if (newKey == "postalcode") newKey = "Postal Code";
		return newKey.toUpperCase();
	};

	const nextPage = async (newStep) => {
		const optional = ["company", "order_id", "cart_id"];
		let msg = "Please fill the following fields. ";
		let fields = "";
		let submitData2 = true;
		const userKeys = Object.keys(user);
		const shipAddressKeys = Object.keys(shipAddress);

		// console.log(userKeys, "userKeys...")
		// console.log(shipAddressKeys, "shipAddressKeys...")
		userKeys.forEach((res) => {
			if (!user[res] || user[res].length < 1) {
				let isFieldOptional = false;

				optional.forEach((res2) => {
					if (res == res2) isFieldOptional = true;
				});

				let key = fieldName(res);

				if (!isFieldOptional) fields += key + ", ";
			}
		});

		if (showAdd) {
			shipAddressKeys.forEach((res) => {
				if (!shipAddress[res] || shipAddress[res].length < 1) {
					let isFieldOptional = false;

					optional.forEach((res2) => {
						if (res == res2) isFieldOptional = true;
					});

					let key = fieldName(res);

					if (!isFieldOptional) {
						fields += "Ship Address " + key + ", ";
						submitData2 = false;
					}
				}
			});
		} else {
			setShipAddress(user)
			address.forEach((res) => {
				if (res.type != "shipping") return;
				userKeys.forEach((key) => {
					if (res.type == "shipping" && address[key] != user[key])
						submitData2 = false;
				});
			});
		}
		if (fields.length > 1) return aler(msg + fields);
		// console.log(msg, fields, "Testing...");

		let addressForLocalStorage = [{...user, type: "billing"}];
		if (submitData) post("address", { ...user, type: "billing" });

		if (showAdd && submitData2) {
			post("address", shipAddress);
		} else if (!showAdd && submitData2) {
			addressForLocalStorage.push({...user, type: "shipping"});
			post("address", { ...user, type: "shipping" });
		}
		setSubmitData(false);
		setItem("address", addressForLocalStorage);

		setStep(newStep);
	};

	const changeUser = (event) => { 
		setUser({ ...user, [event.target.name]: event.target.value }); 
		setSubmitData(true);
	};

	return (
		<>
			{loading ? (
				<Preloader />
			) : (
				<>
					<h1>Billing Address</h1>
					<p className="checkout__note">
						<b>NOTE:</b>
						Billing address would be use as the default shipping address to
						change your shipping address click on the checkbox below.
					</p>

					{/* Billing Form */}
					<form className="multi__form">
						<section className="input__group input__group--two">
							<section className="form__input">
								<label htmlFor="fname">First name*</label>
								<input
									type="text"
									name="fname"
									id="fname"
									value={user.fname}
									onChange={(e) => changeUser(e)}
								/>
							</section>
							<section className="form__input">
								<label htmlFor="lname">Last name*</label>
								<input
									type="text"
									name="lname"
									id="lname"
									value={user.lname}
									onChange={(e) => changeUser(e)}
								/>
							</section>
						</section>

						<section className="input__group">
							<section className="form__input">
								<label htmlFor="companyname">Company name(optional)</label>
								<input
									type="text"
									name="company"
									id="company"
									value={user.company}
									onChange={(e) => changeUser(e)}
								/>
							</section>
						</section>

						{/* <section className="input__group">
							<section className="form__input">
								<label htmlFor="county">
									County/Region*
								</label>
								<input
									type="text"
									name="county"
									id="county"
									value={shipAddress.county}
									onChange={changeUser}								
								/>
							</section>
						</section> */}
								
						<section className="input__group">
							<section className="form__input">
								<label htmlFor="country">Country / Region</label>
								<select value={user.country} name="country" id="country" onChange={changeUser}>
									{countries.map((country, index) => (
									<option key={index} value={country.name}>
										{country.name}
									</option>
									))}
								</select> 
							</section>
						</section>

						<section className="input__group">
							<section className="form__input">
								<label htmlFor="street">Street address</label>
								<input
									type="text"
									name="street"
									id="street"
									placeholder="House number and street name"
									value={user.street}
									onChange={(e) => changeUser(e)}
								/>
							</section>
						</section>

						<section className="input__group">
							<section className="form__input">
								<label htmlFor="city">Town / City</label>
								<input
									type="text"
									name="city"
									id="city"
									value={user.city}
									onChange={(e) => changeUser(e)}
								/>
							</section>
						</section>

						<section className="input__group">
							<section className="form__input">
								<label htmlFor="postalcode">Postal Code</label>
								<input
									type="text"
									name="postalcode"
									id="postalcode"
									value={user.postalcode}
									onChange={(e) => changeUser(e)}
								/>
							</section>
						</section>

						<section className="input__group">
							<section className="form__input">
								<label htmlFor="state">State*</label>
								<input
									type="text"
									name="state"
									id="state"
									placeholder="Select an option..."
									value={user.state}
									onChange={(e) => changeUser(e)}
								/>
							</section>
						</section>

						<section className="input__group">
							<section className="form__input">
								<label htmlFor="phone">Phone*</label>
								<input
									type="tel"
									name="phone"
									id="phone"
									value={user.phone}
									onChange={(e) => changeUser(e)}
								/>
							</section>
						</section>

						<section className="input__group">
							<section className="form__input">
								<label htmlFor="email">Email address*</label>
								<input
									type="email"
									name="email"
									id="email"
									value={user.email}
									onChange={(e) => changeUser(e)}
								/>
							</section>
						</section>
					</form>

					<section className="checkout__address-choice">
						<input type="checkbox" onClick={resetShipAddress} />
						<label htmlFor="">Ship to a different address</label>
					</section>

					{/* Shipping Form  */}
					{showAdd ? (
						<>
							{/* Billing Form */}
							<form className="checkout__address-ship multi__form">
								<h1>Shipping Address</h1>
								<section className="input__group input__group--two">
									<section className="form__input">
										<label htmlFor="addressfname">First name*</label>
										<input
											type="text"
											name="addressfname"
											id="addressfname"
											value={shipAddress.fname}
											onChange={(e) =>
												setShipAddress({
													...shipAddress,
													fname: e.target.value,
												})
											}
										/>
									</section>
									<section className="form__input">
										<label htmlFor="addresslname">Last name*</label>
										<input
											type="text"
											name="addresslname"
											id="addresslname"
											value={shipAddress.lname}
											onChange={(e) =>
												setShipAddress({
													...shipAddress,
													lname: e.target.value,
												})
											}
										/>
									</section>
								</section>

								<section className="input__group">
									<section className="form__input">
										<label htmlFor="addresscompany">
											Company name(optional)
										</label>
										<input
											type="text"
											name="addresscompany"
											id="addresscompany"
											value={shipAddress.company}
											onChange={(e) =>
												setShipAddress({
													...shipAddress,
													company: e.target.value,
												})
											}
										/>
									</section>
								</section>


								{/* <section className="input__group">
									<section className="form__input">
										<label htmlFor="county">
											County/Region*
										</label>
										<input
											type="text"
											name="county"
											id="county"
											value={shipAddress.county}
											onChange={(e) =>
												setShipAddress({
													...shipAddress,
													county: e.target.value,
												})
											}
										/>
									</section>
								</section> */}
								
								<section className="input__group">
									<section className="form__input">
										<label htmlFor="addresscountry">Country</label>
										<select value={shipAddress.country} name="country" id="country"
										 	onChange={(e) =>setShipAddress({...shipAddress,
												country: e.target.value,
											})
										}>
											{countries.map((country, index) => (
											<option key={index} value={country.name}>
												{country.name}
											</option>
											))}
										</select> 
									</section>
								</section>

								<section className="input__group">
									<section className="form__input">
										<label htmlFor="addressstreet">Street address</label>
										<input
											type="text"
											name="addressstreet"
											id="addressstreet"
											placeholder="House number and street name"
											value={shipAddress.street}
											onChange={(e) =>
												setShipAddress({
													...shipAddress,
													street: e.target.value,
												})
											}
										/>
									</section>
									 
								</section>

								<section className="input__group">
									<section className="form__input">
										<label htmlFor="addresscity">Town / City</label>
										<input
											type="text"
											name="addresscity"
											id="addresscity"
											value={shipAddress.city}
											onChange={(e) =>
												setShipAddress({
													...shipAddress,
													city: e.target.value,
												})
											}
										/>
									</section>
								</section>

								<section className="input__group">
									<section className="form__input">
										<label htmlFor="addresspostalcode">Postal Code*</label>
										<input
											type="text"
											name="addresspostalcode"
											id="addresspostalcode"
											placeholder="Insert Postal Code"
											value={shipAddress.postalcode}
											onChange={(e) =>
												setShipAddress({
													...shipAddress,
													postalcode: e.target.value,
												})
											}
										/>
									</section>
								</section>

								<section className="input__group">
									<section className="form__input">
										<label htmlFor="addressstate">State*</label>
										<input
											type="text"
											name="addressstate"
											id="addressstate"
											placeholder="Select an option..."
											value={shipAddress.state}
											onChange={(e) =>
												setShipAddress({
													...shipAddress,
													state: e.target.value,
												})
											}
										/>
									</section>
								</section>

								<section className="input__group">
									<section className="form__input">
										<label htmlFor="addressphone">Phone*</label>
										<input
											type="tel"
											name="addressphone"
											id="addressphone"
											value={shipAddress.phone}
											onChange={(e) =>
												setShipAddress({
													...shipAddress,
													phone: e.target.value,
												})
											}
										/>
									</section>
								</section>

								<section className="input__group">
									<section className="form__input">
										<label htmlFor="addressemail">Email address*</label>
										<input
											type="email"
											name="addressemail"
											id="addressemail"
											value={shipAddress.email}
											onChange={(e) =>
												setShipAddress({
													...shipAddress,
													email: e.target.value,
												})
											}
										/>
									</section>
								</section>
							</form>
						</>
					) : (
						<></>
					)}
				</>
			)}
			<CheckOutButton
				prevText={"Cart"}
				prevLink={"/cart"}
				nextText={"Payment"}
				setStep={nextPage}
				newStep={2}
			/>
		</>
	);
}
