import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../styles/wishlist.css";
import aler from "../utils/aler"

import { WishContext } from "../contexts/WishContext";
import { CartContext } from "../contexts/CartContext";

import { BsHeart } from "react-icons/bs";

import WishListItem from "../components/WishListItem";
import PageBanner from "../components/common/PageBanner";
import { get } from "../config/ajaxRequest";

export default function WishList() {
  const { savedItems, setSavedItems } = useContext(WishContext);
  const [cartQuantities, setCartQuantities ] = useState([])
	const { cart} = useContext(CartContext);
  
	useEffect(() => { 
		(async () => {
			if(savedItems.length < 1)return 
			let ids= ""
			savedItems.forEach(res=>{
        if(res.id)ids+=res.id+","
        if(res.product_id)ids+=res.product_id+","
      })
			if(ids.length>0)ids.slice(0, -1)
			try {
				const response = await get("product/filter/quantity?productIds=" + ids);
        // console.log(response, ids, "response, ids")
        setCartQuantities(response)	 
			} catch (err) {}
 
		})();
	}, [savedItems]);

  const orderItems = (event) => {
    const filter = event.target.value;

    switch (filter) {
      case "expiring":
        showExpiringItems();
        break;
      case "brand_az":
        showAscndItems();
        break;
      default:
        aler("Sorting");
    }
  };

  const showExpiringItems = () => {};

  const showAscndItems = () => {};

  return (
    <>
      <PageBanner pageName={"Wishlist"}>
        <Link to={"./wishlist"}>Wishlist</Link>
        <Link to="/cart">Cart</Link>
      </PageBanner>

      <section className="container wishlist">
        {savedItems.length > 0 ? (
          <>
          {/* <WishListHeader/> */}
          <section className="wishlist__items">
            {savedItems.map((item) => {
              return <WishListItem key={item.id} item={item} cartQuantities={cartQuantities} />;
            })}
          </section>
          </>
        ) : (
          <NoSavedItems />
        )}
      </section>
    </>
  );

  function NoSavedItems() {
    return (
      <section className="no__items">
        <BsHeart size={36} color={"red"} />
        <p>You have no saved items</p>
        <Link to="/my-account">
          <button>RETURN TO SHOP</button>
        </Link>
      </section>
    );
  }
}
