import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { CartContext } from "../../contexts/CartContext";

import CheckoutNav from "./CheckOutNav";
import CartPreview from "./CartPreview";
import { getItem } from "../../config/config";
import Socials from "../women/Socials";
import Footer from "../Footer";

export default function CheckOutLayout({ children }) {
	const history = useHistory();
	const { cart } = useContext(CartContext);
	const [count, setCount] = useState(0);

	useEffect(() => {
		(async () => {
			if (count < 3) setCount(count + 1);
			if (count > 1) {
				if (cart.length < 1) history.push("/cart");
				const user = getItem("user");
				const token = getItem("token");
				if (!user?.usertype || !token) history.push("/signin?proceed=checkout");
			}
		})();
	}, [cart, count]);

	return (
		<>
		<section className="checkout container">
			<section className="checkout__address">
				<div className="checkout__address--header">					
					<CheckoutNav />
				</div>

				{children}
			</section>

			<CartPreview />
		</section>
			<Socials/>
			<Footer/></>
	);
}
