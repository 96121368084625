import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import Images from "../../config/Images";
import "../../asset/css/card.css";
import { get } from "../../config/ajaxRequest";

export default function Card() {
	const [cardsData, setCardsData] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await get("card/files");
				const myRes = res.imagesfitnessCard; // Destructure specific properties
				setCardsData(myRes); // Update state with individual properties
				//console.log(myRes, "image data here...");
			} catch (err) {
				console.error("Error fetching data:", err);
			}
		};

		fetchData();
	}, []);
	return (
		<div className="container-fluid mt-3">
			<h2 className="funday">Hianga FunDay</h2>
			<p style={{ fontSize: "14px" }}>
				get our latest daily wears at affordable prices...
			</p>
			<div className="mainCard">
				{cardsData.map((card, index) => {
					const to = `/products/${card.menuthree_id ?? 1}`;
					return (
						<div className="card cardbtn" key={index}>
							<Link to={to} className="btn">
								<div>
									<img
										src={card.imageUrl}
										alt={`Card ${index + 1}`}
										className="cardimage"
									/>

									<div className="card-body">
										<h4 className="card-title">{card.title}</h4>
										<p className="card-text">{card.description}</p>
										<div className="borcard">
											<motion.div className="borderedcard">shop now</motion.div>
										</div>
									</div>
								</div>
							</Link>
						</div>
					);
				})}
			</div>
		</div>
	);
}
