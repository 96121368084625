import Footer from "../../components/Footer";
import OrderList from "../../components/account/OrderList";
import Socials from "../../components/women/Socials";
import "../../styles/orders.css";
import Preloader from "../../config/Preloader";
import { useState, useEffect } from "react";
import { get } from "../../config/ajaxRequest";


export default function Orders() {
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    (async()=>{ 
        const ordersFromServe = await get("get/orders")
        setOrders(Array.isArray(ordersFromServe) ? ordersFromServe:[])
        setLoading(false) 
    })()
  }, []);
  return (
    <section className="account__page orders">
      {loading ?
        <Preloader/>
      :
        <>
          <h1>Orders</h1>
          <hr />
          <section className="order__list">
            {/* <OrderList  />  */}
            <OrderList orders={orders} setOrders={setOrders} />
          </section>
          <Socials/>
          <Footer/>
        </>
      }
    </section>
  );
}
