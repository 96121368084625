import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { TfiAngleLeft, TfiAngleRight } from "react-icons/tfi";
import aler from "../../utils/aler";

import "../../styles/checkout.css";

export default function CheckOutButton({
	prevText,
	prevLink,
	oldStep,
	nextText,
	nextLink,
	newStep,
	setStep,
}) {
	return (
		<>
			<div className="checkout__address--footer">
				<div className="checkout__address--links">
					{prevLink && prevLink.length > 0 ? (
						<Link to={prevLink} style={{cursor:"pointer"}}>
							<TfiAngleLeft color="white" size={14} style={{cursor:"pointer"}} />
							<span style={{ color: "white", padding: "0 20px", cursor:"pointer" }}>
								{prevText}
							</span>
						</Link>
					) : (
						<Link to={"#"} onClick={() => setStep(oldStep)} style={{cursor:"pointer"}}>
							<TfiAngleLeft color="white" size={14} style={{cursor:"pointer"}}/>
							<span style={{ color: "white", padding: "0 20px", cursor:"pointer" }}>
								{prevText}
							</span>
						</Link>
					)}

					{/* Next Button */}
					{nextLink && nextLink.length > 0 && (
						<Link to={nextLink} style={{cursor:"pointer"}}>
							<span style={{ color: "white", padding: "0 20px", cursor:"pointer" }}>
								{nextText}
							</span>
							<TfiAngleRight color="white" size={14} style={{cursor:"pointer"}}/>
						</Link>
					)}

					{newStep && newStep > 0 && (
						<Link to={"#"} onClick={() => setStep(newStep)} style={{cursor:"pointer"}}>
							<span style={{ color: "white", padding: "0 20px", cursor:"pointer" }}>
								{nextText}
							</span>
							<TfiAngleRight color="white" size={14} style={{cursor:"pointer"}} />
						</Link>
					)}
				</div>
			</div>
		</>
	);
}
