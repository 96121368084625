import { get } from "../config/ajaxRequest"

export const priceCalculator = (productObj) =>{
    const price = productObj.price ?? 1
    const qty = productObj.quantity ?? 1
    const discount = productObj.discount ?? 0

    const discountedPrice = price - (discount / 100) * price
    return parseFloat(parseFloat(discountedPrice * qty).toFixed(2))
}

export const itemPriceDiscountCalculator = (productObj) =>{
    const price = productObj.price ?? 1 
    const discount = productObj.discount ?? 0

    let total = price
    if(discount > 0) total = price - (discount / 100) * price 
    return parseFloat(total).toFixed(2)
}


export const coupleCalculator = (productObj, couple) =>{
    return parseFloat(priceCalculator(productObj) - couple).toFixed(2) ?? priceCalculator(productObj) - couple
}


export async function getProductPrices(cart, setCart=null, shouldSetSession=false) {
  let products = [];
  let total =  10000
  const session = shouldSetSession ? "yes":"no" 

  cart.forEach((res, index) => {
    products.push(res.id);
  });

  
  if (products.length > 0) {
    products = JSON.stringify(products);
    // console.log(cart, products) 
    const productPrices = await get('checkprices?products=' + `${products}`+"&shouldsetsession="+session);
     
    if (Array.isArray(productPrices)) {
      let shouldUpdateCart = false;
      let newCart = [...cart];
      total = 0 
 
      productPrices.forEach((serverPrice) => {
        cart.forEach((cartPrice, index) => { 
          if (serverPrice.id == cartPrice.id) {
            if (cartPrice.price != serverPrice.price || cartPrice.discount != serverPrice.discount) {
              shouldUpdateCart = true;
              newCart[index].price = serverPrice.price;
              newCart[index].discount = serverPrice.discount;           
            }
            newCart[index]["cost"] = priceCalculator(serverPrice); 
          }
        }); 
      });
      // console.log(newCart)
      newCart.forEach(data=>{
        total += priceCalculator(data)
        // console.log(data, priceCalculator(data), "...")
      }) 
      if (shouldUpdateCart && setCart) setCart(newCart);
    }
  }
  return parseFloat(total).toFixed(2)
}

export function calculatePaystackFee(amount, exchangeRate) { 
  // Paystack charges 1.5% + ₦100 for local transactions
  const percentageFee = 0.015; // 1.5%
  const fixedFee = 100/exchangeRate; // ₦100
  const totalFee = amount * percentageFee + fixedFee; 
  return parseFloat(totalFee.toFixed(2));
}

export function calculatePayPalFee(amount) { 
  // PayPal charges 2.9% + $0.30 for international transactions
  const percentageFee = 0.029; // 2.9%
  const fixedFee = 0.3; // $0.30
  const totalFee = amount * percentageFee + fixedFee;
  return parseFloat(totalFee.toFixed(2));
}

export function calculateFee(type){
  let typeLowerCase = type.toLowerCase()
  if(typeLowerCase === "paypal")return calculatePayPalFee
  if(typeLowerCase === "paystack")return calculatePaystackFee
  return null
}
