import { useState, useEffect } from "react";
import {  City } from "country-state-city";
import "../../styles/address.css";
import "../../styles/auth.css";
import Socials from "../../components/women/Socials";
import Footer from "../../components/Footer";
import { get, post } from "../../config/ajaxRequest";
import Preloader from "../../config/Preloader";
import aler from "../../utils/aler";
import { useCountries } from "../../customHook/useCountries";

export default function BiillingAddress() { 
  const [address, setAddress] = useState({});
  const [newAddress, setNewAddress] = useState({});
  const [adjustments, setAdjustments] = useState(false);
  const [typeUrl, setTypeUrl] = useState("");
  const [loading, setLoading] = useState(true);
	const { countries } = useCountries();

  useEffect(() => {
    (async()=>{ 
      const addresses =await get("getaddress")
      const urlArr = window.location.pathname.split("/")
      const type = urlArr[urlArr.length-1]
      setTypeUrl(type)
      // //console.log(urlArr)
      addresses.forEach(element => {
        if(element.type === type){
          setAddress(element)
          setNewAddress(element)
        }
      }); 
      setLoading(false)
    })()    
  }, []);
  
  const checkChanges= () =>{
    const keys = Object.keys(address)

    for(let i=0; i< keys.length; i++){ 
      if(address[keys[i]] !== newAddress[keys[i]]){
        setAdjustments(true)
        break
      }
      if(i===keys.length-1)setAdjustments(false)
    }
  }

  const handleSubmit = async(e) =>{ 
    e.preventDefault();
    if(!adjustments)return
    const res = await post("address", newAddress)
    if(res.indexOf("Success") > -1){
      setAddress(newAddress)
      setAdjustments(false)
      return aler(res, "success")
    }
    
    aler(res)
  }

  return (
    <section className="account__page address">
      <h1>{typeUrl==="billing" ? "Billing":"Shipping"} Address</h1>
      {loading ?
        <Preloader/>
        :
        <form className="multi__form" onSubmit={event=>handleSubmit(event)}>
          <section className="input__group input__group--two">
            <section className="form__input">
              <label htmlFor="fname">First name*</label>
              <input type="text" name="fname" id="fname" value={newAddress.fname} onChange={e=>setNewAddress({...newAddress, fname:e.target.value})} onBlur={checkChanges}/>
            </section>
            <section className="form__input">
              <label htmlFor="lname">Last name*</label>
              <input type="text" name="lname" id="lname" value={newAddress.lname}  onChange={e=>setNewAddress({...newAddress, lname:e.target.value})} onBlur={checkChanges}/>
            </section>
          </section>

          <section className="input__group">
            <section className="form__input">
              <label htmlFor="company">Company name(optional)</label>
              <input type="text" name="company" id="company" value={newAddress.company}  onChange={e=>setNewAddress({...newAddress, company:e.target.value})} onBlur={checkChanges}/>
            </section>
          </section>

          {/* <section className="input__group">
            <section className="form__input">
              <label htmlFor="county">County / Region</label>
              <input
									type="text"
									name="county"
									id="county"
									value={newAddress.county}
									onChange={e=>setNewAddress({...newAddress, county:e.target.value})} onBlur={checkChanges}								
								/> 
               
            </section>
          </section> */}


          <section className="input__group">
            <section className="form__input">
              <label htmlFor="country">Country</label>
              <select value={newAddress.country} onBlur={checkChanges} name="country" id="country" onChange={e=>{setNewAddress({...newAddress, country:e.target.value});checkChanges();}}>
									{countries.map((country, index) => (
									<option key={index} value={country.name}>
										{country.name}
									</option>
									))}
								</select> 
               
            </section>
          </section>

          <section className="input__group">
            <section className="form__input">
              <label htmlFor="street">Street address</label>
              <input
                type="text"
                name="street"
                id="street"
                placeholder="House number and street name"
                value={newAddress.street}
                onChange={e=>setNewAddress({...newAddress, street:e.target.value})} onBlur={checkChanges}
              />
            </section>
            {/* <section className="form__input">
              <input
                type="text"
                name=""
                id=""
                placeholder="Apartment, Suite, Unit, etc.(optional)"
                value={newAddress.apartment}
                onChange={e=>setNewAddress({...newAddress, apartment:e.target.value})} onBlur={checkChanges}
              />
            </section> */}
          </section>

          <section className="input__group">
            <section className="form__input">
              <label htmlFor="country">Town / City</label>
              <input type="text" name="city" id="city"  value={newAddress.city} onChange={e=>setNewAddress({...newAddress, city:e.target.value})} onBlur={checkChanges}/>
            </section>
          </section>

          <section className="input__group">
            <section className="form__input">
              <label htmlFor="postalcode">Postal Code*</label>
              <input
                type="text"
                name="postalcode"
                id="postalcode"
                placeholder="Postal Code"
                value={newAddress.postalcode}
                onChange={e=>setNewAddress({...newAddress, postalcode:e.target.value})} onBlur={checkChanges}
              />
            </section>
          </section>

          <section className="input__group">
            <section className="form__input">
              <label htmlFor="state">State*</label>
              <input
                type="text"
                name="state"
                id="state"
                placeholder="State"
                value={newAddress.state}
                onChange={e=>setNewAddress({...newAddress, state:e.target.value})} onBlur={checkChanges}
              />
            </section>
          </section>

          <section className="input__group">
            <section className="form__input">
              <label htmlFor="phone">Phone*</label>
              <input type="tel" name="phone" id="phone"  value={newAddress.phone}  onChange={e=>setNewAddress({...newAddress, phone:e.target.value})} onBlur={checkChanges}/>
            </section>
          </section>

          <section className="input__group">
            <section className="form__input">
              <label htmlFor="country">Email address*</label>
              <input type="email" name="email" id="email" readOnly value={newAddress.email} onChange={e=>setNewAddress({...newAddress, email:e.target.value})} onBlur={checkChanges}/>
            </section>
          </section>

          <button type="submit"className="joinButton active">SAVE ADDRESS</button> 
      </form>
      }
      <Socials/>
      <Footer/>
    </section>
  );

}
