import { useEffect, useState } from "react";
import { useDropDownMenu } from "../../customHook/useDropDownMenu";
import { useMainMenu } from "../../customHook/useMainMenu";
import {
	MenuWithOnlyText,
	MenuWithSmallImage,
} from "./MenuComponent/DropDownMenuDisplay";
import "../../asset/css/menustyles.css";
import MarketPlace from "./MarketPlace";

export const DropDownMenu = ({ menuOneId, handleMouseLeave }) => {
	const dropDownMenu = useDropDownMenu(menuOneId);
	const [menuOneName, setMenuOneName] = useState("")
	const mainMenu = useMainMenu(window.location.pathname);

	useEffect(() => {
		mainMenu.map(response=>{ 
			if(response.id == menuOneId)setMenuOneName(response.name.trim())
			// console.log(response.name)
		})
	}, [menuOneId, mainMenu]);
  
	return (
		<div className="mainmenu">
			{dropDownMenu.length > 0 &&
				dropDownMenu.map((response, index) => {
					if (response.menuone_id.toString() !== menuOneId.toString())
						return <div key={index}></div>;
					return (
						<ul className="menu" key={index}>
							<div className="titleText">
								<h4 className="menutitle">{response.name}</h4>

								<div className="line">
									<hr className="innerline2" />
								</div>
							</div>
							<div className="bodyText">
								{response.menuthrees.map((item, index) => { 
									return (
										<div key={index}>
											{response.menutwotype.toLowerCase() == "text" && (
												<MenuWithOnlyText
													item={item}
													handleMouseLeave={handleMouseLeave}
												/>
											)}
											{response.menutwotype.toLowerCase().includes("image") && (
												<MenuWithSmallImage
													item={item}
													handleMouseLeave={handleMouseLeave}
												/>
											)}

											{ menuOneName.trim() === "Marketplace"
												&&
												<MarketPlace/>
											}
										</div>
									);
								})}
							</div>
						</ul>
					);
				})}
			 
			{menuOneName.trim().toLowerCase() == "marketplace" && 
				<ul className="menu">
					<div className="titleText">
						<h4 className="menutitle">Marketplace</h4>
						<div className="line">
							<hr className="innerline2" />
						</div>
					</div>
					<div className="bodyText"> 							
						<MarketPlace/>	 
					</div>
				</ul>
			}
		</div>
	);
};
