import React, { useEffect, useState } from "react";
import "../../asset/css/AccessoryList.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function HorizontalScroll({ menu, menuName, id, setMenuEvent}) {
	// Convert the menuName into an array by splitting it
	// const menuNameArray = menuName.split(",");
	const [link, setLink] = useState("")
 
	useEffect(() => {
		(async () => { 			
			// alert("T")
			const urlArr = window.location.href.split("/")
			const oldId = urlArr.pop()
			const url = window.location.pathname.replace('/'+oldId, `/${id}`); 
			// console.log(url, oldId, menuName, id, "Testomg...", menu )
			setLink(url);		 

		})()
	}, [id]);
 
	return (
		<>
			{/* {menuNameArray.map((accessory, index) => ( */}
				<Link to={link} onClick={()=>setMenuEvent(id)} className="accessory-item">
					<div className="accessory-list-container">
						<div className="accessory-list">
							{menuName}
						</div> 
					</div>
				</Link>
			{/* ))} */}
		</>
	);
}
