import React, { useRef, useEffect, useState } from "react";
import "../../../asset/css/compstyle.css";
import { getItem } from "../../../config/config";
import { motion } from "framer-motion";
import Images from "../../../config/Images";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Socials from "../../women/Socials";
import Footer from "../../Footer";
import Ads1 from "./Ads/Ads1";
import { get } from "../../../config/ajaxRequest";
import Videos from "../../../config/Videos";

export default function Home() {
	const videoRef = useRef(null);
	const [videoData, setVideoData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [backgroundImages, setBackgroundImages] = useState([]);
	const [error, setError] = useState(null);

	useEffect(() => {
		(async () => {
			try {
				const res = await get("home/files");
				setVideoData(res.videoData);
				setBackgroundImages(res.imageData);
			} catch (err) {}
		})();

		// Load last menu logic
		const lastMenu = getItem("lastmenu");
		const fullUrl = window.location.pathname;

		// if (fullUrl === "/" && lastMenu && lastMenu.length > 0) {
		// 	window.location.href = lastMenu;
		// }
	}, []);

	useEffect(() => {
		if (videoData.length > 0) {
			let currentVideoIndex = 0;
			const interval = setInterval(() => {
				currentVideoIndex = (currentVideoIndex + 1) % videoData.length;
				videoRef.current.src = videoData[currentVideoIndex].source;
			}, 10000);

			return () => clearInterval(interval);
		}
	}, [videoData]);

	return (
		<>
			<Ads1 />
			<div className="video-overlay">
				{videoData.length > 0 ? (
					<video autoPlay muted loop ref={videoRef}></video>
				) : (
					<video autoPlay muted loop src={Videos.vid1}></video>
				)}

				<div className="overlay-content">
					<h1>Hianga Store</h1>
					<p>What to expect..</p>
				</div>
			</div>
			<Ads1 />
			<motion.div className="imagedivider">
				{backgroundImages.length > 0 ? (
					<>
						{backgroundImages.map((image, index) => (
							<motion.div
								className={image.title}
								style={{
									backgroundImage: `linear-gradient(#10084086,#00000038),url(${image.source})`,
								}}
								key={index}
							>
								<motion.div
									initial={{ x: -100 }}
									animate={{ x: 0 }}
									transition={{ duration: 1, delay: 2 }}
									className="{index%2==0 ? btnhome : btnhome1}"
								>
									<Link
										to={`/${encodeURIComponent(image.title)}`}
										className="btnhomelink"
									>
										{image.text}
									</Link>
								</motion.div>
							</motion.div>
						))}
					</>
				) : (
					<>
						<motion.div
							className="kiddies"
							style={{
								backgroundImage: `linear-gradient(#10084086,#00000038),url(${backgroundImages.kids})`,
							}}
						>
							<motion.div
								initial={{ x: -100 }}
								animate={{ x: 0 }}
								transition={{ duration: 1, delay: 2 }}
								className="btnhome1"
							>
								<Link to="/kids" className="btnhomelink">
									Shop For Kids
								</Link>
							</motion.div>
						</motion.div>
						<motion.div
							className="men"
							style={{
								backgroundImage: `linear-gradient(#10084086,#00000038),url(${backgroundImages.men})`,
							}}
						>
							<motion.div
								initial={{ x: -100 }}
								animate={{ x: 0 }}
								transition={{ duration: 1, delay: 1 }}
								className="btnhome"
							>
								<Link to="/men" className="btnhomelink">
									Shop For Men
								</Link>
							</motion.div>
						</motion.div>

						<motion.div
							className="fitness"
							style={{
								backgroundImage: `linear-gradient(#10084086,#00000038),url(${backgroundImages.fitness})`,
							}}
						>
							<motion.div
								initial={{ x: -100 }}
								animate={{ x: 0 }}
								transition={{ duration: 1, delay: 3.5 }}
								className="btnhome1"
							>
								<Link to="/fitness" className="btnhomelink">
									Shop For Fitness
								</Link>
							</motion.div>
						</motion.div>
					</>
				)}
			</motion.div>
			<div className="ads">
				<Link to="#" className="link">
					EASY WORLDWIDE DELIVERY <br />
				</Link>
				<p>minimum spends apply </p>
			</div>
			<Socials />
			<Footer />
		</>
	);
}
