import {useState, useEffect} from 'react'
import { get} from '../config/ajaxRequest'
import { getItem, setItem } from '../config/config'
export const useMainMenu = (fullUrl)=>{
    const [mainMenu, setMainMenu] = useState([]) 

    const requestToServer = async() =>{ 
        //get data from server & time of last update
        const data = await get("user/getmenu") 
        if(data.menuOne) setItem("menuOne", data.menuOne)
        if(data.menuTwo) setItem("menuTwo", data.menuTwo)
        if(data.lastMenuUpdateId) setItem("lastMenuUpdateId", data.lastMenuUpdateId) 
        return data.menuOne 
    }

    const getData = async() =>{
        let menuOne = getItem("menuOne")
        let shouldCheckForUpdate = 1

        if(!menuOne){
            menuOne = await requestToServer()  
            shouldCheckForUpdate = 0
        }  
        return returnValue(menuOne, shouldCheckForUpdate)
    }

    const returnValue = async(menuOne, shouldCheckForUpdate) =>{  
        if(fullUrl.indexOf("/men") > -1){ setItem("lastmenu", "men"); return [menuOne.men, shouldCheckForUpdate]}
        if(fullUrl.indexOf("/kids") > -1){ setItem("lastmenu", "kids"); return [menuOne.kids, shouldCheckForUpdate]}
        if(fullUrl.indexOf("/fitness") > -1){ setItem("lastmenu", "fitness"); return [menuOne.fitness, shouldCheckForUpdate]}
        if(fullUrl.indexOf("/women") > -1){ setItem("lastmenu", "women"); return [menuOne.women, shouldCheckForUpdate]}
        if(fullUrl.indexOf("/jewellery") > -1){ setItem("lastmenu", "jewellery"); return [menuOne.jewellery, shouldCheckForUpdate]}
        if(fullUrl.indexOf("/root") > -1){ setItem("lastmenu", "root"); return [menuOne.root, shouldCheckForUpdate]}
        if(fullUrl.indexOf("/yeshua") > -1){ setItem("lastmenu", "yeshua"); return [menuOne.yeshua, shouldCheckForUpdate]}
        const lastmenu = await getItem("lastmenu") 
        if(lastmenu && lastmenu.length>0){return [menuOne[lastmenu]??[], shouldCheckForUpdate]}
        return [[], shouldCheckForUpdate]
    }
    const checkForMenuUpdate = async (setMainMenu) =>{ 
        //get data from server & time of last update
        const data = await get("lastmenuupdateid") 
        if(data.lastMenuUpdateId == getItem("lastMenuUpdateId")) return
        
        const menuOne = await requestToServer()
        let shouldCheckForUpdate = 0
        const [menu, notImportantHereButImportantInGetData] = returnValue(menuOne, shouldCheckForUpdate)         
        setMainMenu(Array.isArray(menu) ? menu:[])
    }
 
    useEffect(() => {
        (async()=>{ 
            const notMenuRoute = ["/"]
            let proceed = true
            notMenuRoute.forEach(res=>{
                if(fullUrl === res)proceed=false
            })

            // console.log(fullUrl, notMenuRoute, "testing...")
            if(proceed==false)return setMainMenu([])
            const [menu, shouldCheckForUpdate] = await getData() 
            setMainMenu(menu)
            if(shouldCheckForUpdate) checkForMenuUpdate(setMainMenu)
        })()
    
    }, [fullUrl]);
    return mainMenu
}

