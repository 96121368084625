import React, { useState, useEffect, useContext } from "react";
import { BiHeart } from "react-icons/bi";
import { Link } from "react-router-dom";
import Images from "../../config/Images";
import "../../asset/css/reuse.css";
import ProductFilters from "./ProductFilters";
import { get } from "../../config/ajaxRequest";
import { setItem, getItem } from "../../config/config";
import Preloader from "../../config/Preloader";
import { resizeImage } from "../../utils/picture";
import aler from "../../utils/aler";
import { WishContext } from "../../contexts/WishContext";
import { motion } from "framer-motion";
import Breadcrumb from "./Breadcrumbs";
import Ads1 from "../Menu/ThirdMenu/Ads/Ads1";
import ReadMore from "./ReadMore";
import Banner from "../women/Banner";
import Socials from "../women/Socials";
import Footer from "../Footer";
import HorizontalScroll from "./HorizontalScroll";
import { saveItem } from "../../utils/saveToCardAndWishList";
import { itemPriceDiscountCalculator } from "../../utils/priceCaculator";

export default function Reusable() {
	const [id, setId] = useState(null);
	const [products, setProducts] = useState([]);
	const [selected, setSelected] = useState("");
	const [activeFilterOption, setActiveFilterOption] = useState({});
	const [filterOptionCount, setFilterOptionCount] = useState({});
	const [showBack, setShowBack] = useState({ id: 0 });
	const [preloader, setPreloader] = useState(false);
	const productsPerPage = 12; // Number of products to show per page
	const [visibleProducts, setVisibleProducts] = useState(productsPerPage); // Number of products to initially display
	const [menuEvent, setMenuEvent] = useState(0);
	const { savedItems, setSavedItems } = useContext(WishContext);
	const [product, setProduct] = useState({});

	const [horizontalMenu, sethorizontalMenu] = useState([]);
	const [readMore, setReadMore] = useState({"longText":"", "title":""});

	const getId =() => {
		let locArr = window.location.href.split("/");
		let lastIndex = locArr.length - 1;
		let id = locArr[lastIndex] ?? 1; 	
		setId(id)
		return id
	}

	useEffect(() => {
		const fetchData = async () => {
			const id = getId()
			try {
				const res = await get("menu/read/"+id);
				const rMore = res.readMore ?? {"longText":"", "title":""}
				setReadMore(rMore); 
 
				const aces = res.otherMenu ?? []; 
				sethorizontalMenu(aces); 
				
				//console.log(res, res.readMore, "Geting other things now...")
			} catch (err) {
				console.error("Error fetching data:", err);
			}
		};

		fetchData();
	}, [menuEvent]);


	useEffect(() => {
		document.addEventListener("menuEvent", function (e) { 
			const data = e.detail.data
			const newMenuEvent = menuEvent+1
			// console.log(newMenuEvent, "menuEvent", menuEvent, e) 
			if(typeof data === "number" && !isNaN(data))return setMenuEvent(data)
			setMenuEvent(newMenuEvent);
		});
	}, []);
 
	useEffect(() => {
		(async () => {
			const id = getId()
			setPreloader(true);
			setItem("products", []);
			try {
				const ps = await get("user/menuproducts/" + id);
				// console.log(ps)
				let prod = [];
				if (Array.isArray(ps?.product)) prod = ps?.product;

				setItem("products", prod);

				setProducts(prod);
			} catch (err) {
				console.error("Error fetching products:", err);
			} finally {
				setPreloader(false);
			}
		})();
	}, [menuEvent]);

	useEffect(() => {
		(async () => {
			if (products.length < 1) return setActiveFilterOption({});
			const fil = (await getItem("filter")) ?? [];
			let obj = {};
			if (fil.length > 0) {
				let lastFilterId = 0;
				fil.forEach((filter) => {
					const filterOption = filter.filteroptions;
					for (let i = 0; i < filterOption.length; i++) {
						if (lastFilterId == filter.id) break;
						for (let j = 0; j < products.length; j++) {
							if (products[j].filteroptionids == null) continue;
							if (
								products[j].filteroptionids.indexOf(
									"," + filterOption[i].id + ","
								) > -1
							) {
								obj[filterOption[i].filter_id] = true;
								lastFilterId = filterOption[i].filter_id;
								break;
							}
						}
					}
				});
				//console.log(obj, fil, "obj");
				setActiveFilterOption(obj);
			}
		})();
	}, [products]);

	useEffect(() => {
		let filterOptionNumber = {};
		const newProducts = [...products];
		products.forEach((product) => {
			if (product.filteroptionids == null) return;
			const keyArr = product.filteroptionids.split(",");
			for (let i = 0; i < keyArr.length; i++) {
				const key = keyArr[i];
				if (key.length < 1) continue;
				if (key in filterOptionNumber) {
					filterOptionNumber[key] = filterOptionNumber[key] + 1;
				} else {
					filterOptionNumber[key] = 1;
				}
			}
			setFilterOptionCount(filterOptionNumber);
		});
	}, [products]);

	// Function to load more products
	const handleLoadMore = () => {
		setVisibleProducts(
			(prevVisibleProducts) => prevVisibleProducts + productsPerPage
		);
	};

	function filterIsFound(selected, filteroptionids) {
		if (selected.length < 3) return true;
		if (!filteroptionids) return false;
		const selectedArray = selected.split(",");
		for (let i = 0; i < selectedArray.length; i++) {
			if (selectedArray[i].length < 1) continue;
			if (filteroptionids.includes(selectedArray[i] + ",")) {
				return true;
			}
		}
		return false;
	}

	return (
		<>
			<Ads1 />
			<Breadcrumb id={id} menuName={readMore.title} type={"reuse"}/> 
				<ReadMore
					text={readMore.longText}
					maxLength={60}
					title={readMore.title} 
				/> 

			<hr style={{ color: "#c8c6d4" }} />
			<div className="AppAccess" style={{ display: "flex", overflowX: "auto" }}>
				{preloader && <Preloader />}
				{horizontalMenu.map((menu, index) => (
					<HorizontalScroll
						menu={menu}
						menuName={menu.name}
						key={index}
						id={menu.id}
						setMenuEvent={setMenuEvent}
					/>
				))}
			</div>
			<div>
				{Object.keys(activeFilterOption).length > 0 && (
					<ProductFilters
						selected={selected}
						setSelected={setSelected}
						filterOptionCount={filterOptionCount}
						activeFilterOption={activeFilterOption}
					/>
				)}
			</div>

			<div className="topReuse">
				{products.length > 0
					? products
							.slice(0, visibleProducts)
							.map((product, index) => {
									if (!filterIsFound(selected, product.filteroptionids)) return null;
									return (
										<div
											onMouseLeave={() => setShowBack({ id: 0 })}
											onMouseEnter={() => setShowBack({ id: product.product_id })}
											className="mainReuse"
											key={index}
										>
											{showBack.id !== product.product_id ? (
												<img
													src={product.mainpic}
													alt="hianga Image1"
													id={`img-${product.product_id}`}
												/>
											) : (
												<img
													src={product.backpic ?? product.mainpic}
													alt="hianga Image2"													
													id={`img-${product.product_id}`}
												/>
											)}
											<Link to={"/product/" + product.product_id} className="overlay">
												<h1>SELLING FAST</h1>
											</Link>
											<motion.div className="bgMatch">
												<h5>
													Mix & Match
													<BiHeart
														color="black"
														size={26}
														className="heartProduct active"
														onClick={() => saveItem(product, setSavedItems, aler)} // Pass the product object as an argument
													/>
												</h5>
											</motion.div>
											<small>{product.title}</small>
											<h4> {product.discount!=null && product.discount > 0 && <del style={{color:"maroon"}}>${product.price }</del>}
											&nbsp; 
											${itemPriceDiscountCalculator(product)}</h4>
										</div>
									);
								} 
							)
					: !preloader && <h1>No product found</h1>}

				{/* Show the "Load More" button if there are more products to load */}
				{visibleProducts < products.length && (
					<button className="loadmore" onClick={handleLoadMore}>
						Load More
						<iconify-icon icon="mdi:arrow-down-right" width="20"></iconify-icon>
					</button>
				)}
			</div>
			<Banner />
			<Socials />
			<Footer />
		</>
	);
}
