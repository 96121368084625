import React from "react";
import { BsArrowBarRight, BsForward } from "react-icons/bs";
import { NavLink } from "react-router-dom";

export default function CheckOutNav() {
	return (
		<nav className="checkout__address--header-crumbs">
			<NavLink
				exact
				to={"/cart"}
				activeClassName="active-crumb"
				className="checkout__address--header-crumb"
			>
				cart
				<BsArrowBarRight size={15} color="black" />
			</NavLink>
			<NavLink
				exact
				to={"/checkout"}
				activeClassName="active-crumb"
				className="checkout__address--header-crumb"
			>
				Shipping
				<BsArrowBarRight size={15} color="black" />
			</NavLink>
			<NavLink
				exact
				to={"/checkout/payment"}
				activeClassName="active-crumb"
				className="checkout__address--header-crumb"
			>
				Delivery/Payment
				<BsArrowBarRight size={15} color="black" />
			</NavLink>
			<NavLink
				exact
				to={"/checkout/summary"}
				activeClassName="active-crumb"
				className="checkout__address--header-crumb"
			>
				Summary
			</NavLink>
		</nav>
	);
}
