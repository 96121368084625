import { useState, useEffect } from "react";
import Footer from "../../components/Footer";
import Socials from "../../components/women/Socials";
import { post } from "../../config/ajaxRequest";
import { getItem } from "../../config/config";
import "../../asset/css/lubem.css";
import aler from "../../utils/aler";

export default function AccountDetails() {
  
  const [user, setUser] = useState({})  
  const [password, setPassword] = useState({newpassword:null, confirm:null}) 
  const [message, setMessage] = useState({msg:"", where:""}) 

  useEffect(() => {
    (async()=>{
      const u =await getItem("user") 
      const u2 = {...u, edited:false}
      setUser(u2)
    })()
  }, []);


  const newPasswordValidation=(setMsg=1)=>{
    if(setMsg===1)setMessage({...message, msg:""})
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9_]{8,}$/;
    //console.log(passwordRegex.test(password.newpassword), "passwordRegex.test(password.newpassword)")

    if(!password.newpassword || password.newpassword.length< 8) return setMessage({msg:"Password should be atleast 8 characters", where:"new"})
    if (!passwordRegex.test(password.newpassword)) return setMessage({msg:"Password should have atleast a Captial leter, small leter, number. No SPECIAL CHARACTERS ARE ALLOWED", where:"new"})
  }

  const confirmPasswordValidation=(setMsg=1)=>{
    if(setMsg===1)setMessage({...message, msg:""})
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9_]{8,}$/;
    if(!password.confirm || password.confirm.length< 8)setMessage({msg:"Confirm Password should be atleast 8 characters", where:"confirm"})
    if (!passwordRegex.test(password.confirm)) return setMessage({msg:"Password should have atleast a Captial leter, small leter, number. No SPECIAL CHARACTERS ARE ALLOWED", where:"confirm"}) 
    if(password.newpassword !== password.confirm )setMessage({msg:"Password and Confirm password should match", where:"confirm"})   
  }

  const enterPass = () =>{
    setMessage({msg:"", color:"red"})
    newPasswordValidation(0)
    confirmPasswordValidation(0)

    return message.msg.length < 1 
  }


  const submitToServer = async() =>{  
    const keys = ["fname", "lname", "name"]

    let validated = true
    keys.forEach(res=>{
      if(!user[res] && user[res].length<1) validated=false
    });

    if(validated && user.edited){
      const res = await post("updateuserbiodata", user)
      if(res.indexOf("Successfully") >-1 )aler(res, "success")
      if(res.indexOf("Successfully") < 0)aler(res) 
    } 

    if(enterPass()){
      const res =   await post("changepassword", {password:password.password, newpassword:password.newpassword})
      setMessage({msg:res, where:"pass"})
    }
    
  }


  return (
    
    <section className="account__page address">
      <form className="multi__form">
        <section className="input__group">
          <section className="form__input">
            <label htmlFor="lname">First name *</label>
            <input type="text" name="fname" id="fname" value={user.fname} onChange={(e)=>setUser({...user, fname:e.target.value, edited:true})} />
          </section>
        </section>

        <section className="input__group">
          <section className="form__input">
            <label htmlFor="lname">Last name *</label>
            <input type="text" name="lname" id="lname"  value={user.lname} onChange={(e)=>setUser({...user, lname:e.target.value, edited:true})} />
          </section>
        </section>

        <section className="input__group">
          <section className="form__input">
            <label htmlFor="lname">Display name *</label>
            <input type="text" name="name" id="name"  value={user.name} onChange={(e)=>setUser({...user, name:e.target.value, edited:true})} />
          </section>
        </section>

        <section className="input__group">
          <section className="form__input">
            <label htmlFor="lname">Email address *</label>
            <input type="email" name="email" id="email"  value={user.email} readOnly/>
          </section>
        </section>

        <section className="input__group">
          <section className="form__input">
            <label htmlFor="lname">
              Current Password(leave blank to leave unchanged)
            </label>
            <input type="password" name="password" id="password"  value={password.password} onChange={(e)=>setPassword({...password, password:e.target.value})}/>
            {message.where==="pass" &&<span className="text-warning mb-3">{message.msg}</span>}
          </section>
        </section>

        <section className="input__group">
          <section className="form__input">
            <label htmlFor="lname">
              New Password(leave blank to leave unchanged)
            </label>
            <input type="password" name="newpassword" id="newpassword"  value={password.newpassword} onChange={(e)=>setPassword({...password, newpassword:e.target.value})}  onKeyUp={()=>newPasswordValidation(1)} />
            {message.where==="new" &&<span className="text-danger mb-3">{message.msg}</span>}
          </section>
        </section>

        <section className="input__group">
          <section className="form__input">
            <label htmlFor="lname">Confirm new password</label>
            <input type="password" name="confirm" id="confirm"  value={password.confirm} onChange={(e)=>setPassword({...password, confirm:e.target.value})}  onKeyUp={()=>confirmPasswordValidation(1)} />
            {message.where==="confirm" &&<span className="text-danger mb-3">{message.msg}</span>}
          </section>
        </section>

        <button  type="button" className="joinButton active" onClick={submitToServer}>
            Save Changes
        </button>
        
      </form>
      <Socials/>
      <Footer/>
    </section>
  );
  } 
