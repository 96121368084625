import { Link, useHistory } from "react-router-dom";
import { checkUserLogin } from "../../utils/auth";
import { useEffect, useState } from "react";
import Images from "../../config/Images";
import DeliveryMethodComponent from "../../components/checkout/DeliveryMethodComponent ";
import PaymentMethodComponent from "../../components/checkout/PaymentMethodComponent";
import InformationSummary from "../../components/checkout/InformationSummary";
import MerchantSummary from "../../components/checkout/MerchantSummary";
import DeliverySummary from "../../components/checkout/DeliverySummary";
import "../../asset/css/PaymentMethodComponent.css";
import "../../asset/css/DeliveryMethodComponent.css";

import { FaCreditCard, FaMoneyBill, FaPaypal } from "react-icons/fa";
import CheckOutButton from "../../components/checkout/CheckoutButtons";
import { setItem } from "../../config/config";
import aler from "../../utils/aler";


export default function CheckOutPayment({ selectedValues, setSelectedValues, mercentprices, setMerchantPrice }) {
	const history = useHistory();

	const updateSelectedValue = (key, value) => {
		setSelectedValues((prevState) => ({
			...prevState,
			[key]: value,
		}));

		if(key !== "merchant" || isNaN(value.price))return; 
		setMerchantPrice(value.price, "before delivery event")
		const event = new CustomEvent("delivery", {
			bubbles: true,
			detail: { data: value },
		});

		document.dispatchEvent(event);
	};

	useEffect(() => {
		(async () => {
			const res = await checkUserLogin();
			//console.log(res, "retrurn value");
			if (res) history.push(res);
		})();
	}, []);

	return (
		<>
			<section className="checkout__payment">
				<ul className="checkout__shipping--list checkout__shipping--detail-one">
					{/* merchant section */}
					<li>
						<div>
							<h4><input type="text" readOnly style={{paddingLeft:"30%", border:"none", fontSize:"2rem"}} value={"Select Merchant"} autoFocus/></h4>
							
							<div className="myPay">
								<Link
									to="#"
									className="payLink"
									onClick={() =>{
										updateSelectedValue("merchant", {
											id: 2,
											name: "Dhl",
											price: "dhl" in mercentprices ? mercentprices["dhl"] : null,
										});  
										}

									}
								>
									<img className={selectedValues?.merchant?.name === "Dhl" ? "active":""} src={Images.p2} alt="Dhl" />
									<span className="partnerid">Dhl </span>
								</Link>
								<Link
									to="#"
									className="payLink"
									onClick={() => aler("Coming Soon", "success")
										// updateSelectedValue("merchant", {
										// 	id: 1,
										// 	name: "Aramark",
										// 	price: 30,
										// })
									}
								>
									<img src={Images.p1} alt="Aramark" className={selectedValues?.merchant?.name === "Aramark" ? "active":"paydisabled"} />
									<span className="partnerid">Aramark</span>
								</Link>
								<Link
									to="#"
									className="payLink"
									onClick={() => aler("Coming Soon", "success")
										// updateSelectedValue("merchant", {
										// 	id: 3,
										// 	name: "Ups",
										// 	price: 20,
										// })
									}
								>
									<img src={Images.p4} alt="Ups" className={selectedValues?.merchant?.name === "Ups" ? "active":"paydisabled"} />
									<span className="partnerid">Ups</span>
								</Link>
								<Link
									to="#"
									className="payLink"
									onClick={() => aler("Coming Soon", "success")
										// updateSelectedValue("merchant", {
										// 	id: 4,
										// 	name: "Dispatch",
										// 	price: 10,
										// })
									}
								>
									<img src={Images.p3} alt="Dispatch" className={selectedValues?.merchant?.name === "Dispatch" ? "active":"paydisabled"} />
									<span className="partnerid">Dispatch</span>
								</Link>
							</div>

							{selectedValues.merchant && (
								<div>
									<h5
										style={{
											backgroundColor: "#100840",
											color: "#fff!important",
											padding: "1.5rem 0",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<span
											style={{
												fontVariant: "small-caps",
												fontSize: "30px",
												color: "white",
												display: "block",
												padding: "0 15px",
											}}
										>
											{selectedValues.merchant.name}
										</span>
									</h5>
								</div>
							)}
						</div>
					</li>
					<li>
						{/* payment mode section */}
						<div className="payment-method-container">
							<h2>Select a Payment Method</h2>
							<div className="payment-method-container-inner">
								<div className="payment-method-label">
									<input
										type="radio"
										value="paypal"
										checked={selectedValues.payMethod === "paypal"}
										onChange={() => updateSelectedValue("payMethod", "paypal")}
										className="payment-method-input payee"
									/>
									<img
										src={Images.pay5}
										className={selectedValues.payMethod === "paypal" ? "active":""}
										onClick={() => updateSelectedValue("payMethod", "paypal")
										}
										alt="paypal"
									/>
								</div>
								<div className="payment-method-label">
									<input
										type="radio"
										value="paystack"
										checked={selectedValues.payMethod === "paystack"}
										onChange={() => updateSelectedValue("payMethod", "paystack")}
										className="payment-method-input paystack"
									/>
									<img
										src={Images.pay8}
										className={selectedValues.payMethod === "paystack" ? "active":""}
										onClick={() => updateSelectedValue("payMethod", "paystack")
										}
										alt="paystack"
									/>
								</div>
								<div className="payment-method-label">
									<input
										type="radio"
										value="apple pay"
										name="payMethod"
										checked={selectedValues.payMethod === "apple pay"}
										onChange={() =>
											updateSelectedValue("payMethod", "apple pay")
										}
										className="payment-method-input payee paydisabled"
									/>
									<img
										src={Images.pay1}
										className={selectedValues.payMethod === "apple pay" ? "active":"paydisabled"}
										onClick={() => aler("Coming Soon", "success")
											// updateSelectedValue("payMethod", "apple pay")
										}
										alt="apple pay" 
									/>
								</div>
								<div className="payment-method-label">
									<input
										type="radio"
										value="google pay"
										checked={selectedValues.payMethod === "google pay"}
										onChange={() =>
											updateSelectedValue("payMethod", "google pay")
										}
										className="payment-method-input payee"
									/>
									<img
										src={Images.pay3}
										className={selectedValues.payMethod === "google pay" ? "active":"paydisabled"}
										onClick={() => aler("Coming Soon", "success")
											// updateSelectedValue("payMethod", "google pay")
										}
										alt="google pay" 
									/>
								</div>
								<div className="payment-method-label">
									<input
										type="radio"
										value="stripe"
										checked={selectedValues.payMethod === "stripe"}
										onChange={() => updateSelectedValue("payMethod", "stripe")}
										className="payment-method-input payee"
									/>
									<img
										src={Images.pay6}
										className={selectedValues.payMethod === "stripe" ? "active":"paydisabled"}
										onClick={() => aler("Coming Soon", "success")
											// updateSelectedValue("payMethod", "stripe")
										}
										alt="stripe" 
									/>
								</div>

								{/* 2 */}
								<div className="payment-method-label">
									<input
										type="radio"
										value="dpo"
										name="payMethod"
										checked={selectedValues.payMethod === "DPO Group"}
										onChange={() =>
											updateSelectedValue("payMethod", "DPO Group")
										}
										className="payment-method-input payee"
									/>
									<img
										src={Images.pay2}
										className={selectedValues.payMethod === "DPO Group" ? "active":"paydisabled"}
										onClick={() => aler("Coming Soon", "success")
											// updateSelectedValue("payMethod", "DPO Group")
										}
										alt="DPO Group" 
									/>
								</div>
								<div className="payment-method-label">
									<input
										type="radio"
										value="payoneer"
										checked={selectedValues.payMethod === "payoneer"}
										onChange={() =>
											updateSelectedValue("payMethod", "payoneer")
										}
										className="payment-method-input payee"
									/>
									<img
										src={Images.pay4}
										className={selectedValues.payMethod === "payoneer" ? "active":"paydisabled"}
										onClick={() => aler("Coming Soon", "success")
											// updateSelectedValue("payMethod", "payoneer")
										}
										alt="payoneer" 
									/>
								</div>

								<div className="payment-method-label">
									<input
										type="radio"
										value="stripe"
										checked={selectedValues.payMethod === "payu"}
										onChange={() => aler("Coming Soon", "success")
											// updateSelectedValue("payMethod", "payu")
										}
										className="payment-method-input payee"
									/>
									<img
										src={Images.pay7}
										className={selectedValues.payMethod === "payu" ? "active":"paydisabled"}
										onClick={() => aler("Coming Soon", "success")
											// updateSelectedValue("payMethod", "payu")
										}
										alt="payu" 
									/>
								</div>
							</div>
							<div>
								<p className="selected-method">
									{/* Selected Payment Method: */}
									<h5 className="selectedMethod selMed">
										{selectedValues.payMethod}
									</h5>
								</p>
							</div>
						</div>
					</li>
				</ul>

				<div className="checkout__address--footer">
					{/* <button>PLACE ORDER</button> */}
				</div>
			</section>
		</>
	);
}
