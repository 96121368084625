import React, {useState, useEffect} from "react";
import { LoginSocialGoogle, LoginSocialFacebook} from 'reactjs-social-login'
import {FacebookLoginButton, GoogleLoginButton} from 'react-social-login-buttons'

import "../../styles/checkout.css";
import Images from "../../config/Images";
import { useHistory } from "react-router-dom";
import { setItem, getItem } from "../../config/config";
import { post } from "../../config/ajaxRequest";
import aler from '../../utils/aler'

/**
 import signInwithFacebook from ''
 import signInwithGoogle from  ''
 */

    const signInwithFacebook = async() =>{aler("Signing in with facebook")}
    const signInwithGoogle = async() =>{aler("Signing in with google")}

export default function WelcomeBack() {
    const [loginType, setLoginType] = useState("")
    const [user, setUser] = useState({})
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)

    const history = useHistory()

    const [worked, setWorked] = useState(false)

    const startLogin = ()=>{ 
      if(setLoading)setLoading(true);
      //console.log("Starting login");
      
      setTimeout(() => {
        if(!worked){
          setLoading(false);
          // aler("Could not authenticate")
        }
      }, 50000);
    }
  
    const handlePayload = async(obj, type) =>{
      let data = {}
      if(type === "google"){
        data ={fname:obj.given_name, lname:obj.family_name, type:type,
        name:obj.name, email:obj.email, access_token:obj.access_token}
      }
  
      if(type === "facebook"){
        data ={fname:obj.first_name, lname:obj.last_name, type:type,
        name:obj.name, email:obj.email, access_token:obj.accessToken}
      }
   
      try{ 
        const res = await post("oauth", data)
        if(res?.user){
          setItem("user", res.user)
          setItem("token", res.token) 
          
        if(window.location.href.indexOf("?proceed=checkout") > -1) return window.location.reload()
        if(res.user.usertype === "admin") return window.location.reload()
  
        return window.location.reload()
      }      
      aler(res)
        
      }catch(err){
  
      }
    }
  
    const submit = async()=>{
        let res=null
        if(loginType=="google") res = await signInwithGoogle()
        if(loginType=="facebook") res = await signInwithFacebook()
        if(loginType=="normal"){
            const obj = {email:user.email, password:password}
            res = await post("login", obj)
        }

        if(res && res.token){
          //console.log()
          setItem("token", res.token)          
                // storeuserInStorage(res)
            // history.back()
            return        
        }
        history.push("/login`")
    }

    useEffect(() => {
        
        (async()=>{
            const u = await getItem("user")
            setUser(u)
            setLoginType(u.user_type)
            if(!u.email) return history.push("/signIn")
            if(u.email && u.token) localStorage.removeItem("token")
        })()          
        
    }, []);


  return (
    <div className="welcomeBackCheckout">
    <div className="innWelcome">
    <h1>
    Hianga <small></small>
      </h1>
      <div className="innerFold">
        <h2>Welcome Back, {user.fname}</h2>
        {loginType=="facebook" && <> 
        {/* <p onClick={submit}>Sign in again with Facebook to continue</p><img src={Images.Facebook} alt="Hianga Faceebook" /> */}
               
          <LoginSocialFacebook
            appId="appId"
            onLoginStart={startLogin}
            onResolve={(response) => {
              //console.log(response);
              handlePayload(response, "facebook")
              if(setLoading)setLoading(false); 
            }}
            onReject={(error) => {
              //console.log(error);
              if(setLoading)setLoading(false);
            }}
            scope="email,public_profile"
          >
            <FacebookLoginButton />
            {/* <button>Facebook Login</button> */}
          </LoginSocialFacebook>
          
        </>}
        {loginType=="google" && 
        // <p onClick={submit}>Sign in again with Google to continue</p>
          
          <LoginSocialGoogle
            client_id="99181804016-ve30jhpdus5jjdeelp5p521bc86si20d.apps.googleusercontent.com"
            onLoginStart={startLogin}
            onResolve={({ provider, data }) => {
              //console.log(provider, data)
              handlePayload(data, "facebook")
              if(setLoading)setLoading(false);
              setWorked(true)            
            }}
            onReject={(err) => {
              //console.log(err);
              if(setLoading)setLoading(false);
            }}
            scope="https://www.googleapis.com/auth/userinfo.email"
        >
          <GoogleLoginButton />
        </LoginSocialGoogle>
        }
        {
        loginType=="normal" && 
            <>
                <p>Confirm your authentication by entering your password</p>
                <input type="password" onchange={setPassword}/>
                <button  onClick={submit}>Submit</button>
            </>
        }
        <h3>NOT YOU?</h3>
        <small>
          Shopping on our shared device? Sorry, You will lose the items you just{" "}
          <br /> added to the bag when you sign into a different account
        </small>
      </div>
    </div>
    </div>
  );
}