import React, {useState} from "react";
import MerchantSummary from "./MerchantSummary";
import {
	FaBusinessTime,
	FaEnvelope,
	FaFlag,
	FaLocationArrow,
	FaMagnet,
	FaMarker,
	FaPhone,
	FaUser,
} from "react-icons/fa";
// import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom";

export default function InformationSummary({
	user,
	shipAddress, 
	selectedMerchant,
}) {
	const [showAdd, setShowAdd] = useState(false);
	return (
		<>
			<div className="information-summary">
				<h2>Billing Address</h2>
				<p>
					<strong>First Name:</strong> {user && user.fname} <FaUser size={20} />
				</p>
				<p>
					<strong>Last Name:</strong> {user && user.lname} <FaUser size={20} />
				</p>
				<p>
					<strong>Company:</strong> {user && (user.company || "N/A")}{" "}
					<FaBusinessTime size={20} />
				</p>
				<p>
					<strong>Country:</strong> {user && user.country} <FaFlag size={20} />
				</p>
				<p>
					<strong>Street Address:</strong> {user && user.street}{" "}
					<FaMarker size={20} />
				</p>
				<p>
					<strong>City:</strong> {user && user.city}{" "}
					<FaLocationArrow size={20} />
				</p>
				<p>
					<strong>State:</strong> {user && user.state} <FaMagnet size={20} />
				</p>
				<p>
					<strong>Phone:</strong> {user && user.phone} <FaPhone size={20} />
				</p>
				<p>
					<strong>Email:</strong> {user && user.email} <FaEnvelope size={20} />
				</p>

				{showAdd && shipAddress && (
					<div className="shipping-summary">
						<h2>Shipping Address</h2>
						<p>
							<strong>First Name:</strong> {shipAddress.fname}
						</p>
						<p>
							<strong>Last Name:</strong> {shipAddress.lname}
						</p>
						<p>
							<strong>Company:</strong> {shipAddress.company || "N/A"}
						</p>
						<p>
							<strong>Country:</strong> {shipAddress.country}
						</p>
						<p>
							<strong>Street Address:</strong> {shipAddress.street}
						</p>
						<p>
							<strong>City:</strong> {shipAddress.city}
						</p>
						<p>
							<strong>State:</strong> {shipAddress.state}
						</p>
						<p>
							<strong>Phone:</strong> {shipAddress.phone}
						</p>
						<p>
							<strong>Email:</strong> {shipAddress.email}
						</p>
					</div>
				)}
			</div>
			{/* <Link to="#" className="checkout__address--links">
				PROCESS MY ORDER
			</Link> */}
		</>
	);
}
