import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer";
import Socials from "../../components/women/Socials";
import { FaCopy } from "react-icons/fa";
import "../../styles/voucher.css";
export default function GiftVouchers() {
	const [copied, setCopied] = useState(false);
	const [code, setCode] = useState("")

	const handleCopyClick = () => { 
		navigator.clipboard
			.writeText(code)
			.then(() => setCopied(true))
			.catch((err) => console.error("Could not copy text: ", err));
	};

	useEffect(() => {
		(()=>{
			// get voucher from server and setCode to it
		})()
	}, []);
	return (
		<section className="account__page orders">
			<h1>Vouchers</h1>
			<hr />
			<div className="container mt-5">
				<div className="card">
					<div className="card-body">
						<h5 className="card-title">Vouchers:</h5>
						<p className="card-text">{copied ? "Code Copied!" : code ? code:"No Code"}</p>
						{code.length > 0 && <button className="copy-button" onClick={handleCopyClick}>
							Copy <FaCopy className="copy-icon" />
						</button>}
					</div>
				</div>
			</div>
			<br />
			<Socials />
			<Footer />
		</section>
	);
}
