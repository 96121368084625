function getText(value, event=false){  
    if(!event)return value
    
    return value.target.value  
}
export const validatePassword = (value, aler=null, event=false) => {
    let password = getText(value); 
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;
    if(aler!=null && !regex.test(password))aler("Password must be at least 6 characters long and include at least 1 uppercase, lowercase letter, and number")
    return regex.test(password) 
  }

  
export function validateEmail(value, aler=null, event=false){
    let email = getText(value);   
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; 
        if(aler!=null && !regex.test(email))aler("Please Enter A Valid Email")
        return regex.test(email) 
	}
