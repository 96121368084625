import { useState } from "react";
import { Link, useHistory } from "react-router-dom"; 

import "../../styles/auth.css";

import SocialMedia from "../../components/auth/SocialMedia";
import AuthLocation from "../../components/auth/AuthLocation";
import { get, post } from "../../config/ajaxRequest";
import aler from "../../utils/aler";
import Preloader from "../../config/Preloader";
import { validateEmail, validatePassword } from "../../utils/validation";

function ForgetPassword() {
	const [loading, setLoading] = useState(false)
	const [Data, setData] = useState({email: ""});
	const history = useHistory();

	const onChange = (event) => {
		setData({...Data, [event.target.name]: event.target.value});
	};


	async function handleSubmit(event) {
		event.preventDefault();
		if(!validateEmail(Data.email)) return aler("Please enter a valid email address")

		try{
			setLoading(true)
			const res = await post("forgetpassword", Data)
			//console.log(res, "checking res in forgetPassword") 
			if(res.indexOf("Success") > -1){
				get("execute/job")
				aler("Check Your Mail For Your Password Reset Code", "success")
				return history.push("/resetpassword?email="+Data.email);
			}
			
			aler(res)
		}catch(err){
			aler("An Error Occured! Please your network")
		}
		setLoading(false)
	}

	return (
		<main>
			<h1>Forget Password</h1>

			<section className="auth-container">
				<AuthLocation tab={2} />

				{/* Sign In Form */}
				{loading ?
					<Preloader/>
					: 
					<form className="container" onSubmit={handleSubmit}>
						{/* Email Address */}
						<section className="form-group">
							<label htmlFor="id" className="form-group__label">
								Email Address:{" "}
							</label>
							<br />
							<input
								type="email"
								id="email"
								placeholder="Enter Your Email to Reset Password"
								name="email"
								value={Data.email}
								onChange={onChange}
							/>
						</section>

						<button className="forgetPassLink joinButton active" type="submit">
							Send Mail
						</button>
					</form>
				}
				<h1 className="social__header">OR SIGN IN WITH...</h1>
				<SocialMedia />
			</section>
		</main>
	);
}

export default ForgetPassword;
