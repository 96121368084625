 
// export const URL = "http://127.0.0.1:8000/api";
export const URL = "https://backend.hianga.com/public/api";
export const getItem = (key) => {
	const value = localStorage.getItem(key);
	try {
		if (key === "message")
			if (
				(value &&
					value.indexOf("{") > -1 &&
					value.indexOf(":") > -1 &&
					value.indexOf("}") > -1) ||
				(value.indexOf("[") > -1 && value.indexOf("]") > -1)
			)
				//console.log(key, value, typeof value, "inside getItem");
				return JSON.parse(value);
		return JSON.parse(value);
	} catch (err) {
		return value;
	}
};

export const setItem = (key, value) =>
	localStorage.setItem(key, JSON.stringify(value));

export const REACT_APP_FB_APP_ID = 1;
export const REACT_APP_GG_APP_ID = 1;
export const TABULARITEMSPERPAGE = 15;
