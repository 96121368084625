import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ButtonReUse from "./ButtonReuse";
import { get } from "../../../../config/ajaxRequest";

export default function Ads1() {
	// State to store the fetched data
	const [textData, setTextData] = useState([]); // Initialize as an empty array

	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await get("home/files");
				const myRes = res.textData; // Destructure specific properties
				setTextData(myRes); // Update state with individual properties
				//console.log(myRes, "text data here...");
			} catch (err) {
				console.error("Error fetching data:", err);
			}
		};

		fetchData();
	}, []);
	return (
		<div className="ads1">
			{/* Pass the fetched text1 data to the ButtonReUse component */}
			{textData.map((item, index) => (
				<div key={index} className="banner-item">
					<h4 className="small-text">{item.bigtext}</h4>
					{/* <h4 className="medium-text">{item.mediumtext}</h4> */}
					<small className="big-text">{item.smalltext}</small>
				</div>
			))}
		</div>
	);
}
