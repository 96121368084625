import React, {useContext} from "react";
import { Link, useHistory } from "react-router-dom";
import { BsHeart } from "react-icons/bs";
import { getItem } from "../../config/config";
import Preloader from "../../config/Preloader";
import aler from "../../utils/aler";
import { saveItem } from "../../utils/saveToCardAndWishList";
import { WishContext } from "../../contexts/WishContext";
import { itemPriceDiscountCalculator } from "../../utils/priceCaculator";

export default function RelatedProduct({ product, setLoad }) {
	const history = useHistory(); 
	const { savedItems, setSavedItems } = useContext(WishContext);
	const handleNavigation = () => {
		const newPath = `/product/${product.product_id}`;
		history.push(newPath);
		setLoad(product.product_id ?? product.id)
	  };
	return (
		<div className="reproducts__grid-item">
			<div
				className="reproducts__grid-item-content"
				// onClick={() =>
				// 	(window.location.href = `/product/${product.product_id}`)
				// }
			>
				<img onClick={handleNavigation} src={product.mainpic} alt="Image" />

				<div className="reproducts__grid-item--controls">
					<button onClick={()=>saveItem(product, setSavedItems, aler)}>
						<BsHeart size={15} className="heartProduct" />
					</button>
				</div>
			</div>

			<div className="reproducts__grid-item--details" onClick={handleNavigation}>
				<h4
					className="reproducts__grid-item--price"
					style={{ fontSize: "15px", fontWeight: "600", color: "black" }}
				> 
					{product.discount!=null && product.discount > 0 && <del style={{color:"maroon"}}>${parseFloat(product.price, 2)}</del>}
					&nbsp; 
					${itemPriceDiscountCalculator(product)}
				</h4>
				{/* <Link
					to={`/product/${product.product_id}`}
					style={{ textDecoration: "none" }}
				> */}
					<h3 style={{ fontSize: "15px" }}>{product.title}</h3>
				{/* </Link> */}
			</div>
			
		</div>
	);
}
