import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { get } from "../../config/ajaxRequest";
import Preloader from "../../config/Preloader";

export default function AddressCard({ type }) {
  const [address, setAddress] = useState({})
  const [loading, setLoading] = useState(true)
   
  useEffect(() => { 
    
    (async()=>{
      const addresses =await get("getaddress") ?? []
      addresses.forEach(element => {
        if(element.type === type){
          setAddress(element)
        }

        setLoading(false)
      }); 
    })()
  }, []);

  return (
    <section className="address__card">
      <section className="address__card--head">
        <h3>{type.charAt(0).toUpperCase() + type.slice(1)} Address</h3>
        <Link to={`/my-account/addresses/edit-address/${type.toLowerCase()}`} style={{color:"#5cb85c"}}>Edit</Link>
      </section>
      <section>
        {loading ? 
          <Preloader/>
          :
          <>
            {Object.keys(address).length >0 ? 
              <ul>
                <li>Full Name: {address.fname+""+address.lname}</li>
                <li>Street Address: {address.street}</li>
                <li>City: {address.city}</li>
                <li>State: {address.state}</li>
                <li>Country: {address.country}</li>
              </ul> : 
              <p>You have not set up this type of address yet.</p>
            }
          </>
        }
      </section>
    </section>
  );
}
