import React, { useEffect, useState } from "react";
import { get } from "../../config/ajaxRequest";

export default function BannerSmall({ text1, text2 }) {
  // State to store the fetched data
  const [textData, setTextData] = useState([]); // Initialize as an empty array

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await get("home/files");
        const myRes = res.textData; // Destructure specific properties
        setTextData(myRes); // Update state with individual properties
        //console.log(myRes, "text data here...");
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, []);

  // Assuming that textData is an array with objects containing bigtext and smalltext properties
  const bannerItems = textData.map((item, index) => (
    <div key={index} className="banner-item">
      {/* Assign bigtext to text1 and smalltext to text2 */}
      {index === 0 ? (
        <h4 className="small-text">{item.bigtext}</h4>
      ) : (
        <small className="big-text">{item.smalltext}</small>
      )}
    </div>
  ));

  return (
    <div className="p-5 text-white topBanner">
      <div className="innerbanner">{bannerItems}</div>
    </div>
  );
}
