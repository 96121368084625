import { useState } from "react";
import Order from "./Order";

export default function OrderList({ orders, setOrders }) { 
  return orders ? <AllOrders orders={orders} setOrders={setOrders} /> : <NoOrders />;

  function NoOrders() {
    return <p>No Order Placed</p>;
  }

  function AllOrders({orders}) { 
    return orders.map((order, index) => {
      let lastCartId = index > 0 ? orders[index-1].cartId : 0 
      if(order.cartId === lastCartId)return <div key={index}></div>
      
      return <Order key={index} order={order} orders={orders} setOrders={setOrders} />;
    });
  }
}
