import { post } from "../config/ajaxRequest";
import aler from "./aler";

export const submitToServer = async(type="paypal", token, cart, setCart, user, total, mercentprice, paymentFee) =>{
    const cartForServer = cart.map(res=>{ 
        return {product_id:res.id, quantity:res.quantity, details:res.selectedQuantities, cost:res.cost}
    }) 
    
  const serializedData = {
    carts:[...cartForServer] ,
      address:user?.id ?? 1,
      type:type,
      token:token,
      total:parseFloat(total,2) + parseFloat(mercentprice, 2) + parseFloat(paymentFee, 2),
      delivery_fee:parseFloat(mercentprice, 2),
      payment_fee: paymentFee
    }; 
    const response = await post("pay/order", serializedData)
    // console.log(response)

    if(response === "Payment Has Been Confirmed"){
      setCart([]) 
      return 1

    }
    aler(response)
    return 0
}
