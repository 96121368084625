import React from "react";
import Footer from "./Footer";

const TermsAndConditions = () => {
	return (
		<div className="container-fluid mt-5">
			<div className="container mt-5">
				<div className="accordion" id="termsAccordion">
					{/* Section 1 */}
					<div className="accordion-item" data-aos="fade-right">
						<h2 className="accordion-header" id="section1Header">
							<button 
								className="accordion-button"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#section1Collapse"
								aria-expanded="true"
								aria-controls="section1Collapse"
								style={{ backgroundColor: "#100840", color: "white" }}
							>
								Terms & Conditions
							</button>
						</h2>
						<div
							id="section1Collapse"
							className="accordion-collapse collapse  show"
							aria-labelledby="section1Header"
							data-bs-parent="#termsAccordion"
						>
							<div className="accordion-body">
								<p>
									Legally, we're The Hianga.com Limited. When you shop with us
									(or access our services, apps, and websites (our “Websites”)),
									these terms apply. They’re important for both of us as they
									set out what we expect from each other, and they also give you
									helpful info. You can also find out more on our Help pages
									(which also form part of these terms). Of course, if you need
									anything else, get in touch with customer care. We always love
									to hear from you!
								</p>
								<p>
									We keep these terms and help pages updated, and we amend them
									every so often, so remember to check back in before you shop,
									as the latest set will apply.
								</p>
							</div>
						</div>
					</div>

					{/* Section 2 */}
					<div className="accordion-item" data-aos="fade-left">
						<h2 className="accordion-header" id="section2Header">
							<button
								className="accordion-button"
								type="button"
								data-bs-toggle="collapse"
								style={{ backgroundColor: "#100840", color: "white" }}
								data-bs-target="#section2Collapse"
								aria-expanded="false"
								aria-controls="section2Collapse"
							>
								Legal right of cancellation within 14 days
							</button>
						</h2>
						<div
							id="section2Collapse"
							className="accordion-collapse collapse"
							aria-labelledby="section2Header"
							data-bs-parent="#termsAccordion"
						>
							<div className="accordion-body">
								<p>
									You may cancel your order within 14 days of receiving it, by
									filling in the cancellation form. Please send it back to us by
									e-mail at cancellation@hianga.com and return the products to
									us. You have 14 days (starting from the working day following
									receipt of your order) to cancel your order contract with
									Hianga so that we can offer you a full refund of the items
									purchased as well as the cost of delivery (in case you have
									paid for it). If you have paid a delivery fee and you return
									your entire order by our Returns Policy, we will refund the
									cost of a Standard delivery to your country, even if you have
									chosen a faster delivery. For example, if you paid $34 for 24H
									delivery but the cost of a Standard delivery is $5, then we
									will refund $5. If you only return part of your order to us,
									you will not receive a refund of any delivery costs. If you
									return an item that is incorrect or faulty, we will refund you
									the full cost of delivery and the returns charges paid by you.
									If you have already received your order, you will need to
									return the items you wish to cancel to us. Once you have
									informed us of your intention to cancel the order, we will
									explain the procedure to follow. Returned items must not have
									been worn or used and must be in their original condition,
									they will be inspected once received. We try to accept all
									returns. Returned items must keep the labels but the return
									packaging does not need to be the shipping one. However, it is
									your responsibility to ensure that returned items are properly
									packed so as not to be damaged during the return journey. If a
									returned item arrives in inadequate condition, we will return
									the item to you. In this case, we will not be able to proceed
									with the refund. If we do not receive your canceled order, we
									may arrange a recovery at your expense.
								</p>
							</div>
						</div>
					</div>

					{/* Section: About You */}
					<div className="accordion-item" data-aos="fade-right">
						<h2 className="accordion-header" id="aboutYouHeader">
							<button
								className="accordion-button"
								type="button"
								data-bs-toggle="collapse"
								style={{ backgroundColor: "#100840", color: "white" }}
								data-bs-target="#aboutYouCollapse"
								aria-expanded="true"
								aria-controls="aboutYouCollapse"
							>
								About You
							</button>
						</h2>
						<div
							id="aboutYouCollapse"
							className="accordion-collapse collapse"
							aria-labelledby="aboutYouHeader"
							data-bs-parent="#termsAccordion"
						>
							<div className="accordion-body">
								<ul>
									<li>Be at least 16 years old.</li>
									<li>
										Have a digital wallet, credit, or debit card that we accept
										(see bottom of the page for details of current payment
										providers).
									</li>
									<li>
										Be authorized to use that digital wallet, credit, or debit
										card (e.g., it is in your name, or you have permission to
										use it).
									</li>
								</ul>
							</div>
						</div>
					</div>

					{/* Section: Placing an Order */}
					<div className="accordion-item" data-aos="fade-left">
						<h2 className="accordion-header" id="placingOrderHeader">
							<button
								className="accordion-button"
								type="button"
								data-bs-toggle="collapse"
								style={{ backgroundColor: "#100840", color: "white" }}
								data-bs-target="#placingOrderCollapse"
								aria-expanded="false"
								aria-controls="placingOrderCollapse"
							>
								Placing an Order
							</button>
						</h2>
						<div
							id="placingOrderCollapse"
							className="accordion-collapse collapse"
							aria-labelledby="placingOrderHeader"
							data-bs-parent="#termsAccordion"
						>
							<div className="accordion-body">
								<p>
									When you place an order, you should receive an acknowledgment
									e-mail confirming receipt of your order. We then carry out a
									standard pre-authorization check to make sure there’s enough
									money on the card. We only accept your order once payment has
									been approved and we have debited the payment card or digital
									wallet (and then the contract is made based on these terms).
									You may be able to cancel (not change) your order within a
									short period of ordering – timings depend on your chosen
									delivery method (and will be set out in the acknowledgment
									email). You can’t change your order. You’ll need to cancel
									(and/or return original item(s)), and re-order. All orders are
									subject to availability and confirmation of the order price.
									Don’t worry, if there’s an issue with an order, we’ll get in
									touch with you. Very occasionally, we may need to refuse or
									cancel an order or close or freeze an account (even if we have
									previously confirmed your order) – e.g. if we notice something
									unusual on an order or an account or if your order goes
									against the unit limits as detailed on the product display
									pages of specific products. If your account has been blocked
									and you think we’ve made a mistake, please get in touch with
									Customer Care and they’ll be happy to speak to you about it.
								</p>
							</div>
						</div>
					</div>

					{/* Section: Prices and Product Descriptions */}
					<div className="accordion-item" data-aos="fade-right">
						<h2 className="accordion-header" id="pricesAndDescriptionsHeader">
							<button
								className="accordion-button"
								type="button"
								data-bs-toggle="collapse"
								style={{ backgroundColor: "#100840", color: "white" }}
								data-bs-target="#pricesAndDescriptionsCollapse"
								aria-expanded="true"
								aria-controls="pricesAndDescriptionsCollapse"
							>
								Prices and Product Descriptions
							</button>
						</h2>
						<div
							id="pricesAndDescriptionsCollapse"
							className="accordion-collapse collapse  "
							aria-labelledby="pricesAndDescriptionsHeader"
							data-bs-parent="#termsAccordion"
						>
							<div className="accordion-body">
								<p>
									From time to time we may price up or describe a product or
									promo wrongly. If we discover an error for any goods you’ve
									ordered, we will tell you ASAP and give you the option of
									reconfirming your order (at the correct price) or canceling
									it. If we can’t get in touch with you, we will treat the order
									as canceled. If you cancel and you’ve already paid, we will
									refund you in full. Prices include VAT (where applicable).
									Depending on the value of your order or the delivery option or
									address you choose, delivery costs may also be charged (see
									below). Such additional charges will be clearly shown during
									the checkout process and included in the 'Total Cost'.
								</p>
							</div>
						</div>
					</div>

					{/* Section: Delivery */}
					<div className="accordion-item" data-aos="fade-left">
						<h2 className="accordion-header" id="deliveryHeader">
							<button
								className="accordion-button"
								type="button"
								data-bs-toggle="collapse"
								style={{ backgroundColor: "#100840", color: "white" }}
								data-bs-target="#deliveryCollapse"
								aria-expanded="false"
								aria-controls="deliveryCollapse"
							>
								Delivery
							</button>
						</h2>
						<div
							id="deliveryCollapse"
							className="accordion-collapse collapse"
							aria-labelledby="deliveryHeader"
							data-bs-parent="#termsAccordion"
						>
							<div className="accordion-body">
								<p>
									Before you finalize your order, you’ll be given various
									delivery options to choose from with estimated delivery times
									and dates depending on the delivery address. We will always do
									our best to try to meet all delivery times but sometimes there
									may be delays – e.g. because of postal/carrier delays,
									logistics, or bad weather. We will keep you updated as much as
									we can and you should be able to track your parcel’s progress.
									Please check out our Delivery & Return page. Any problems with
									your delivery? Please let us know within 30 days of the date
									on which your order should have been delivered and we’ll do
									our best to help you.
								</p>
							</div>
						</div>
					</div>
					{/*  */}

					{/* Section: Returns and Refunds */}
					<div className="accordion-item" data-aos="fade-right">
						<h2 className="accordion-header" id="returnsRefundsHeader">
							<button
								className="accordion-button"
								type="button"
								data-bs-toggle="collapse"
								style={{ backgroundColor: "#100840", color: "white" }}
								data-bs-target="#returnsRefundsCollapse"
								aria-expanded="true"
								aria-controls="returnsRefundsCollapse"
							>
								Returns and Refunds
							</button>
						</h2>
						<div
							id="returnsRefundsCollapse"
							className="accordion-collapse collapse  "
							aria-labelledby="returnsRefundsHeader"
							data-bs-parent="#termsAccordion"
						>
							<div className="accordion-body">
								<p>
									Check out our returns and refund policy here. RETURNING AN
									UNWANTED ITEM? We get it, sometimes something just doesn’t
									work for you and you want your money back. Don’t worry, as
									long as an item is still in its original condition. You will
									have 28 days from the date your order is delivered to you or
									made available for collection, to return your item(s). As long
									as an item is still in its original condition, we accept
									returns free of charge up to 14 days after you receive them
									but after this period, you will be charged $4. Any returns
									that are sent back between 15-28 days will be subject to a
									charge.
								</p>
							</div>
						</div>
					</div>

					{/* Section: Your Information */}
					<div className="accordion-item" data-aos="fade-left">
						<h2 className="accordion-header" id="yourInformationHeader">
							<button
								className="accordion-button"
								type="button"
								data-bs-toggle="collapse"
								style={{ backgroundColor: "#100840", color: "white" }}
								data-bs-target="#yourInformationCollapse"
								aria-expanded="false"
								aria-controls="yourInformationCollapse"
							>
								Your Information
							</button>
						</h2>
						<div
							id="yourInformationCollapse"
							className="accordion-collapse collapse"
							aria-labelledby="yourInformationHeader"
							data-bs-parent="#termsAccordion"
						>
							<div className="accordion-body">
								<p>
									Our Privacy Policy sets out how we’ll use your information. If
									your details change, remember that you need to update My
									Account so we can continue giving you our best service (you
									can also update your marketing preferences there). We love
									being able to interact with and chat with you through social
									media. However, we can’t control those social media platforms
									or how you set your profiles on them. Please check and set
									your privacy settings so that you understand and are
									comfortable with how your personal information on those
									platforms will be used.
								</p>
							</div>
						</div>
					</div>

					{/* Section: Things You Shouldn't Do */}
					<div className="accordion-item" data-aos="fade-right">
						<h2 className="accordion-header" id="shouldNotDoHeader">
							<button
								className="accordion-button"
								type="button"
								data-bs-toggle="collapse"
								style={{ backgroundColor: "#100840", color: "white" }}
								data-bs-target="#shouldNotDoCollapse"
								aria-expanded="true"
								aria-controls="shouldNotDoCollapse"
							>
								Things You Shouldn't Do…
							</button>
						</h2>
						<div
							id="shouldNotDoCollapse"
							className="accordion-collapse collapse  "
							aria-labelledby="shouldNotDoHeader"
							data-bs-parent="#termsAccordion"
						>
							<div className="accordion-body">
								<p>
									We know it’s obvious, but you must not misuse or tamper with
									our websites, apps, or other services (e.g. hack, introduce
									viruses, trojans, worms, logic bombs, or other technologically
									harmful material or carry out denial of service attacks, etc)
									or otherwise mess with our tech or functionality or steal our
									or our customers’ data. Doing any of these things may be a
									criminal offense, but they also get in the way of us giving
									our loyal customers the best service, so we take them
									seriously. We will report any such breach or activity (and all
									information about the people carrying it out) to the relevant
									law enforcement authorities. We recommend you use virus
									protection software when using any website, including ours.
									Although we have a dedicated team who work hard to stop people
									from messing with our Website, we can’t guarantee that it will
									always be secure from bugs, viruses, or trouble-makers. Also,
									you’re not allowed to use automated systems or software to
									screen scrape our website. Troublemakers take note: you agree
									to indemnify, defend, and hold harmless Hianga, its directors,
									officers, employees, consultants, agents, and affiliates, from
									any third-party claims, liability, damages, and/or costs
									(including, but not limited to, legal fees) arising from your
									use (or misuse) of this Website or your breach of the Terms
									and Conditions.
								</p>
							</div>
						</div>
					</div>

					{/* Section: Intellectual Property, Software, and Content */}
					<div className="accordion-item" data-aos="fade-left">
						<h2 className="accordion-header" id="intellectualPropertyHeader">
							<button
								className="accordion-button"
								type="button"
								data-bs-toggle="collapse"
								style={{ backgroundColor: "#100840", color: "white" }}
								data-bs-target="#intellectualPropertyCollapse"
								aria-expanded="false"
								aria-controls="intellectualPropertyCollapse"
							>
								Intellectual Property, Software, and Content
							</button>
						</h2>
						<div
							id="intellectualPropertyCollapse"
							className="accordion-collapse collapse"
							aria-labelledby="intellectualPropertyHeader"
							data-bs-parent="#termsAccordion"
						>
							<div className="accordion-body">
								<p>
									Hianga owns or has permission to use the intellectual property
									rights in its website and its content. These rights are
									protected around the world. All such rights are reserved.
									You’re allowed to store, print, and display our website
									content only for your personal use. You are not allowed to use
									any part of the website for commercial purposes unless you
									have our express permission. You’re also not allowed to use
									the Hianga logo or any Hianga brand or trademark without our
									express permission.
								</p>
							</div>
						</div>
					</div>

					{/* Section: Linking to this Website */}
					<div className="accordion-item" data-aos="fade-right">
						<h2 className="accordion-header" id="linkingToWebsiteHeader">
							<button
								className="accordion-button"
								type="button"
								data-bs-toggle="collapse"
								style={{ backgroundColor: "#100840", color: "white" }}
								data-bs-target="#linkingToWebsiteCollapse"
								aria-expanded="true"
								aria-controls="linkingToWebsiteCollapse"
							>
								Linking to this Website
							</button>
						</h2>
						<div
							id="linkingToWebsiteCollapse"
							className="accordion-collapse collapse  "
							aria-labelledby="linkingToWebsiteHeader"
							data-bs-parent="#termsAccordion"
						>
							<div className="accordion-body">
								<p>
									It’s fine for you to link to hianga.com, as long as you do so
									in a way that is not commercial, is fair and legal, and
									doesn’t damage or take advantage of our reputation. Please
									don’t link in a way that suggests any form of approval or
									endorsement by Hianga where none exists. Our website must not
									be framed on any other site without our permission.
								</p>
							</div>
						</div>
					</div>

					{/* Section: Complaints or Need to Speak to Us */}
					<div className="accordion-item" data-aos="fade-left">
						<h2 className="accordion-header" id="complaintsHeader">
							<button
								className="accordion-button"
								type="button"
								data-bs-toggle="collapse"
								style={{ backgroundColor: "#100840", color: "white" }}
								data-bs-target="#complaintsCollapse"
								aria-expanded="false"
								aria-controls="complaintsCollapse"
							>
								Complaints or Need to Speak to Us?
							</button>
						</h2>
						<div
							id="complaintsCollapse"
							className="accordion-collapse collapse"
							aria-labelledby="complaintsHeader"
							data-bs-parent="#termsAccordion"
						>
							<div className="accordion-body">
								<p>
									Occasionally things do go wrong and you may want to get in
									touch with us. If that happens, please contact our Customer
									Care team, who will work with you to resolve any issues you’re
									having with our services: Send us an email at
									support@hianga.com.
								</p>
							</div>
						</div>
					</div>
					{/*  */}
					{/* Section: Legal Stuff */}
					<div className="accordion-item" data-aos="fade-right">
						<h2 className="accordion-header" id="legalStuffHeader">
							<button
								className="accordion-button"
								type="button"
								data-bs-toggle="collapse"
								style={{ backgroundColor: "#100840", color: "white" }}
								data-bs-target="#legalStuffCollapse"
								aria-expanded="true"
								aria-controls="legalStuffCollapse"
							>
								Legal Stuff
							</button>
						</h2>
						<div
							id="legalStuffCollapse"
							className="accordion-collapse collapse  "
							aria-labelledby="legalStuffHeader"
							data-bs-parent="#legalContentAccordion"
						>
							<div className="accordion-body">
								<p>
									We have the right to amend, remove, or vary our services
									and/or any part of the website (including our Ts&Cs) at any
									time.
								</p>
							</div>
						</div>
					</div>

					{/* Section: User Generated Content Ts&Cs */}
					<div className="accordion-item" data-aos="fade-left">
						<h2 className="accordion-header" id="userGeneratedContentHeader">
							<button
								className="accordion-button"
								type="button"
								data-bs-toggle="collapse"
								style={{ backgroundColor: "#100840", color: "white" }}
								data-bs-target="#userGeneratedContentCollapse"
								aria-expanded="false"
								aria-controls="userGeneratedContentCollapse"
							>
								User Generated Content Ts&Cs
							</button>
						</h2>
						<div
							id="userGeneratedContentCollapse"
							className="accordion-collapse collapse"
							aria-labelledby="userGeneratedContentHeader"
							data-bs-parent="#legalContentAccordion"
						>
							<div className="accordion-body">
								<p>
									Have we reached out to you on social media to feature your
									content on our social media channels? Congrats! It means we
									love your style.
								</p>
								<p>
									By allowing us to feature your content on our social media
									channels you agree:
								</p>
								<ul>
									<li>
										We can use your handle and the content on hianga.com, and
										Hianga social media platforms (TikTok, Instagram, Facebook,
										Snapchat, X, and YouTube).
									</li>
									<li>
										We can edit, crop, adapt, enhance, or modify the content.
										But Hianga will not treat you in a derogatory manner.
									</li>
								</ul>
								<p>You promise that you:</p>
								<ul>
									<li>Have the permission of everyone in the content.</li>
									<li>Have the right to grant Hianga the above rights; and</li>
									<li>Are at least 16.</li>
								</ul>
								<p>
									If you or anyone in the content asks us to remove the content,
									we will remove the content from the social media accounts that
									we control.
								</p>
								<p>
									You understand that other users of these social media
									platforms can also share and make use of the content once
									posted. In particular, a user of these platforms can take a
									screenshot of and save an image of the content to their
									device, and share the content on social media platforms or
									websites that feature the content and sharing capabilities. If
									you do not want to grant the permissions set out above then
									please do not give us consent to use the content.
								</p>
							</div>
						</div>
					</div>
					<br />
					<br />
					{/*  */}
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default TermsAndConditions;
