import { useState, useEffect } from "react";

import DashboardCard from "../../components/account/DashboardCard";
import { BsBox2, BsMap, BsSuitHeart, BsPeople, BsTicketPerforated } from "react-icons/bs";
import { TfiWrite, TfiPowerOff } from "react-icons/tfi";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Socials from "../../components/women/Socials";
import { getItem } from "../../config/config";
import { logOut } from "../../utils/auth";
import { get } from "../../config/ajaxRequest";

export default function Account() { 
  const [user, setUser] = useState({})
  const ICON_SIZE = 24;

  useEffect(() => {
    (async()=>{ 
      const u =await getItem("user")
      setUser(u)
    })()
  }, []);

  useEffect(()=>{
    (()=>{
      get("paymentsetup")
    })()
  },[])
  return (
    <section className="account__page">
      <section className="account__page--context">
        <p>
          Hello {user?.fname} (not {user?.fname}?{" "}
          <a href={"/my-account/logout"}>Log Out</a>)
        </p>
        <p>
          From your account dashboard you can view your recent orders, manage
          your shipping and billing addresses, and edit your password and
          account details.
        </p>
      </section>

      <section className="dashboard__cards">
        <DashboardCard
          path={"/my-account/orders"}
          icon={<BsBox2 size={ICON_SIZE} />}
          name={"Orders"}
        />
        <DashboardCard
          path={"/my-account/addresses"}
          icon={<BsMap size={ICON_SIZE} />}
          name={"Addresses"}
        />
        <DashboardCard
          path={"/my-account/details"}
          icon={<TfiWrite size={ICON_SIZE} />}
          name={"Account Details"}
        />

        <DashboardCard
          path={"/wishlist"}
          icon={<BsTicketPerforated size={ICON_SIZE} />}
          name={"Vouchers"}
        />

        <DashboardCard
          path={"/my-account/social-accounts"}
          icon={<BsPeople size={ICON_SIZE} />}
          name={"Social Accounts"}
        />

        <DashboardCard
          path={"/my-account/logout"}
          icon={<TfiPowerOff size={ICON_SIZE} />}
          name={"Logout"}
          logOut={logOut}
        />
      </section>
      <Socials/>
      <Footer/>
    </section>
  ); 
}
