import React, {useState} from "react";
import { LoginSocialGoogle, LoginSocialFacebook} from 'reactjs-social-login'
import {post} from '../../config/ajaxRequest'
// CUSTOMIZE ANY UI BUTTON
import {FacebookLoginButton, GoogleLoginButton} from 'react-social-login-buttons'
import { setItem } from "../../config/config";
import aler from '../../utils/aler'

function SocialMedia({ subTitle, setLoading }) {
  const [worked, setWorked] = useState(false)

  const startLogin = ()=>{
    //console.log("event") 
    if(setLoading)setLoading(true);
    //console.log("Starting login");
    
    setTimeout(() => {
      if(!worked){
        setLoading(false);
        // aler("Could not authenticate")
      }
    }, 50000);
  }

  const handlePayload = async(obj, type) =>{
    let data = {}
    if(type === "google"){
      data ={fname:obj.given_name, lname:obj.family_name, type:type,
      name:obj.name, email:obj.email, access_token:obj.access_token}
    }

    if(type === "facebook"){
      data ={fname:obj.first_name, lname:obj.last_name, type:type,
      name:obj.name, email:obj.email, access_token:obj.accessToken}
    }
 
    try{ 
      const res = await post("oauth", data)
      if(res?.user){
        setItem("user", res.user)
        setItem("token", res.token) 
        
      if(window.location.href.indexOf("?proceed=checkout") > -1) return window.location.reload()
      if(res.user.usertype === "admin") return window.location.reload()

      return window.location.reload()
    }      
    aler(res)
      
    }catch(err){

    }
  }

  return (
    <>
      <section className="social">
        <button className="social__button">          
          <LoginSocialGoogle
            client_id="99181804016-ve30jhpdus5jjdeelp5p521bc86si20d.apps.googleusercontent.com"
            onLoginStart={startLogin}
            onResolve={({ provider, data }) => {
              //console.log(provider, data)
              handlePayload(data, "facebook")
              if(setLoading)setLoading(false);
              setWorked(true)
              
            }}
            onReject={(err) => {
              //console.log(err);
              if(setLoading)setLoading(false);
            }}
            scope="https://www.googleapis.com/auth/userinfo.email"
          >
            <GoogleLoginButton />
            {/* <button>Google Login</button> */}
          </LoginSocialGoogle>
          {/* <img src="https://cdn.simpleicons.org/Google" alt="Google" />
          <span>Google</span> */}
        </button>
        <button className="social__button">
          
          <LoginSocialFacebook
            appId="appId"
            onLoginStart={startLogin}
            onResolve={(response) => {
              //console.log(response);
              handlePayload(response, "facebook")
              if(setLoading)setLoading(false); 
            }}
            onReject={(error) => {
              //console.log(error);
              if(setLoading)setLoading(false);
            }}
            scope="email,public_profile"
          >
            <FacebookLoginButton />
            {/* <button>Facebook Login</button> */}
          </LoginSocialFacebook>
          {/* <img src="https://cdn.simpleicons.org/Facebook" alt="Facebook" />
          <span>Facebook</span> */}
        </button>
      </section>
      <p className="social__text">{subTitle}</p>
    </>
  );
}

export default SocialMedia;
/*
  GOOGLE LOGIN PAYLOAD
  {
    "access_token": "ya29.a0AWY7Ckk3c2geLHh6AjSenCMQ4pKM9vVKVwh5tKfMbTaZmkthBv9qwIEnAyIj663hv6iaKvUvOw63tUQmR1fOpxi9IaS5O7SKM34FmCpHS2aLsozIXVcFTyCIwxC3hG3Bzt1LARitODJqEjendt8FBH3urY_7aCgYKAaESARESFQG1tDrpD4rfcnGBblfHSiQXoJ6tsw0163",
    "token_type": "Bearer",
    "expires_in": 3599,
    "scope": "email profile https://www.googleapis.com/auth/userinfo.email openid https://www.googleapis.com/auth/userinfo.profile",
    "authuser": "0",
    "prompt": "consent",
    "sub": "113368826190145574779",
    "name": "lubem Imoko",
    "given_name": "lubem",
    "family_name": "Imoko",
    "picture": "https://lh3.googleusercontent.com/a/AGNmyxZ2kh61g0s2cYYDEt9XMBbDpjlbWEE8wMIgBiOX=s96-c",
    "email": "lubemimoko@gmail.com",
    "email_verified": true,
    "locale": "en"
}

*/