import React, { useEffect, useState } from "react";
import "../../asset/css/swiperstyles.css";
import Footer from "../Footer";
import Banner from "./Banner";
import Card from "./Card";
import Grids from "./Grids";
import Socials from "./Socials";
import Swipper from "./Swipper";
import Trends from "./Trends";
import BackgroundVideo from "../backgroundVid/BackgroundVideo";
import { get } from "../../config/ajaxRequest";
import BannerSmall from "./BannerSmall";
import ButtonserverChange from "../Menu/ThirdMenu/Ads/ButtonserverChange";

export default function KidsScreen() {
	const [videoSource, setVideoSource] = useState(null);

	useEffect(() => {
		(async () => {
			try {
				const res = await get("home/files");
				// Assuming you want the first video in the array, you can access it like this:
				if (res.videoData.length > 0) {
					res.videoData.map((video) => {
						// console.log(video)
						if (video.title == "kids") setVideoSource(video.source);
					});
				}
			} catch (err) {
				// Handle errors here
			}
		})();
	}, []);

	return (
		<div className="topmost2">
			<ButtonserverChange />
			<Banner />
			{videoSource && (
				<BackgroundVideo
					src={videoSource}
					autoPlay={true}
					muted={true}
					loop={true}
				>
					{/* Your content goes here */}
				</BackgroundVideo>
			)}
			<Swipper />
			{/* <Banner /> */}
			<BannerSmall />
			<Grids />
			<Banner />
			<Card />
			<Banner />
			<Trends />
			{/* <RelatedProduct /> */}
			<Socials />
			<Footer />
		</div>
	);
}
