import React, { useContext, useEffect, useState } from "react";

import { CartContext } from "../../contexts/CartContext";
import "../../styles/checkout.css";
import {
	priceCalculator,
	itemPriceDiscountCalculator,
	calculateFee,
} from "../../utils/priceCaculator";
import { get } from "../../config/ajaxRequest";
import { getItem, setItem } from "../../config/config";

export default function CartPreview() {
	const { cart, setCart } = useContext(CartContext);
	const [coupon, setCoupon] = useState(false);
	const [subtotal, setSubtotal] = useState(0);
	const [shipmentCost, setShipmentCost] = useState(0);
	const [paymentFee, setPaymentFee] = useState(0);
	const [loadCoupon, setLoadCoupon] = useState(false)
	const [paymentMethod, setPaymentMethod] = useState("")
	const [couponValue, setCouponValue] = useState("")
	const [message, setMessage] = useState({})
	const [exchangeRate, setExchangeRate] = useState(1200)

	useEffect(() => {
		(async()=>{	
			try{
			  const res = await get("naira/usd")
			  res ? setExchangeRate(res): (()=>{})()
			}catch(err){}
		})()
	}, []);

	useEffect(() => {
		(async () => {
			let products = [];

			cart.forEach((res, index) => {
				products.push(res.id);
			});

			if (products.length > 0) {
				products = JSON.stringify(products);
				const productPrices = await get(
					"checkprices?products=" + `${products}`
				);
				if (Array.isArray(productPrices)) {
					let updateCart = false;
					let newCart = [...cart];
					productPrices.forEach((serverPrice) => {
						cart.forEach((cartPrice, index) => {
							if (serverPrice.id == cartPrice.id) {
								if (
									cartPrice.price != serverPrice.price ||
									cartPrice.discount != serverPrice.discount
								) {
									updateCart = true;
									newCart[index].price = serverPrice.price;
									newCart[index].discount = serverPrice.discount;
								}
							}
						});
					});

					//console.log(newCart, products, "newCart", "products");
					if (updateCart) setCart(newCart);
				}
			}
		})();
	}, []);

	useEffect(() => {
		let total = 0;
		cart.forEach((item) => {
			total += parseFloat(priceCalculator(item));
		});
		const amount = isNaN(total) ? 0:total;
		setSubtotal(amount)
	}, [cart]);


	useEffect(() => {
		document.addEventListener("delivery", function (e) { 
			const price = parseFloat(e.detail.data.price, 2) 
			if(price)setShipmentCost(price ?? 5);
		});
	}, [])
	
	useEffect(() => { 
	document.addEventListener("paymentfee", function (e) { 
			setPaymentMethod(e.detail.data) 
	});	
	}, []);

	useEffect(() => { 
		let price = 0
		const total = subtotal +  shipmentCost
		const func = calculateFee(paymentMethod)
		if(func)price = parseFloat(func(total, exchangeRate))
		if(price)setPaymentFee(price); 

	}, [paymentMethod, subtotal, shipmentCost]);
	
	
	const toggleCoupon = () => {
		setCoupon(!coupon);
		setLoadCoupon(false)
	};

	const applyCoupon = async() =>{
		setLoadCoupon(true)
		setMessage({msg:"Verifying Coupon...", color:"black"})
		// const coupon = getItem("coupon")
		// if(coupon){
		// 	const res =  get("get/coupon?coupon="+coupon)
		// 	setTimeout(() => {setMessage({msg:"Invalid Coupon", color:"red"})}, 1500);
		// }
		setTimeout(() => {setMessage({msg:"Invalid Coupon", color:"red"})}, 1500);
		// setTimeout(() => {setLoadCoupon(false)}, 2500);

	}

	const inputCoupon = (e) =>{
		const value = e.target.value
		setCouponValue(value)
		setItem("coupon", value) 
	}
	return (
		<section className="checkout__preview">
			<OrderPreview cart={cart} />
			<section className="checkout__preview--section checkout__preview--coupon">
				<button onClick={toggleCoupon}>
					<span>Have a coupon code ?</span>
				</button>
				{coupon ? (
					<div className="coupon">
						<input type="text" onBlur={inputCoupon} placeholder="Enter Coupon Code" />
						<button onClick={applyCoupon}>APPLY</button>
						{loadCoupon && <p style={{color:message.color}}>{message.msg}</p>}
					</div>
				) : (
					<></>
				)}
			</section>
			<section className="checkout__preview--section checkout__preview--prices">
				<h4>Subtotal</h4>
				<span>${subtotal}</span>
			</section>
			{shipmentCost!==null && shipmentCost > 0 &&<section className="checkout__preview--section checkout__preview--prices">
				<h4>Delivery Fee</h4>
				<span>${shipmentCost}</span>
			</section>
			}
			
			{paymentFee!==null && paymentFee > 0 &&<section className="checkout__preview--section checkout__preview--prices">
				<h4>Payment Gateway Charge</h4>
				<span>${paymentFee}</span>
			</section>
			}
			<section className="checkout__preview--section checkout__preview--prices">
				<h3>Total</h3>
				<span>${parseFloat(subtotal + shipmentCost + paymentFee).toFixed(2)}</span>
			</section>
		</section>
	);
}

function OrderPreview({ cart }) {
	return (
		<section className="checkout__preview--section">
			<h2>Your Item(s)</h2>

			<section className="checkout__table">
				<section className="checkout__table--head">
					<h3 style={{ fontVariant: "small-caps", fontSize: "20px" }}>
						Product
					</h3>
					<h3 style={{ fontVariant: "small-caps", fontSize: "20px" }}>
						Subtotal
					</h3>
				</section>
				{cart.map((item, index) => {
					return (
						<section key={index} className="checkout__table--list">
							<section style={{ maxWidth: "25%" }}>
								<section className="cart__item--image">
									<img src={item.mainpic} alt={item.name} />
								</section>
							</section>
							<span>{item.title}</span>
							<span>
								${itemPriceDiscountCalculator(item)} X {item.quantity ?? 1}
							</span>
						</section>
					);
				})}
			</section>
		</section>
	);
}
