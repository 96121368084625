import React, { useEffect, useState } from "react";
import { getItem } from "../../config/config";
import RelatedProduct from "./RelatedProduct";
import Footer from "../Footer";

export default function RelatedProducts({ productId, setLoad }) {
	const [products, setProducts] = useState([]);

	useEffect(() => {
		(async () => {
			if (!productId) return;
			const p = (await getItem("products")) ?? [];
			const p2 = p.filter((product) => {
				return product.product_id != productId;
			});
			setProducts(p2);
		})();
	}, [productId]);

	return (
		<section className="reproducts__grid">
			{products.length > 0 &&
				products.map((product, index) => {
					return (
						<RelatedProduct key={index} product={product} setLoad={setLoad} />
					);
				})}

			{products.length < 1 && (
				<>
					<h2></h2>
					<h3 style={{ textAlign: "center" }}>No Related Product Found!</h3>
					<h2></h2>
				</>
			)}
		</section>
	);
}
