import "../../styles/common.css";
export default function PageBanner({ pageName, children }) {
	return (
		<section className="page__banner">
			<section className="container page__banner--container">
				<h5 className="page__banner--name">{pageName}</h5>
				<span className="tempt" style={{ padding: "0 3rem" }}>
					Can we tempt you? Spend another £76.00 to qualify for FREE Standard
					Delivery to Nigeria.
				</span>
				<span className="page__banner--links">
					<a href="/">Home</a>

					{children}
				</span>
			</section>
		</section>
	);
}
