import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Mousewheel, Pagination, EffectFlip } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-flip";
import "../../asset/css/swiperstyles.css";
import { get } from "../../config/ajaxRequest";

export default function Swipper() {
	const [carouselData, setCarouselData] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await get("carousel/files");
				const myRes = res.carouselMenData; // Destructure specific properties
				if(Array.isArray(myRes))setCarouselData(myRes); // Update state with individual properties
				//console.log(myRes, "image data here...");
			} catch (err) {
				console.error("Error fetching data:", err);
			}
		};

		fetchData();
	}, []);

	return (
		<div>
			<Swiper
				direction={"horizontal"}
				slidesPerView={1}
				spaceBetween={300}
				mousewheel={false}
				pagination={{
					clickable: true,
				}}
				autoplay={{
					delay: 2000,
					disableOnInteraction: false,
				}}
				modules={[Autoplay, Mousewheel, Pagination, EffectFlip]}
				className="mySwiper"
				effect="flip"
			>
				{carouselData.map((item, index) => (
					<SwiperSlide key={index}>
						<img src={item.imageUrl} alt={`caro ${index + 1}`} />
						<div className="swiper-slide-overlay">
							<h2>{item.title}</h2>
							<p>{item.description}</p>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
}
