import React from "react";
import { Link } from "react-router-dom";
import "../asset/css/footer.css";
import Location from "./geolocation/Location";
import Images from "../config/Images";
export default function Footer() {
	return (
		<>
			<div className="mainFooter">
				<div className="row">
					<div
						className="d-flex justify-content-center align-items-center"
						style={{ height: "25vh" }}
					>
						<img
							src={Images.img44}
							style={{ width: "10%" }}
							alt="logo hianga"
						/>
					</div>
					<div className="col-4">
						<div className="holder">
							<h3 className="footerBold">HELP & INFORMATION</h3>
							<Link to="/help" className="help">
								<p>Help</p>
							</Link>
							<Link to="/terms" className="help">
								<p>delivery and returns</p>
							</Link>
							<Link to="/my-account/orders" className="help">
								<p>Track order</p>
							</Link>
						</div>
					</div>
					<div className="col-4">
						<div className="holder">
							<h3 className="footerBold">About Hianga</h3>
							<a href="https://about.hianga.com" target="_blank" className="help">
								<p>about</p>
							</a>
							<a href="https://corporate.hianga.com" target="_blank" className="help">
								<p>Coporate Responsibility</p>
							</a> 
							<a href="https://partner.hianga.com" target="_blank" className="help">
								<p>Partner With Hianga</p>
							</a>
						</div>
					</div>
					<div className="col-4">
						<div className="holder">
							<h3 className="footerBold">More From Hianga</h3>
							<Link to="/" className="help">
								<p>Mobile & Hianga Apps</p>
							</Link>
							<Link to="/my-account/gift-cards-and-vouchers" className="help">
								<p>Gift Voucher</p>
							</Link>
							{/* <a href="https://blackfriday.hianga.com" target="_blank">
								<p>Black Friday</p>
							</a> */}
							<a href="https://newyearsales.hianga.com/" target="_blank">
								<p>New Year Sales</p>
							</a>
							<a href="https://iiignacy.com" target="_blank">
								<p>Hianga Market</p>
							</a>
						</div>
					</div>
					{/* <div className="col-3">
						<div className="holder">
							<h3 className="footerBold">YOU ARE HERE</h3>
							<Location />
						</div>
					</div> */}
				</div>
			</div>
			<div className="ads2">
				<Link to="#" className="link">
					© 2023 Hianga
				</Link>
				<p>
					<Link to="/privacy" className="link">
						Privacy & Cookies
					</Link>
					<Link to="/terms" className="link">
						Ts&Cs
					</Link>
					<Link to="/touch" className="link">
						Accessibility
					</Link>
				</p>
			</div>
		</>
	);
}
