import React, { useState } from "react";
import { ChromePicker } from "react-color";

const ColorPicker = () => {
	// State to manage the selected color
	const [selectedColor, setSelectedColor] = useState("#000000");

	// Function to handle color change
	const handleColorChange = (color) => {
		setSelectedColor(color.hex);
	};

	return (
		<div>
			<ChromePicker color={selectedColor} onChange={handleColorChange} />

			<div style={{ marginTop: "20px" }}>
				<p>Selected Color: {selectedColor}</p>
				<div
					style={{
						width: "50px",
						height: "50px",
						backgroundColor: selectedColor,
					}}
				></div>
			</div>
		</div>
	);
};

export default ColorPicker;
