import { useState, useContext, useEffect } from "react";

import { WishContext } from "../contexts/WishContext";
import { CartContext } from "../contexts/CartContext";

import { BsTrash } from "react-icons/bs";
import { getItem } from "../config/config";
import aler from "../utils/aler";
import { get } from "../config/ajaxRequest";
import { getRandomNumber } from "../utils/saveToCardAndWishList";

export default function WishListItem({ item, cartQuantities }) {
	const { setSavedItems, savedItems } = useContext(WishContext);
	const { cart, setCart } = useContext(CartContext);
	const [quantities, setQuantities] = useState([]);
	const [properties, setProperties] = useState({});   
	const [productFilterFromServer, setProductFilterFromServer] = useState([]);
	const [productFilterQuantity, setProductFilterQuantity] = useState({});

	
	
	useEffect(() => {
		(async () => {  
			try {
				const p = await get("user/singleproduct/" + item.id); 
				if(p.product){
					const quantities = JSON.parse(p.product.quantity)
					let obj = {}
					quantities.forEach(res=>{
						obj[res.filteroptionids] = res.quantity
					})
					setProductFilterQuantity(obj)
					// console.log(obj, "filter obj")
				} 
			} catch (err) {}
 
		})();
	}, []);



	useEffect(() => { 
		(async () => { 		
			savedItems.map(res=>{ 
				if(res.id != item.id)return 
				if(!(res.id === item.id && res.index===item.index))return 
				if(res.selectedQuantities)setProperties(res.selectedQuantities) 
			})		 
 
		})();
	}, []);

	
	useEffect(() => { 
		(async () => { 		
			cartQuantities.map(res=>{ 
				// console.log(res, "res....")
				if(res.productId != item.id)return
				setQuantities(res.filter) 
				let newProperties = {}
				res.filter.forEach(element => {
					// console.log(element, properties, "element....")
					const value = element.id in properties ? properties[element.id] : null
					// console.log(value, properties[element.id], "value check")
					newProperties[element.id] = value
					const condition = element.id in newProperties 
					if(!condition)newProperties[element.id] = null
				});					
				setProperties(newProperties)
			})		 
 
		})();
	}, [cartQuantities]);
	
	useEffect(() => {
		(async () => { 
			try {				
				const response = await get("product/filter/quantity?productIds=" + item.id);
				response.map((res) => {
					if (res.productId == item.id) setProductFilterFromServer(res.filter);					 
				});
			} catch (err) {}
 
		})();
	}, []);


	const handleQuantities = (event, filterId) => {
		const value = event.target.value.length > 0 ? event.target.value:null ;
		if(productFilterQuantity[","+value+","] === 0 || productFilterQuantity[","+value+","] === "0")return aler("Out of stock")
		let newProperty = {...properties}
		newProperty[String(filterId)] = value
		setProperties(newProperty); 
	};

	const getDiscountPrice = () => {
		if (!item.discount || item.discount == 0) return item.price;

		return parseFloat(item.price - (item.discount / 100) * item.price).toFixed(2);
	};

	const bagItem = () => {
		const itemsInListFromLocalStorage = getItem("cart") ?? [];
		const foundProductInCartArr = itemsInListFromLocalStorage.filter(
			(itemStorage) => itemStorage.id === item.id
		);
		
		
		let matchingProperties = 0
		foundProductInCartArr.forEach((cartItem)=>{
			let found = 0
			Object.keys(cartItem.selectedQuantities).forEach((key) => { 
				if (cartItem.selectedQuantities[key] == properties[key] && properties[key]!=null) {
					found += 1 
				}				 
			});
			if(found > matchingProperties)matchingProperties =  found
			
		}) 
 
		if (matchingProperties === productFilterFromServer.length){
			removeItem();
			return aler("Product is already in cart", "success");
		}

		let inValidQuantities = ""

		Object.keys(properties).map(key=>{
			if(properties[key] == null){
				quantities.forEach(filter=>{
					if(filter.id == key) inValidQuantities +=filter.name+", "
				})
			}
		}) 
		
		if (Object.keys(properties).length > 0 && inValidQuantities.length > 0) return aler("Please select "+inValidQuantities);
		
		const newCart = [...cart, item].map((res, index)=>{
			if(res.index)return res
			return {...res, index:getRandomNumber(1, 10000)}
		})
		setCart([...cart, {...item, selectedQuantities: properties }]); 

		removeItem();
	};

	const removeItem = async () => {
		const s = savedItems.filter((savedItem) => {
			return savedItem.id !== item.id;
		});

		setSavedItems(s);
	};

	return (
		<section className="wishlist__item">
			<img src={item.mainpic} alt="Product" />

			<h2>{item.title}</h2>

			<section className="wishlist__item--product-price">
				{item.discount ? (
					<>
						<span className="wishlist__item--product-price-discount">
							${getDiscountPrice()}
						</span>
						<span className="wishlist__item--product-price-original wishlist__item--product-price-original--inactive">
							${parseFloat(item.price, 2)}
						</span>
					</>
				) : (
					<span className="wishlist__item--product-price-original wishlist__item--product-price-original--active">
						${parseFloat(item.price, 2)}
					</span>
				)}
			</section>

			<section className="wishlist__item--product-color">{item.colors}</section>

			<section className="wishlist_item--product-size">
				{
					quantities.map((filter, index)=>{
						return( 
							<div key={index}>
								<label className="text-dark" style={{textAlign:"left", marginTop:"15px", fontWeight:"bold"}}>{filter.name}:</label>
								<select key={index} onClick={(event)=>{handleQuantities(event, filter.id)}} className="SelectSize" name="size">
									<option value="">Select {filter.name}</option>
									{filter.filteroptions.map((ele, index2)=>{
										const quantity = productFilterQuantity[","+ele.id+","] 
										const selected = filter.id in properties && properties[filter.id] ==ele.id ? true : false										
										return(
											<option selected={selected} key={index2} value={ele.id}>
												{ele.name} {quantity === "0" || quantity === 0 ? "- Out Of Stock":""}
											</option>
										)
									})}
								</select> 
							</div>
						)
					})
				}
			</section>

			<section className="wishlist__item--product-buttons"> 
				<button
					className="wishlist__item--product-button-active"
					onClick={bagItem}
				>
					MOVE TO BAG
				</button> 
			</section>

			<section className="wishlist__item--product-cta">
				<span></span>
				<button
					className="wishlist__item--product-cta-icon"
					onClick={removeItem}
				>
					<BsTrash size={24} />
				</button>
			</section>
		</section>
	);
}
