import React, { useEffect, useState } from "react";
import { get } from "../../../../config/ajaxRequest";
import "../../../../asset/css/Buttonserver.css";

const AdData = ({ longText, title, titlehover }) => (
	<div className="mainads">
		<div className="p1" title={titlehover}>
			{longText}
		</div>
		<div className="p2">{title}</div>
	</div>
);

const ButtonserverChange = ({ text1, text2 }) => {
	const [ads, setAds] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await get("ads/file");
				const myRes = res.ads; // Destructure specific properties
				setAds(myRes); // Update state with individual properties
				// console.log(myRes, "image data here...");
			} catch (err) {
				console.error("Error fetching data:", err);
			}
		};

		fetchData();
	}, []);

	return (
		<>
			{Array.isArray(ads) && ads.length > 0 ? (
				ads.map((ad, index) => (
					<AdData
						key={index}
						longText={ad.longtext}
						title={ad.title}
						titlehover={ad.titlehover}
					/>
				))
			) : (
				<p>No data available</p>
			)}
		</>
	);
};

export default ButtonserverChange;
