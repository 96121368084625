import "./App.css";
import { useEffect, useState } from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	useHistory,
} from "react-router-dom";
import WomanScreen from "./components/women/WomanScreen";
import MenScreen from "./components/men/MenScreen";
import KidsScreen from "./components/kids/KidsScreen";
import FitnessScreen from "./components/fitness/FitnessScreen";

import Home from "./components/Menu/ThirdMenu/Home";
import ToggleNav from "./components/ToggleNav";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import Account from "./pages/account/Account";
import Address from "./pages/account/Address";
import SocialAccount from "./pages/account/SocialAccount";
import Reuseable from "./components/Product/Reuseable";
import WishList from "./pages/WishList";
import Cart from "./pages/Cart";

import AccountLayout from "./layouts/AccountLayout";
import BiillingAddress from "./pages/account/BiillingAddress"; 
import AccountDetails from "./pages/account/AccountDetails";
import Orders from "./pages/account/Orders";
import GiftVouchers from "./pages/account/GiftVouchers";
import OrderItem from "./pages/account/OrderItem";
import MainHelp from "./components/help/MainHelp";
import InTouch from "./components/help/InTouch";
import Location from "./components/geolocation/Location";

import Product from "./pages/product/Product";
import CheckOut from "./pages/checkout/CheckOut";
import CheckOutShipping from "./pages/checkout/CheckOutShipping";
import CheckOutLayout from "./components/checkout/CheckOutLayout";
import CheckOutPayment from "./pages/checkout/CheckOutPayment"; 
import WelcomeBack from "./components/checkout/WelcomeBack"; 
import ForgetPassword from "./pages/auth/ForgetPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import Maintainance from "./components/Maintainance";
import ComingSoon from "./components/ComingSoon";
import JewScreen from "./components/jewellery/JewScreen";
import MarketPlace from "./components/Menu/MarketPlace";
import Information from "./components/checkout/Information";
import InformationSummary from "./components/checkout/InformationSummary";
import MerchantSummary from "./components/checkout/MerchantSummary";
import { get } from "./config/ajaxRequest";
import RootScreen from "./components/root/RootScreen";
import TermsAndConditions from "./components/TermsAndConditions";
import PrivacyAndCookies from "./components/PrivacyAndCookies ";
import PayPalButton from "./components/paymentGateways/PayPalButton";
import MyPaystackButton from "./components/paymentGateways/MyPaystackButton";
import ColorPicker from "./config/ColorPalate";
import YehsuaScreen from "./components/yeshua/YehsuaScreen";

function App() {
	const [menuEvent, setMenuEvent] = useState("");
	const [newRoute, setNewRoute] = useState("");
	const [onMaintenance, setOnMaintenance] = useState(false);

	const history = useHistory();
	useEffect(() => {
		document.addEventListener("category", function (e) {
			if (e.detail.data === menuEvent) return;
			setMenuEvent(e.detail.data);
		});
	}, []);

	useEffect(() => {
		(async () => {
			try {
				const data = await get("checkmaintenance");
				// console.log(data, "maintina");
				const path = window.location.pathname;
				if (path === "/maintain" && data !== "yes") window.location.href = "/";
				if (data === "yes" && path !== "/maintain") {
					window.location.href = "/maintain";
				}
				if (path === "/maintain") setOnMaintenance(true);
			} catch (error) {
				console.error("Error checking maintenance status:", error);
			}
		})();
	}, [history]);

	useEffect(() => {
		const unlisten = history.listen((location, action) => {
			// The route has changed
			setNewRoute(location.pathname);
			// console.log(`Route changed to ${location.pathname}`);
		});
		// Cleanup function to unsubscribe from the listener when the component unmounts
		return () => {
			unlisten();
		};
	}, [history]);
	return (
		<div className="App">
			<Router>
				{!onMaintenance && <ToggleNav />}
				<Switch>
					{/* Auth */}
					<Route path="/signIn" component={SignIn} />
					<Route path="/signUp" component={SignUp} />
					<Route path="/forgetpassword" component={ForgetPassword} />
					<Route path="/resetpassword" component={ResetPassword} />

					<Route path="/" exact component={Home}></Route>
					<Route path="/women" exact component={WomanScreen}></Route>
					<Route path="/men" exact component={MenScreen}></Route>
					<Route path="/kids" exact component={KidsScreen}></Route>
					<Route path="/jewellery" exact component={JewScreen}></Route>
					<Route path="/fitness" exact component={FitnessScreen}></Route>
					<Route path="/root" exact component={RootScreen}></Route>
					<Route path="/yeshua" exact component={YehsuaScreen}></Route>
					<Route path="/reuse" exact component={Reuseable}></Route>
					<Route path="/help" exact component={MainHelp}></Route>
					<Route path="/touch" exact component={InTouch}></Route>
					<Route path="/locate" exact component={Location}></Route>
					<Route path="/maintain" exact component={Maintainance}></Route>
					<Route path="/market" exact component={MarketPlace}></Route>
					<Route path="/summary" exact component={InformationSummary}></Route>
					<Route path="/terms" exact component={TermsAndConditions}></Route>
					<Route path="/privacy" exact component={PrivacyAndCookies}></Route>
					<Route path="/pal" exact component={MyPaystackButton}></Route>
					<Route path="/palate" exact component={ColorPicker}></Route>

					{/* Account Routes */}
					<Route path={"/my-account"}>
						<AccountLayout>
							<Route exact path={"/my-account"} component={Account} />
							<Route exact path={"/my-account/addresses"}>
								<Address />
							</Route>
							<Route
								exact
								path={"/my-account/addresses/edit-address/billing"}
								component={BiillingAddress}
							/>
							<Route
								exact
								path={"/my-account/addresses/edit-address/shipping"}
								component={BiillingAddress}
							/>
							<Route
								path={"/my-account/my-details"}
								component={AccountDetails}
							/>
							<Route exact path={"/my-account/orders"} component={Orders} />
							<Route path={"/my-account/orders/:id"} component={OrderItem} />
							<Route
								exact
								path={"/my-account/social-accounts"}
								component={SocialAccount}
							/>
							<Route
								exact
								path={"/my-account/gift-cards-and-vouchers"}
								component={GiftVouchers}
							/>
						</AccountLayout>
					</Route>
					<Route exact path="/wishlist" component={WishList} />
					<Route exact path="/cart" component={Cart} />

					{/* checkout */}
					<Route exact path={"/product/:id"} component={Product} />
					<Route
						path="/product/:category/:id"
						exact
						component={Reuseable}
					></Route>
					<Route path="/products/:id" exact component={Reuseable}></Route>

					<Route path={"/checkout"}>
						<CheckOutLayout>
							<Route exact path={"/checkout"} component={CheckOut} />
							<Route
								exact
								path={"/checkout/shipping"}
								// component={CheckOutShipping} Information
								component={Information}
							/>
							<Route
								exact
								path={"/checkout/payment"}
								// component={CheckOutPayment} Information
								component={Information}
							/>
							<Route
								exact
								path={"/checkout/summary"}
								// component={InformationSummary} Information
								component={Information}
							/>
						</CheckOutLayout>
					</Route>

					<Route exact path={"/checkout"} component={CheckOut} />
 

					<Route exact path={"/welcomeback"} component={WelcomeBack} />

					<Route exact path="*">
						Page Not Found
					</Route>
				</Switch>
			</Router>
		</div>
	);
}

export default App;
