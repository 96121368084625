import {useState, useEffect} from 'react'
import { setItem, getItem } from '../config/config'
import { get } from '../config/ajaxRequest'

export const useFilter = ()=>{ 
    const requestToServer = async() =>{
         
        let lastFilterUpdateId = getItem("lastFilterUpdateId") ?? 0
        const data = await get("getfilter?="+lastFilterUpdateId)        
        if(data.fillastFilterUpdateIdter == lastFilterUpdateId)return

        if(data.filter) setItem("filter", data.filter)
        if(data.lastFilterUpdateId) setItem("lastFilterUpdateId", data.lastFilterUpdateId)  
    }

    useEffect(() => {  
            try{
            requestToServer()
            }catch(err){} 
    }, []);    

    
    return {}
}

