import { Link } from "react-router-dom";
import { getItem } from "../../../config/config";

const fireEvent = (data, handleMouseLeave) => {
	handleMouseLeave();
	const event = new CustomEvent("menuEvent", {
		bubbles: true,
		detail: { data: data },
	});
	// console.log(data, "checking...")
	document.dispatchEvent(event);
};

const constructLink = (id) => {
	const category = getItem("lastmenu") ?? "menu";
	return `/product/${category}/${id}`;
};
export const MenuWithOnlyText = ({ item, handleMouseLeave }) => {
	const url = constructLink(item.id);
	return (
		<li key={item.id}>
			<Link
				to={url}
				onClick={() => fireEvent(item.id, handleMouseLeave)}
				className="menulink"
			>
				<div className="line" />
				<h5 className="menutext">{item.name}</h5>
			</Link>
		</li>
	);
};

export const MenuWithBigImage = ({ item, handleMouseLeave }) => {
	const url = constructLink(item.id);
	return (
		<li key={item.id}>
			<Link
				to={url}
				onClick={() => fireEvent(item.id, handleMouseLeave)}
				className="menulink2"
			>
				<img className="menuimage" src={item.image} alt="Hianga" />
				<h5 className="menutext">{item.name}</h5>
				<div className="line">
					<hr className="innerline2" />
				</div>
			</Link>
		</li>
	);
};

export const MenuWithSmallImage = ({ item, handleMouseLeave }) => {
	const url = constructLink(item.id);
	return (
		<li key={item.id}>
			<Link
				to={url}
				onClick={() => fireEvent(item.id, handleMouseLeave)}
				className="menulink2"
			>
				<div className="datahorizontal">
					<img
						className="menuimage"
						src={
							item.image ??
							"http://127.0.0.1:3000/static/media/a.92ee566a674fd556967f.jpeg"
						}
						alt="Hianga"
					/>
					<h4 className="menutext" style={{ textAlign: "center" }}>
						{item.name}
					</h4>
				</div>
				<br />
			</Link>
		</li>
	);
};
