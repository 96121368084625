import React, { useState } from "react";
import Select from "react-select";
import { getCurrency } from "country-currency-map";

import "../../asset/css/menustyles.css";
import { BiCurrentLocation } from "react-icons/bi";
import Socia from "./Socia";
const countries = [
	{ value: "US", label: "United States" },
	{ value: "CA", label: "Canada" },
	{ value: "GB", label: "United Kingdom" },
	// Add more countries as needed
];

const currencies = [
	{ value: "USD", label: "US Dollar" },
	{ value: "CAD", label: "Canadian Dollar" },
	{ value: "GBP", label: "British Pound" },
	// Add more currencies as needed
];
export default function Location() {
	const [selectedCountry, setSelectedCountry] = useState("");
	const [selectedCurrency, setSelectedCurrency] = useState("");

	const handleCountryChange = (selectedOption) => {
		const countryCode = selectedOption.value;
		setSelectedCountry(selectedOption);
		const currencyCode = getCurrency(countryCode);
		setSelectedCurrency({ value: currencyCode, label: currencyCode });
	};
	return (
		<div className="d-flex align-items-center align-self-center  px-5">
			{/* start modal */}
			<div
				type="button"
				data-bs-toggle="offcanvas"
				data-bs-target="#offcanvasExample"
				aria-controls="offcanvasExample"
			>
				<BiCurrentLocation size={24} color="#100840" />
			</div>

			<div
				className="offcanvas offcanvas-start"
				tabIndex="-1"
				id="offcanvasExample"
				aria-labelledby="offcanvasExampleLabel"
			>
				<div className="offcanvas-header">
					<h1
						className="offcanvas-title text-secondary"
						id="offcanvasExampleLabel"
					>
						PREFERENCES
						<hr />
					</h1>
					<button
						type="button"
						className="btn-close text-reset"
						data-bs-dismiss="offcanvas"
						aria-label="Close"
					></button>
				</div>
				<div className="offcanvas-body">
					<h4 className="shopin text-primary d-flex justify-content-left">
						SHOP IN:{" "}
					</h4>
					<hr />
					<label style={{ fontSize: "20px" }}>Country:</label>
					<Select
						value={selectedCountry}
						onChange={handleCountryChange}
						options={countries}
					/>
					<Socia />
					<label style={{ fontSize: "20px" }}>Currency:</label>
					<Select
						value={selectedCurrency}
						options={currencies}
						isDisabled={!selectedCountry} // Disable currency selector until country is selected
					/>
					<br />
					<div className="offcanvas-footer container mt-5">
						<h2
							className="offcanvas-title bg-dark text-light "
							id="offcanvasExampleLabel"
						>
							UPDATE PREFERENCES
							<hr />
						</h2>
					</div>
				</div>
			</div>
			{/* end of modal */}
		</div>
	);
}
