import React from "react";
import "../../asset/css/menustyles.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { motion } from "framer-motion";
export default function MarketPlace() {
	return (
		<a href="https://iiignacy.com" style={{ textDecoration: "none" }}>
			<div className="marketplace" style={{ float: "none", marginLeft: "40%" }}>
				<h4>Hianga Store!</h4>
				<motion.div
					initial={{ x: 100, y: 100 }}
					animate={{ x: 0, y: 0 }}
					className="bg"
				>
					Upto 50% Off
				</motion.div>
			</div>
		</a>
	);
}
