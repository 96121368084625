import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import "swiper/css/grid";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import "../../asset/css/gridstyles.css";
import Images from "../../config/Images"; // If you have a module for image formats

import { get } from "../../config/ajaxRequest";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function Grids() {
	const [imageData, setImageData] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await get("swiper/files");
				const myRes = res.imageFitnessData; // Destructure specific properties
				setImageData(myRes); // Update state with individual properties
				//console.log(myRes, "image data here...");
			} catch (err) {
				console.error("Error fetching data:", err);
			}
		};

		fetchData();
	}, []);

	return (
		<>
			<div className="container">
				<div className="row">
					{imageData.map((image, index) => (
						<div
							key={index}
							className="col-lg-3 col-md-3 col-sm-6 col-xs-12 swiper-slide1"
						>
							<img
								src={image.imageUrl}
								alt={`caro ${index + 1}`}
								className="gridImg"
							/>
							<div className="gridText">
								<h2 className="gridtitle">
									{" "}
									<Link to="/" className="gridtitle">
										{" "}
										{image.title}{" "}
									</Link>
								</h2>
								<p className="griddesc">
									<Link to="/" className="griddesc">
										{" "}
										{image.description}
									</Link>
								</p>
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
}
