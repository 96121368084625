import React from "react";
import Footer from "./Footer";

const PrivacyAndCookies = () => {
	return (
		<div className="container-fluid">
			<div className="container">
				{/* Accordion: Privacy & Cookies */}
				<div
					className="accordion mt-5"
					id="privacyCookiesAccordion"
					data-aos="fade-right"
				>
					<div className="accordion-item">
						<h2 className="accordion-header" id="privacyCookiesHeader">
							<button
								className="accordion-button"
								type="button"
								data-bs-toggle="collapse"
								style={{ backgroundColor: "#100840", color: "white" }}
								data-bs-target="#privacyCookiesCollapse"
								aria-expanded="true"
								aria-controls="privacyCookiesCollapse"
							>
								Privacy & Cookies: Our promises
							</button>
						</h2>
						<div
							id="privacyCookiesCollapse"
							className="accordion-collapse collapse show"
							aria-labelledby="privacyCookiesHeader"
							data-bs-parent="#privacyCookiesAccordion"
						>
							<div className="accordion-body">
								<p>
									We’ll always keep your data safe and secure. So you’re clued
									up, here’s why we need it and how we use it.
								</p>
								<ul>
									<li>
										We will only use your data to improve your Hianga experience
										and Hianga services.
									</li>
									<li>We’ll protect and secure your data.</li>
									<li>
										We’ll always talk your language and be open – no-nonsense,
										no surprises.
									</li>
									<li>
										You decide if you want to hear from us and how you hear from
										us.
									</li>
									<li>
										Your info won’t just hang about – if we don’t need it, we’ll
										delete it.
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				{/* Accordion: Protecting Your Privacy */}
				<div
					className="accordion mt-5"
					id="protectingPrivacyAccordion"
					data-aos="fade-left"
				>
					<div className="accordion-item">
						<h2 className="accordion-header" id="protectingPrivacyHeader">
							<button
								className="accordion-button"
								type="button"
								data-bs-toggle="collapse"
								style={{ backgroundColor: "#100840", color: "white" }}
								data-bs-target="#protectingPrivacyCollapse"
								aria-expanded="true"
								aria-controls="protectingPrivacyCollapse"
							>
								Protecting Your Privacy
							</button>
						</h2>
						<div
							id="protectingPrivacyCollapse"
							className="accordion-collapse collapse  "
							aria-labelledby="protectingPrivacyHeader"
							data-bs-parent="#protectingPrivacyAccordion"
						>
							<div className="accordion-body">
								<p>
									At Hianga, we are 100% committed to protecting your privacy
									and security. We are customers ourselves on other sites, so we
									appreciate and respect how important privacy is.
								</p>
								<p>
									<strong>Full Privacy policy</strong>
								</p>
								<ul>
									<li>How we use your information</li>
									<li>Sharing your information</li>
									<li>Marketing messages</li>
									<li>Seeing adverts for hianga.com online</li>
									<li>Keeping your information</li>
									<li>Your rights</li>
									<li>Changes to how we protect your privacy</li>
									<li>Cookies</li>
									<li>Contact us</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div
					className="accordion mt-5"
					id="useInfoAccordion"
					data-aos="fade-right"
				>
					<div className="accordion-item">
						<h2 className="accordion-header" id="useInfoHeader">
							<button
								className="accordion-button"
								type="button"
								data-bs-toggle="collapse"
								style={{ backgroundColor: "#100840", color: "white" }}
								data-bs-target="#useInfoCollapse"
								aria-expanded="true"
								aria-controls="useInfoCollapse"
							>
								How we use your information
							</button>
						</h2>
						<div
							id="useInfoCollapse"
							className="accordion-collapse collapse  "
							aria-labelledby="useInfoHeader"
							data-bs-parent="#useInfoAccordion"
						>
							<div className="accordion-body">
								<p>
									We use your information in several different ways. What we do
									depends on the information. We explain below in detail, what
									we do, and why we do it.
								</p>
								<p>
									YOUR DETAILS, SUCH AS YOUR NAME, DATE OF BIRTH, GENDER,
									ADDRESS, EMAIL ADDRESS, SOCIAL MEDIA HANDLE, SCREEN NAME, AND
									PHONE NUMBER.
								</p>
								<ol>
									<li>
										Identify you when you visit our website or contact us:
									</li>
									<li>Deliver your purchases to you:</li>
									<li>
										Send you account and service updates, such as updates to our
										Terms and Conditions and order confirmations:
									</li>
									<li>
										Send your order updates by text, e-mail, or through our app:
									</li>
									<li>
										Manage your participation in any promotions, offers, or
										discount schemes you choose to participate in:
									</li>
									<li>Direct you to the right part of our website:</li>
									<li>Send you information about our products and services:</li>
								</ol>

								<p>
									YOUR BODY SIZE AND SHAPE, IF YOU CHOOSE TO TELL US THIS. For
									each product, we suggest sizes, and how garments fit. Because
									We want you to love the products you buy from us. It is
									important to us that you buy the right product from us, we
									want you to love the hianga products you wear.
								</p>

								<p>
									YOUR PAYMENT INFORMATION This means your chosen payment
									method, for example, your card details (don’t worry we don’t
									keep the security code) We need your payment information to
									take payment and give refunds. It’s important to us and an
									important part of our contract commitment to you. We need to
									keep a record of any financial transactions with you. Why?
									Because we need to know what you have paid for (and we must
									tell the government about our income too!) Legally, we have to
									do this.
								</p>

								<p>
									YOUR CONTACT HISTORY WITH US What you’ve said to us. For
									example, by email, on instant chat, on social media, or in
									private messages. If you contact us by phone we record calls
									to our customer care center, too. We do it to provide customer
									service and support. Because you expect the best services from
									us! We need to do it because It’s an important part of our
									service and part of our contract commitment to you.
								</p>

								<p>
									Keeping a record of your contact history with us helps us
									improve the services and support we provide to you. So that
									you get the best possible customer service and it is important
									to us to train our staff.
								</p>

								<p>
									PURCHASE HISTORY AND SAVED ITEMS What you’ve bought in the
									past, what you’ve searched for - including using our Style
									Match feature - what you’ve saved or added to a board for
									another time, and when you have asked us to tell you that
									something is back in stock. We need a record of your purchase
									history to help you keep track of items you like, update you
									when we have new availability, and let you share them, your
									way. We do this to help you get to the products that you like
									faster and so you can get the best out of our products and
									your account. It’s up to you if you want to use these services
									but we would love you to share all our good stuff.
								</p>

								<p>
									We handle returns by our Terms and Conditions and provide
									customer service and support. Because you expect the best
									service from us and, after all, we can’t provide a refund if
									we don’t know what you’ve bought! It’s an important part of
									our contract commitment to you.
								</p>

								<p>
									We analyze what you have bought, searched for, or returned,
									which helps us find out what you like. For Style Match, once
									we’ve shown you your search results, we store the photo for
									one month and keep it separated from anything else that
									identifies you. We do this to ensure we are giving you what
									you want, providing you with the best service we can, and so
									we can stay ahead of the competition. We need to know your
									preferences.
								</p>

								<p>
									INFORMATION ABOUT YOUR PHONE OR LAPTOP, AND HOW YOU USE OUR
									WEBSITE AND APP Information collected when you browse our site
									or use our app, including your IP address and device type, how
									you use our website and app (such as the pages you visit and
									the products you look at), and, if you choose to share it with
									us, your location data.
								</p>

								<p>
									We identify you when you visit our website. Because, if we can
									remember you, we can give you the best possible shopping
									experience. Legally it is important for us that you get the
									best shopping experience. We do this to improve our website
									and set default options for you (such as language and
									currency) Because it’s important for us, and it’s much easier
									for you if we know the language and currency you prefer, and
									it also helps us to protect your information. Also, we need to
									know what you like to give you the best shopping experience.
									We send information about our products and services to you, to
									keep you up to date and help you find products. You can find
									out more in our section on Marketing messages below. We need
									to show you things we think you will like and that may make
									your Hianga experience better. We show you Hianga.com adverts
									as you browse the web to help you see our latest products and
									deals that we think you will love because we need to show you
									things, we think you will like. We monitor visitors to our
									site and analyze their behavior to protect our website and to
									help make our service better. This is important for us and
									legally, we have to do this!
								</p>

								<p>
									INFORMATION FROM SOCIAL MEDIA OR ACCOUNTS YOU LINK TO US What
									do we do if you link your Social Media or your third-party
									accounts to us? We will keep a record of your Social Media
									handle, and the other information that is made available to us
									according to your Social Media account settings.
								</p>

								<p>
									We allow you to link your Social Media account to your Hianga
									account so you can log on simply and easily without having to
									create a specific account. We do this to make it easier for
									you to use Hianga.com and purchase the items you want! Because
									it is important for us that you get the best shopping
									experience. We analyze to understand what you like, how you
									might share your likes with your friends, and how you might
									influence others with your style. This helps improve any
									recommendations we make to you, and we may invite you to take
									part in surveys, reward schemes, and other fashion-related
									activities. We need to know what you like to give you the best
									shopping experience. We provide product recommendations, so
									you can quickly spot things that take your fancy. Because we
									need to show you things, we think you will like.
								</p>

								<p>
									IF YOU POST COMMENTS ABOUT HIANGA, TAG HIANGA OR POST PHOTOS
									TO OUR SOCIAL MEDIA PAGES. We monitor our customers’ views or
									opinions because it is important to us to respond to you or
									react, particularly if you are unhappy with something. We need
									to know what you think about us. We use public sources of
									information to help us investigate fraudulent activity to
									prevent and detect fraud against either you or Hianga.
									Unfortunate, but essential. We need to protect our service, to
									protect you, and to stop fraudulent activities.
								</p>

								<p>
									YOUR RESPONSES TO SURVEYS, FEEDBACK, AND COMPETITIONS. We ask
									for feedback on our products or how you feel we are doing.
									Generally, responses are anonymized but we may want to respond
									to you directly, for example, if you mention you are unhappy
									with something. It makes good sense to check how you feel
									about us and your purchases from time to time and we can use
									this information to improve our service. But of course, it’s
									up to you whether you take part. We manage the competitions
									that you choose to enter and get prizes to the winners. We
									will let you know how we use your data at the point of entry.
									We need to let you know if you win! If you take part we will
									treat this as an important contract commitment to you.
								</p>

								<p>
									OTHER IDENTIFIABLE INFORMATION. We allocate you a unique
									number when you first shop with us (your customer ID) This
									allows us to uniquely identify you. Legally it’s an important
									part of our contract commitment to you. You don’t have to give
									us all of this personal information but if you don’t, you may
									not be able to buy from the site, and you are unlikely to
									receive our optimal overall customer experience. But that is
									your choice and we respect that.
								</p>

								<p>
									FRAUD PREVENTION AND DETECTION We use any of the above
									categories of information to identify, prevent, and detect
									fraud, against either you or Hianga. Detecting and preventing
									fraud is an unfortunate, but essential part of our service and
									our contract commitment to you. Legally, we have to do this,
									and it is very important for us. We also use Purchase history
									data, to protect our service and uphold our Terms of Service
									as part of our contract commitment to you, as part of this we
									may make use of computer-system decisions to protect Hianga
									and our Service. Your rights about this are detailed below.
								</p>

								<p>
									PERSONALIZING YOUR HIANGA EXPERIENCE We use the data we
									collect to help us provide you with the best service, and the
									best shopping experience and to show you the latest and
									greatest products and services that we think you will love.
								</p>
							</div>
						</div>
					</div>
				</div>
				{/* Accordion: Sharing your information */}
				<div
					className="accordion mt-5"
					id="sharingInfoAccordion"
					data-aos="fade-left"
				>
					<div className="accordion-item">
						<h2 className="accordion-header" id="sharingInfoHeader">
							<button
								className="accordion-button"
								type="button"
								data-bs-toggle="collapse"
								style={{ backgroundColor: "#100840", color: "white" }}
								data-bs-target="#sharingInfoCollapse"
								aria-expanded="true"
								aria-controls="sharingInfoCollapse"
							>
								Sharing your information
							</button>
						</h2>
						<div
							id="sharingInfoCollapse"
							className="accordion-collapse collapse  "
							aria-labelledby="sharingInfoHeader"
							data-bs-parent="#sharingInfoAccordion"
						>
							<div className="accordion-body">
								<p>
									We share your data with the following categories of companies
									as an essential part of being able to provide our services to
									you:
								</p>
								<p>
									Companies that do things to get your purchases to you, such as
									payment service providers, warehouses, order packers, and
									delivery companies.
								</p>
								<p>
									Professional service providers, such as marketing agencies,
									advertising partners, and hosting companies who help us run
									our business.
								</p>
								<p>
									Affiliates who help us reach out to potential new customers or
									promote our products on their websites.
								</p>
								<p>
									Credit reference agencies, law enforcement, and fraud
									prevention agencies, so we can help tackle fraud.
								</p>
								<p>
									You may choose to take advantage of some of our additional
									services, in which case, depending on your choices we may
									share your data with the following categories of companies to
									fulfill the services you have asked for:
								</p>
								<p>
									Social Media sites (for example if you choose to link your
									accounts to us) and other companies approved by you.
								</p>
								<p>Product advisors to help you choose the right products.</p>
								<p>
									Other third-party payment providers, when you choose to use
									their payment services.
								</p>
								<p>
									If you would like to know more about the 3rd parties we may
									share personal data with, or how to find out more about how
									they will use your data, please contact us at the details
									below.
								</p>
								<p>
									We do not, and will not, sell any personal data that directly
									identifies you to any third party.
								</p>
							</div>
						</div>
					</div>
				</div>

				<div
					className="accordion mt-5"
					id="marketingAccordion"
					data-aos="fade-right"
				>
					<div className="accordion-item">
						<h2 className="accordion-header" id="marketingHeader">
							<button
								className="accordion-button"
								type="button"
								data-bs-toggle="collapse"
								style={{ backgroundColor: "#100840", color: "white" }}
								data-bs-target="#marketingCollapse"
								aria-expanded="true"
								aria-controls="marketingCollapse"
							>
								Marketing messages
							</button>
						</h2>
						<div
							id="marketingCollapse"
							className="accordion-collapse collapse  "
							aria-labelledby="marketingHeader"
							data-bs-parent="#marketingAccordion"
						>
							<div className="accordion-body">
								<p>
									If you have said we can, we’ll send you marketing messages to
									keep you aware of what we’re up to and to help you see and
									find our products and services.
								</p>
								<h2>How to stop marketing messages from Hianga.com</h2>
								<p>
									You can control the marketing communications you receive from
									us through the Contact Preferences section of your Hianga
									account. You also have the following options:
								</p>
								<ul>
									<li>
										Emails: You can also click on the ‘unsubscribe’ link in any
										marketing email you receive, and this will take you to the
										Contact Preferences section of your account so you can
										unsubscribe from that method of communication.
									</li>
									<li>
										Text Messages: You can also text “stop” to the number
										provided within the communication and this will unsubscribe
										you from that method of communication.
									</li>
									<li>
										Any method of Marketing: You can contact our Customer Care
										team. Once you do this, we will update our records to ensure
										that you don’t receive further marketing messages.
									</li>
								</ul>
								<p>
									If you tell us you don’t want to receive marketing messages it
									might take a few days for all our systems to be updated, so we
									would ask for your patience as you might get messages from us
									while we process your request.
								</p>
								<p>
									Please note that opting out of marketing messages will not
									stop service communications, such as order updates, or where
									you have asked for a specific ‘back in stock’ notification.
								</p>
							</div>
						</div>
					</div>
				</div>
				{/* Accordion: Seeing adverts for Hianga.com online */}
				<div
					className="accordion mt-5"
					id="advertsAccordion"
					data-aos="fade-left"
				>
					<div className="accordion-item">
						<h2 className="accordion-header" id="advertsHeader">
							<button
								className="accordion-button"
								type="button"
								data-bs-toggle="collapse"
								style={{ backgroundColor: "#100840", color: "white" }}
								data-bs-target="#advertsCollapse"
								aria-expanded="true"
								aria-controls="advertsCollapse"
							>
								Seeing adverts for Hianga.com online
							</button>
						</h2>
						<div
							id="advertsCollapse"
							className="accordion-collapse collapse  "
							aria-labelledby="advertsHeader"
							data-bs-parent="#advertsAccordion"
						>
							<div className="accordion-body">
								<p>
									We use online advertising to keep you aware of what we’re up
									to and to help you see and find our products.
								</p>
								<p>
									You may see Hianga banners and ads when you are on other
									websites and apps, such as Social Media. We manage this
									through a variety of digital marketing networks and ad
									exchanges. We also use a range of advertising technologies.
								</p>
								<p>
									The banners and ads you see are based on information we hold
									about you, or your previous use of Hianga (for example, your
									Hianga search history, and the content you read on Hianga) or
									on Hianga banners or ads you have previously clicked on.
								</p>
								<p>
									For more information on our use of advertising technologies
									and Cookies, please see our cookie notice.
								</p>
							</div>
						</div>
					</div>
				</div>
				{/* Accordion: Keeping your information */}
				<div
					className="accordion mt-5"
					id="keepingInfoAccordion"
					data-aos="fade-right"
				>
					<div className="accordion-item">
						<h2 className="accordion-header" id="keepingInfoHeader">
							<button
								className="accordion-button"
								type="button"
								data-bs-toggle="collapse"
								style={{ backgroundColor: "#100840", color: "white" }}
								data-bs-target="#keepingInfoCollapse"
								aria-expanded="true"
								aria-controls="keepingInfoCollapse"
							>
								Keeping your information
							</button>
						</h2>
						<div
							id="keepingInfoCollapse"
							className="accordion-collapse collapse  "
							aria-labelledby="keepingInfoHeader"
							data-bs-parent="#keepingInfoAccordion"
						>
							<div className="accordion-body">
								<p>
									We’ll hold on to your information for as long as you continue
									to be a Hianga customer and for as long as we are required to
									keep it to ensure we meet our legal requirements across the
									globe.
								</p>
								<p>
									If you no longer wish to be a customer you can contact our
									Customer Care team and request that we close your account.
									However, we have a legal requirement to keep some of your data
									even after you have asked us to delete it. We will only keep
									what we need to, and only to make sure we can meet our legal
									or regulatory requirements, resolve disputes, prevent fraud
									and abuse, or enforce our Terms & Conditions.
								</p>
							</div>
						</div>
					</div>
				</div>
				{/* Continue adding more accordion sections... */}
				{/* Accordion: Your rights */}
				<div
					className="accordion mt-5"
					id="rightsAccordion"
					data-aos="fade-left"
				>
					<div className="accordion-item">
						<h2 className="accordion-header" id="rightsHeader">
							<button
								className="accordion-button"
								type="button"
								data-bs-toggle="collapse"
								style={{ backgroundColor: "#100840", color: "white" }}
								data-bs-target="#rightsCollapse"
								aria-expanded="true"
								aria-controls="rightsCollapse"
							>
								Your rights
							</button>
						</h2>
						<div
							id="rightsCollapse"
							className="accordion-collapse collapse  "
							aria-labelledby="rightsHeader"
							data-bs-parent="#rightsAccordion"
						>
							<div className="accordion-body">
								<p>
									You have a lot of rights relating to your personal
									information. These are:
								</p>
								<ul>
									<li>
										The right to be informed about how your personal information
										is being used (like this notice!)
									</li>
									<li>
										The right to access the personal information we hold about
										you
									</li>
									<li>
										The right to request the correction of inaccurate personal
										information we hold about you (although you can probably do
										most of this through My account.
									</li>
									<li>
										The right to request that we delete your data, or stop
										processing it or collecting it, in some circumstances
									</li>
									<li>
										The right to stop direct marketing messages, which you can
										do through My account.
									</li>
									<li>
										The right to withdraw consent for any consent-based
										processing at any time
									</li>
									<li>
										The right to request that we transfer or port elements of
										your data either to you or another service provider
									</li>
									<li>
										The right to ask us to explain any computer-system decision
										about you
									</li>
									<li>
										The right to complain to your data protection regulator.
									</li>
								</ul>
								<p>
									If you want to exercise your rights, have a complaint, or just
									have questions, please contact us. As a starting point, we
									have 30 days in which to respond to you. Our contact details
									are at the end of this Policy.
								</p>
							</div>
						</div>
					</div>
				</div>
				{/* Accordion: Changes to How We Protect Your Privacy */}
				<div
					className="accordion mt-5"
					id="changesAccordion"
					data-aos="fade-right"
				>
					<div className="accordion-item">
						<h2 className="accordion-header" id="changesHeader">
							<button
								className="accordion-button"
								type="button"
								data-bs-toggle="collapse"
								style={{ backgroundColor: "#100840", color: "white" }}
								data-bs-target="#changesCollapse"
								aria-expanded="true"
								aria-controls="changesCollapse"
							>
								Changes to How We Protect Your Privacy
							</button>
						</h2>
						<div
							id="changesCollapse"
							className="accordion-collapse collapse  "
							aria-labelledby="changesHeader"
							data-bs-parent="#changesAccordion"
						>
							<div className="accordion-body">
								<p>
									We may change this page from time to time, to reflect how we
									are processing your data.
								</p>
								<p>
									If we make significant changes, we will make that clear on the
									Hianga website or other Hianga services, or by some other
									means of contact such as email, so that you can review the
									changes before you continue to use Hianga.
								</p>
							</div>
						</div>
					</div>
				</div>

				<div
					className="accordion mt-5"
					id="cookiesAccordion"
					data-aos="fade-left"
				>
					<div className="accordion-item">
						<h2 className="accordion-header" id="cookiesHeader">
							<button
								className="accordion-button"
								type="button"
								data-bs-toggle="collapse"
								style={{ backgroundColor: "#100840", color: "white" }}
								data-bs-target="#cookiesCollapse"
								aria-expanded="true"
								aria-controls="cookiesCollapse"
							>
								Cookies
							</button>
						</h2>
						<div
							id="cookiesCollapse"
							className="accordion-collapse collapse  "
							aria-labelledby="cookiesHeader"
							data-bs-parent="#cookiesAccordion"
						>
							<div className="accordion-body">
								<p>
									We use cookies on our website. For more information on
									cookies, please see our cookie notice.
								</p>
							</div>
						</div>
					</div>
				</div>
				{/* Accordion: How to contact us */}
				<div
					className="accordion mt-5"
					id="contactAccordion"
					data-aos="fade-right"
				>
					<div className="accordion-item">
						<h2 className="accordion-header" id="contactHeader">
							<button
								className="accordion-button"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#contactCollapse"
								aria-expanded="true"
								aria-controls="contactCollapse"
								style={{ backgroundColor: "#100840", color: "white" }}
							>
								How to contact us
							</button>
						</h2>
						<div
							id="contactCollapse"
							className="accordion-collapse collapse  "
							aria-labelledby="contactHeader"
							data-bs-parent="#contactAccordion"
						>
							<div className="accordion-body">
								<p>
									We always want to hear from our customers. (especially if you
									feel we’ve let you down or could do better).
								</p>
								<p>
									If you:{" "}
									<li>Have any questions or feedback about this notice</li>{" "}
									<li>Would like us to stop using your information </li>
									<li>
										Want to exercise any of your rights as set out above, or
										have a complaint please don’t hesitate to contact our
										Customer Care team, who will be happy to answer any
										questions you may have.
									</li>
								</p>
								<p>
									You can contact our privacy team by dropping us a line at
									<a
										href="malto:dataprotection@hianga.com"
										style={{ color: "darkblue" }}
									>
										dataprotection@hianga.com
									</a>{" "}
									or else through customer care via the Hianga website.
								</p>
							</div>
						</div>
					</div>
				</div>
				{/* End of accordion sections */}
				{/* Closing tags for the component */}
			</div>
			<br />
			<br />
			<Footer />
		</div>
	);
};

export default PrivacyAndCookies;
