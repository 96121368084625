import React from "react";
import { Link } from "react-router-dom";

import Images from "../../config/Images";
import "../../asset/css/socials.css";
import { Icon } from "@iconify/react";

export default function Socials() {
	return (
		<div className="mainSocial">
			<div className="left">
				<a
					className="img"
					href="https://www.facebook.com/ixxiindustry?mibextid=ZbWKwL"
					target="_blank"
				>
					<img src={Images.Facebook} alt="trendsb1" />
				</a>
				<a
					className="img"
					href="https://instagram.com/lahianga?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D"
					target="_blank"
				>
					<img src={Images.instagram} alt="trendsb2" />
				</a>

				<a
					className="img"
					href="https://www.tiktok.com/@hiangaa?_t=8giliWojfXi&_r=1"
					target="_blank"
				>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
						<path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" />
					</svg>
				</a>

				<a
					className="img"
					href="https://x.com/ixxiindustry?t=0n6JMqftNqu9fqqeVe0Cng&s=09"
					target="_blank"
				>
					<Icon
						icon="ri:twitter-x-line"
						className="socialX"
						style={{
						
						}}
					/>
				</a>

				{/* <Link className='img' to="#"><img src={Images.snapchat} alt="trendsb3"/></Link> */}
			</div>
			<div className="right">
				<Link className="img" to="#">
					<img src={Images.visa} alt="trendsb1" />
				</Link>
				<Link className="img" to="#">
					<img src={Images.express} alt="trendsb2" />
				</Link>
				<Link className="img" to="#">
					<img src={Images.mastercard} alt="trendsb3" />
				</Link>
				<Link className="img" to="#">
					<img src={Images.paypal} alt="trendsb1" />
				</Link>
				<Link className="img" to="#">
					<img src={Images.electron} alt="trendsb2" />
				</Link>
			</div>
		</div>
	);
}
