import React, { useState } from "react";
import CheckOutLayout from "../../components/checkout/CheckOutLayout";
import Information from "../../components/checkout/Information";

import "../../styles/checkout.css";

export default function CheckOut({ children }) {
  return <section className="checkoutMain">
  <Information/>
  </section>;
}
