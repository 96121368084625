import React from "react";
import { Link } from "react-router-dom";

import "../../asset/css/trends.css";
import Images from "../../config/Images";

export default function Trends() {
	return (
		<div className=" container mainTrends">
			<div className="trendbanner">
				<a className="img" href="https://iiignacy.com" target="_blank">
					<img src={Images.img39} alt="hianga b1" />
				</a>
			</div>
			<hr style={{ backgroundColor: "#d4d4d4", color: "#d4d4d4" }} />
		</div>
	);
}
