import { useState, useEffect } from "react";
import { setItem, getItem } from "../config/config";
import { get } from "../config/ajaxRequest";

export const useDropDownMenu = (id) => {
	const [dropDownMenu, setDropDownMenu] = useState([]);

	const requestToServer = async () => {
		//get data from server & time of last update
		const data = await get("getmenu");

		if (data.menuTwo) setItem("menuTwo", data.menuTwo);
		if (data.lastMenuUpdateId)
			setItem("lastMenuUpdateId", data.lastMenuUpdateId);
		return data.menuTwo;
	};

	const getData = async (id, setDropDownMenu) => {
		let menuTwo = getItem("menuTwo");

		if (!menuTwo) {
			menuTwo = await requestToServer();
		}
		return returnValue(menuTwo, id, setDropDownMenu);
	};

	const returnValue = async (menuTwo, id, setDropDownMenu) => {
		const urlPath = window.location.pathname;

		let selectedCategoryMenu = null;
		const lastmenu = await getItem("lastmenu");

		// selectedCategoryMenu = menuTwo.women
		if (lastmenu && lastmenu.length > 0) selectedCategoryMenu = menuTwo[lastmenu] ?? [];

		let arr = []; 
		for (let i = 0; i < selectedCategoryMenu.length; i++) {
			if (selectedCategoryMenu[i].menuone_id == id) {
				arr.push(selectedCategoryMenu[i]);
			}
		}
		setDropDownMenu(Array.isArray(arr) ? arr : []);
		return;
	};

	//
	useEffect(() => {
		if (id != null) {
			(async () => { 
				await getData(id, setDropDownMenu);
			})();
		}
	}, [id]);

	return dropDownMenu;
};
