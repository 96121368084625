import React, { useEffect, useState } from "react";
import "../../asset/css/breadcrumb.css";
import { Breadcrumb } from "react-materialize";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getItem } from "../../config/config";

const Breadcrumbs = ({ id, type, menuName}) => {
	const to = {"men":"/men", "women":"/women", "kids":"/kids", "fitness":"/fitness", "jewellery":"/jewellery", "root":"/root"}
	const [category, setCategory ]  = useState("men")
	const [menu, setMenu ]  = useState(menuName)
	
	useEffect(() => {
		(()=>{ 			
			// console.log(menuName, menu, "br")
			if(!id || type!=="reuse")return 
			const data = getItem("menuTwo")
			let matchingKey = null;

			for (const key in data) {
				if (data.hasOwnProperty(key)) {
					const items = data[key];
					for (const item of items) {
						const menuthrees = item.menuthrees;
						for (const menuthree of menuthrees) { 
							if (parseInt(menuthree.id) === parseInt(id)) {
								matchingKey = key; 
								setCategory(matchingKey)
								setMenu(menuthree.name)
								break;
							}
						}
						if (matchingKey) { 
							break;
						}
					}
					if (matchingKey) { 
						break;
					}
				}
			}
		})()		
	}, [id]);
 
	useEffect(() => {
		(()=>{ 			 
			if(type==="product")return setCategory("product")
		})()		
	}, [id]);
 
	return (
		<div className="breadcrumb">
			<Link to="/" style={{ color: "#100840" }}>
				HOME
			</Link>
			<span>
				<iconify-icon
					icon="iconamoon:arrow-right-2-bold"
					width="10"
				></iconify-icon>
			</span>
			{/* <Link to={''} style={{ color: "#100840" }}> */}
			<Link to={to[category] ?? ''} style={{ color: "#100840" }}>
				{category.toUpperCase() ?? "PRODUCT"}
			</Link>
			<span>
				<iconify-icon
					icon="iconamoon:arrow-right-2-bold"
					width="10"
				></iconify-icon>
			</span>
			<span>{menu ? menu.toUpperCase() : ""}</span>
		</div>
	);
};

export default Breadcrumbs;
