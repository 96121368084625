import vid1 from "../asset/videos/vid1.mp4";
import vid2 from "../asset/videos/vid2.mp4";
import vid3 from "../asset/videos/vid3.mp4";
import vid4 from "../asset/videos/vid4.mp4";
import vid5 from "../asset/videos/video.mp4";

const Videos = {
	vid1,
	vid2,
	vid3,
	vid4,
	vid5,
	
};
export default Videos;
