import React, { useState } from "react";
import { Icon } from "@iconify/react";
import "../../asset/css/menustyles.css";

const SearchFilter = ({ handleSearch }) => {
	const [searchQuery, setSearchQuery] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	const [isDropdownVisible, setDropdownVisible] = useState(false);

	const handleChange = (e) => {
		const query = e.target.value;
		setSearchQuery(query);

		// Clear search results and hide dropdown if the query is empty
		if (query.length === 0) {
			setSearchResults([]);
			setDropdownVisible(false);
		} else {
			// Perform search and update searchResults
			handleSearch(query);
			setDropdownVisible(true);
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		// You can perform a search when the user submits the form if needed.
		// handleSearch(searchQuery);
	};

	return (
		<form onSubmit={handleSubmit} className="d-flex search">
			<div className="input-group searchBox">
				<input
					className="form-control"
					type="search"
					placeholder="Search"
					aria-label="Search"
					value={searchQuery}
					onChange={handleChange}
				/>
				<button className="btn btn-light" type="submit">
					<Icon className="searchicon" icon="mdi:cloud-search" />
				</button>
			</div>

			{/* Display search results as a dropdown */}
			{isDropdownVisible && (
				<div className="search-results">
					<ul>
						{searchResults.map((result) => (
							<li key={result.id}>{result.name}</li>
						))}
					</ul>
				</div>
			)}
		</form>
	);
};

export default SearchFilter;
